import { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Avatar, Box, Button, Divider, Drawer, Typography } from '@material-ui/core'
import useAuth from '../../hooks/useAuth'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import NavSection from '../NavSection'
import Scrollbar from '../Scrollbar'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined'
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined'
import ViewListIcon from '@material-ui/icons/ViewList';
import CastIcon from '@material-ui/icons/Cast'
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import { IconContext } from 'react-icons'
import { BiArrowToLeft } from 'react-icons/bi';
import { ImBooks } from 'react-icons/im';

const sections = [
	{
		items: [
			{
				title: 'Overview',
				path: '/dashboard',
				icon: <HomeIcon fontSize="medium" />,
			},
			{
				title: 'Videos',
				path: '/dashboard/channels',
				icon: <OndemandVideoOutlinedIcon fontSize="medium" />,
			},
			{
				title: 'Courses',
				path: '/dashboard/lms/courses',
				icon: <ImBooks />
			},
			{
				title: 'Series',
				path: '/dashboard/series',
				icon: <ViewListIcon fontSize="medium" />,
			},
			{
				title: 'Live Events',
				path: '/dashboard/workshops',
				icon: <CastIcon fontSize="medium" />,
			},
			{
				title: 'Instructors',
				path: '/dashboard/instructors',
				icon: <PeopleIcon fontSize="medium" />
			},
			{
				title: 'Reports',
				path: '/dashboard/reports',
				icon: <AssessmentOutlinedIcon fontSize="medium" />,
			},
			{
				title: 'Settings',
				path: '/dashboard/settings',
				icon: <SettingsOutlinedIcon fontSize="medium" />,
			}
		],
	},
]

const subscriberSections = [
	{
		items: [
			{
				title: 'Dashboard',
				path: '/dashboard',
				icon: <DashboardOutlinedIcon fontSize="medium" />,
			},
			{
				title: 'My Subscriptions',
				path: '/dashboard/subscriptions',
				icon: <OndemandVideoOutlinedIcon fontSize="medium" />,
			},
			{
				title: 'My Bookmarks',
				path: '/dashboard/bookmarks',
				icon: <BookmarksOutlinedIcon fontSize="medium" />,
			},
			{
				title: 'Billing & Payments',
				path: '/dashboard/billing',
				icon: <CreditCardOutlinedIcon fontSize="medium" />,
			},
			{
				title: 'My Account',
				path: `/dashboard/account`,
				icon: <SettingsOutlinedIcon fontSize="medium" />,
			},
		],
	},
]

const DashboardSidebar = (props) => {
	const { onMobileClose, openMobile, onCloseSidebar } = props
	const location = useLocation()
	const { user } = useAuth()
	const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))

	const content = (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignContent: 'space-between',
				height: '100%',
			}}
		>
			<Scrollbar options={{ suppressScrollX: true }}>
				<Box sx={{ p: 2 }}>
					<Box
						sx={{
							alignItems: 'center',
							backgroundColor: 'background.default',
							borderRadius: 1,
							display: 'flex',
							overflow: 'hidden',
							p: 2,
						}}
					>
						<RouterLink to={`/dashboard/account`}>
							<Avatar
								src={`${user.avatar?.url}`}
								sx={{
									cursor: 'pointer',
									height: 60,
									width: 60,
								}}
							/>
						</RouterLink>
						<Box sx={{ ml: 2 }}>
							<Typography color="textPrimary" variant="subtitle2">
								{user.username}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Divider />
				<Box sx={{ p: 3 }}>
					{user.subtype === 'publisher'
						? sections.map((section, idx) => (
							<NavSection
								key={idx}
								pathname={location.pathname}
								sx={{ '& + &': { mt: 2, p: 4 } }}
								{...section}
							/>
						))
						: subscriberSections.map((section, idx) => (
							<NavSection
								key={idx}
								pathname={location.pathname}
								sx={{ '& + &': { mt: 2, p: 4 } }}
								{...section}
							/>
						))}
				</Box>
				<Divider />
			</Scrollbar>
			<Box sx={{ justifyContent: 'space-between' }}>
				<Box sx={{ px: 2, py: 1, display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						color="button"
						component={RouterLink}
						align="center"
						fullWidth
						sx={{ display: 'flex-end' }}
						to="/dashboard/help"
						variant="contained"
					>
						<HelpOutlineOutlinedIcon sx={{ mr: 1 }} />
						Need Help?
					</Button>
				</Box>
				<Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						color="button"
						align="center"
						sx={{ display: 'flex-end' }}
						onClick={onCloseSidebar}
					>
						<IconContext.Provider value={{ size: '2em' }}>
							<BiArrowToLeft />
						</IconContext.Provider>
					</Button>
				</Box>
			</Box>
		</Box>
	)

	if (lgUp) {
		return (
			<Drawer
				anchor="left"
				open
				PaperProps={{
					sx: {
						backgroundColor: 'background.default',
						height: 'calc(100% - 64px) !important',
						top: '64px !Important',
						left: '0px !Important',
						width: 260,
					},
				}}
				variant="permanent"
			>
				{content}
			</Drawer>
		)
	}

	return (
		<Drawer
			anchor="left"
			onClose={onMobileClose}
			open={openMobile}
			PaperProps={{
				sx: {
					backgroundColor: 'background.paper',
					width: 260,
				},
			}}
			variant="temporary"
		>
			{content}
		</Drawer>
	)
}

DashboardSidebar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
	onCloseSidebar: PropTypes.func,
}

export default DashboardSidebar
