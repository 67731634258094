import React, { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { Box, Button } from '@material-ui/core'
import { updateSubscriptionUser } from '../actions/subscriptionsApi'
import useAuth from '../hooks/useAuth'
import { LoadingButton } from '@material-ui/lab'

const accentColor = '#2d91e3'

/**
 * @returns {JSX.Element}
 */
const UpdatePaymentFormControl = ({onSuccess, onCancel}) => {
    
    const { user } = useAuth()
    const stripe = useStripe()
	const elements = useElements()

    /** @type {[boolean, Dispatch<boolean>]} */
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        setLoading(true)

        // This creates the new payment method
        const setupResult = await stripe.confirmSetup({
            elements,
            redirect: "if_required",
            confirmParams: {
                return_url: window.location.protocol + "//" + window.location.host + "/"
			}
        })
        // setupResult.setupIntent.payment_method
        // Make sure setup intent was successfull
        if(setupResult.error) {
            setLoading(false)
            return
        }
        

        await updateSubscriptionUser(user.stripesubscriptionid, { default_payment_method: setupResult.setupIntent.payment_method})
        setLoading(false)
        onSuccess()
    }

    return (
        <Box minHeight={'75px'}>
            <PaymentElement id="payment-element" />
            <Box sx={{justifyContent:"center", display: "flex"}}>

                <LoadingButton
                    sx={{ mt: 4, px: 15, backgroundColor: accentColor, color: 'background.gray.contrastText', '&:hover': { backgroundColor: accentColor } }}
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    loading={loading}
                >
                    Submit
                </LoadingButton>
                <Button onClick={onCancel} sx={{ mt: 4, px: 15, ml: 4}} variant='outlined'>
                    Cancel
                </Button>
            </Box>
        </Box>
    );
}

export default UpdatePaymentFormControl