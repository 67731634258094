import { useEffect } from 'react'
import { useLocation } from 'react-router'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { Link as RouterLink } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { AppBar, Box, Button, Divider, Toolbar, makeStyles, Modal, IconButton, Typography } from '@material-ui/core'
import { useState } from 'react'
import AccountManagementForm from '../AccountManagementForm'
import SubscriptionCancelForm from '../SubscriptionCancelForm'
import SubscriptionManageForm from '../SubscriptionManageForm'
import LoginForm from '../LoginForm'
import UpdatePaymentForm from '../UpdatePaymentForm'
import AccountPopover from '../AccountPopover'
import MobileMenuPopover from '../MobileMenuPopover'
import { fetchUsersSubscription } from '../../actions/subscriptionsApi';
import MenuIcon from '@material-ui/icons/Menu'
import TransactionPopOver from '../membership/TransactionPopOver'
import { paymentsApi } from '../../actions/paymentsApi'

// These should be passed in from a theme
const useStyles = makeStyles(theme => ({
	accountButton: {
		'&:hover': {
			filter: 'brightness(0.85)'
		},
		marginRight: 2
	},
	loginButton: {
		'&:hover': {
			borderColor: "#2d91e3",
			color: '#000',
			filter: 'brightness(0.75)',
		},
		marginLeft: 10,
		paddingLeft: 20,
		paddingRight: 20,
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	subscribeButton: {
		textTransform: 'capitalize',
		backgroundColor: "#2d91e3",
		marginLeft: 10,
		paddingLeft: 20,
		paddingRight: 20,
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	mobileHamburger: {
		[theme.breakpoints.up('sm')]: {
			display: 'none'
		}
	},
	logo: {
		[theme.breakpoints.up('sm')]: {
			height: 20,
		},
		[theme.breakpoints.down('sm')]: {
			height: 16,
		},
		padding: 0,
		margin: 0
	},
	appBar: {
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.primary,
	},
	toolBar: {
		minHeight: 64,
		justifyContent: 'space-between',
	},
	sep: {
		fontWeight: 700,
		fontSize: 18,
		color: '#1CABAA',
		[theme.breakpoints.down('sm')]: {
			marginLeft: -3,
			marginRight: -3,
		},
	},
	impersonatingBar: {
		backgroundColor: '#FF6961',
		color: 'white',
		textAlign: 'center',
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	}
}))

const PublisherSiteNavbar = (props) => {
	const { siteName, logo } = props
	const { user, logout, stopImpersonating } = useAuth()
	const navigate = useNavigate()
	const classes = useStyles()
	const updatePaymentLink = new URLSearchParams(window.location.search).get('update-payment');
	const [accountModalOpen, setAccountModalOpen] = useState(false)
	const [cancelModalOpen, setCancelModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [subManageModalOpen, setSubManageModalOpen] = useState(Boolean(updatePaymentLink))
	const [paymentModalOpen, setPaymentModalOpen] = useState(false)
	const [subscription, setSubscription] = useState()
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
	const [mobileMenuRef, setMobileMenuRef] = useState(null)
	const [transactionModalOpen, setTransactionModalOpen] = useState(false)
	const location = useLocation()
	const [customerId, setCustomerId] = useState()
	const [paymentMethod, setPaymentMethod] = useState()
	const [isExpiring, setIsExpiring] = useState(false)

	useEffect(() => {
		if (user?.stripesubscriptionid) getSubscriptionDetails()
		if (user?.stripecustomerid) setCustomerId(user.stripecustomerid)
	}, [user])

	const getSubscriptionDetails = async () => {
		const sub = await fetchUsersSubscription()
		if (sub.msg === undefined) {
			setSubscription(sub)
		}
	}

	const getPaymentMethod = async (pmId) => {
		const paymentMethod = await paymentsApi.getPaymentMethodDetails(pmId)
		setPaymentMethod(paymentMethod)
	}

	const checkCardExpiry = async (paymentMethod) => {
		const { exp_month, exp_year } = paymentMethod;
		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		const currentYear = currentDate.getFullYear();

		const isExpiring = (exp_year === currentYear && exp_month <= currentMonth) ||
			(exp_year === currentYear && exp_month === currentMonth + 2) ||
			(exp_year === currentYear + 1 && exp_month === 2 && currentMonth === 12);

		setIsExpiring(isExpiring)
	}

	const handleLogout = async () => {
		try {
			await logout()
			navigate(`/${siteName}/videos`)
		} catch (err) {
			console.error(err)
			toast.error('Unable to logout')
		}
	}

	const handleSubCancel = async () => {
		await getSubscriptionDetails()
		setCancelModalOpen(false)
		setSubManageModalOpen(true)
		toast.success('Subscription Canceled')
	}

	const openMobileMenu = (e) => {
		setMobileMenuRef(e.currentTarget)
		setMobileMenuOpen(true)
	}

	const closeMobileMenu = () => {
		setMobileMenuOpen(false)
		setMobileMenuRef(null)
	}

	useEffect(() => {
		if (!subscription) return

		getPaymentMethod(subscription.default_payment_method)
	}, [subscription])

	useEffect(() => {
		if (!paymentMethod) return

		checkCardExpiry(paymentMethod)
	}, [paymentMethod])

	const impersonating = sessionStorage.getItem('impersonating')

	return (
		<AppBar elevation={0} className={classes.appBar} >
			{impersonating && (
				<Box className={classes.impersonatingBar}>
					<Typography variant="body1" sx={{ mr: 2, fontWeight: 600 }}>Impersonating {user?.firstName} {user?.lastName}</Typography>
					<Button color="primary" onClick={stopImpersonating} variant="contained" sx={{ cursor: 'pointer' }}>Stop Impersonating</Button>
				</Box>
			)}
			{isExpiring && (
				<Box className={classes.impersonatingBar}>
					<Typography variant="body1" sx={{ mr: 2, fontWeight: 600 }}>Your card ending in {paymentMethod.last_four} expires on {paymentMethod.exp_month}/{paymentMethod.exp_year}. Please update your card information.</Typography>
					<Button color="primary" onClick={() => setSubManageModalOpen(true)} variant="contained" sx={{ cursor: 'pointer' }}>Update</Button>
				</Box>
			)}
			<Toolbar className={classes.toolBar}>
				<Box
					sx={{
						alignItems: 'center',
						ml: { md: 2, sm: 2, xs: 0 },
						mt: 2,
						mb: 1
					}}>
					<RouterLink to={`/yogarenew/videos`}>
						<img alt="Logo" src={logo} className={classes.logo} />
					</RouterLink>
				</Box>
				{user
					? <Box className={classes.accountButton}>
						<AccountPopover logoutClick={handleLogout} profileClick={() => { setAccountModalOpen(true) }} transactionClick={() => { setTransactionModalOpen(true) }} />
					</Box>
					: <Box>
						<IconButton className={classes.mobileHamburger} onClick={openMobileMenu} >
							<MenuIcon />
						</IconButton>
						<MobileMenuPopover
							loginClick={() => { setLoginModalOpen(true); closeMobileMenu() }}
							subscribeClick={() => { navigate(`/${siteName}/subscriptions`); closeMobileMenu() }}
							open={mobileMenuOpen}
							setOpen={setMobileMenuOpen}
							closeMenu={closeMobileMenu}
							anchorRef={mobileMenuRef}
						/>
						{location.pathname !== '/YogaRenew/subscriptions' && location.pathname !== '/yogarenew/subscriptions' &&
							<>
								<Button variant="contained" className={classes.subscribeButton} onClick={() => navigate(`/${siteName}/subscriptions`)} >
									SUBSCRIBE
								</Button>

								<Button
									variant="outlined"
									className={classes.loginButton}
									onClick={() => navigate(`/authentication/yogarenewlogin`)}
								>
									LOG IN
								</Button>
							</>
						}
					</Box>
				}
			</Toolbar>
			<Divider />

			<Modal
				open={accountModalOpen}
				className={classes.accountModal}
				onClose={() => (setAccountModalOpen(false))}
			>
				<AccountManagementForm
					manageSubClick={() => { setAccountModalOpen(false); setSubManageModalOpen(true) }}
					subscription={subscription}
					setAccountModalOpen={setAccountModalOpen}
				/>
			</Modal>
			<Modal
				open={transactionModalOpen}
				className={classes.accountModal}
				onClose={() => (setTransactionModalOpen(false))}
			>
				<TransactionPopOver
					customer={user}
					setTransactionModalOpen={setTransactionModalOpen}
				/>
			</Modal>
			<Modal
				open={subManageModalOpen}
				className={classes.subManageModal}
				onClose={() => (setSubManageModalOpen(false))}
			>
				<SubscriptionManageForm
					cancelPress={() => { setSubManageModalOpen(false); setCancelModalOpen(true) }}
					restorePress={() => { getSubscriptionDetails() }}
					updateSubscription={getSubscriptionDetails}
					closePress={() => setSubManageModalOpen(false)}
					subscription={subscription}
					updatePayment={updatePaymentLink}
					customerId={customerId}
				/>
			</Modal>
			<Modal
				open={cancelModalOpen}
				className={classes.cancelModal}
				onClose={() => (setCancelModalOpen(false))}
			>
				<div>
					<SubscriptionCancelForm siteName={siteName} onSubmit={handleSubCancel} onClose={() => setCancelModalOpen(false)} />
				</div>
			</Modal>
			<Modal
				open={loginModalOpen}
				className={classes.loginModal}
				onClose={() => (setLoginModalOpen(false))}
			>
				<div>
					<LoginForm loginClick={() => { setLoginModalOpen(false); navigate(`/${siteName}/videos`) }} createNewAccountClick={() => { setLoginModalOpen(false); navigate(`/${siteName}/signup`) }} />
				</div>
			</Modal>
			<Modal
				open={paymentModalOpen}
				className={classes.paymentModal}
				onClose={() => (setPaymentModalOpen(false))}
			>
				<div>
					<UpdatePaymentForm siteName={siteName} setOpen={setPaymentModalOpen} />
				</div>
			</Modal>
		</AppBar>
	)
}

export default PublisherSiteNavbar