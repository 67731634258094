import { Box, Typography, Paper, Button, Grid } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import Image from '../../../../components/Image'

export const WorkshopListView = ({ workshops, subscriptionValid }) => {
    const date = new Date();
    const dateAsString = date.toString();
    const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
    const navigate = useNavigate()

    return (
        workshops && workshops.length > 0 && (
            <Box sx={{
                py: 3,
                '@media (max-width: 600px)': {
                    pb: 10,
                }
            }}>
                <Typography variant="h4" sx={{ mb: 3, textTransform: 'uppercase' }}>
                    Upcoming Live Events
                </Typography>
                <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}>
                    {workshops.map((workshop, index) => (
                        <Grid item sm={12} lg={6} key={index}>
                            <Paper elevation={8} sm={6}
                                sx={{
                                    p: 4,
                                    borderRadius: 10,
                                    '@media (max-width: 600px)': {
                                        width: '100%',
                                        p: 2
                                    }
                                }}>
                                <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{
                                            width: '100%',
                                            height: 'auto',
                                            overflow: 'hidden',
                                            borderRadius: 6,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            '@media (max-width: 600px)': {
                                                height: '20vh',
                                                p: 2
                                            }
                                        }}>
                                            {workshop.covers?.format ?
                                                <Image formats={workshop.cover?.formats} alt={workshop.title} style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', aspectRatio: '1' }} />
                                                :
                                                <img src={workshop.cover?.url} alt={workshop.title} style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', aspectRatio: '1' }} />
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left', alignItems: 'flex-start' }}>
                                        <Box sx={{ ml: 2 }}>
                                            <Typography variant="h5" align="left">
                                                {workshop.title}
                                            </Typography>
                                            <Typography variant="body1" align="left" sx={{ my: 1 }}>
                                                When: {new Date(workshop.date).toLocaleDateString()} at {new Date(workshop.date).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })} {timezone.match(/\b(\w)/g).join('')}
                                            </Typography>
                                            <Typography variant="body1" align="left" sx={{ my: 1 }}>
                                                Host: {workshop.host}
                                            </Typography>
                                            {subscriptionValid ?
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ alignSelf: 'left', mt: 2 }}
                                                    onClick={() => navigate(`/yogarenew/workshops/${workshop.id}`)}
                                                >
                                                    Learn More
                                                </Button>
                                                :
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ alignSelf: 'left', mt: 2 }}
                                                    onClick={() => navigate('/yogarenew/subscriptions')}
                                                >
                                                    Subscribe to Register
                                                </Button>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        )
    )
}