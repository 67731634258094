import { Typography, Container, makeStyles, FormLabel, TextField, Grid, Button, IconButton } from '@material-ui/core'
import useAuth from "../hooks/useAuth"
import { Formik, Form } from 'formik'
import PublisherSiteLogo from './publisher-site/PublisherSiteLogo'
import { updateUser, checkEmailExists } from '../actions/userActions'
import * as Yup from 'yup'
import { useState, forwardRef } from 'react'
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    container: {
        background: "white",
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        minWidth: '20vw',
        zIndex: 1,
        borderRadius: '6px',
        padding: '30px',
        [theme.breakpoints.up('md')]: {
            minWidth: '20vw',
            maxWidth: '25vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '30vw',
            maxWidth: '40vw'
        },
        [theme.breakpoints.up('xs')]: {
            width: '98vw',
            margin: 0
        },
        transition: theme.transitions.create('all', {
            duration: 500,
        })
    },
    form: {
        width: '100%',
        alignSelf: 'center',
    },
    field: {
        //marginTop: '0.5rem'
        transition: theme.transitions.create('all', {
            duration: 250,
            easing: 'ease-in-out'
        })
    },
    hiddenField: {
        opacity: 0,
        position: 'absolute',
        paddingTop: '0 !important'
    },
    button: {
        marginTop: '1rem'
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
}))

const AccountManagementForm = forwardRef((props, ref) => {
    const { manageSubClick, subscription, setAccountModalOpen } = props;
    const { user } = useAuth()
    const classes = useStyles()
    const [lastCheckedEmail, setLastCheckedEmail] = useState("")
    const [lastEmailExistsResult, setLastEmailExistsResult] = useState(false)

    const checkEmailUsed = async (email) => {
        if (email === user.email) return true
        if (email && email === "") return false

        // Quick and dirty check for email addresses to prevent extra API calls
        if (!/^.+@.+\..{2,}$/.test(email)) {
            return false
        }
        // Don't check the same email twice (also to prevent extra API calls)
        if (email === lastCheckedEmail) {
            return !lastEmailExistsResult.used
        }

        const existResult = await checkEmailExists(email)
        setLastCheckedEmail(email)
        setLastEmailExistsResult(existResult)

        return !existResult.used
    }

    const UpdateAccountSchema = Yup.object().shape({
        firstName: Yup.string().required('first name is required').matches(/^[a-zA-Z0-9_ ]+$/, 'name must be alphanumeric'),
        lastName: Yup.string().required('last name is required').matches(/^[a-zA-Z0-9_ ]+$/, 'name must be alphanumeric'),
        email: Yup.string().email('invalid email').required('email is required').test('uniqueEmail', 'email is already in use', checkEmailUsed),
        password: Yup.string().min(8),
        passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'new passwords must match'),
        current_password: Yup.string().when('password', {
            is: (password) => password?.length > 0,
            then: Yup.string().required('current password is required').notOneOf([Yup.ref('password')], 'new password must not be the same as the current password'),
        })
    })

    const handleUpdateAccountClick = (formik) => {
        if (!formik.isValid) return
        // Don't double submit
        if (formik.isSubmitting) return
        // Don't submit unchanged form
        if (!formik.dirty) return
        const details = {}
        if (formik.touched.firstName) details.firstName = formik.values.firstName
        if (formik.touched.lastName) details.lastName = formik.values.lastName
        if (formik.touched.email) details.email = formik.values.email
        if (formik.touched.password) {
            details.password = formik.values.password
            details.current_password = formik.values.current_password
        }

        formik.setSubmitting(true)
        updateUser(user.id, details).then(async (response) => {
            if (response.error) {
                formik.setSubmitting(false)
                if (response.message === "current_password.notSame") {
                    formik.setErrors({ current_password: "current password is incorrect" })
                    // TODO: Handle other errors better
                } else {
                    formik.setErrors({ current_password: response.message })
                }
                return
            }
            // Store new user data locally
            sessionStorage.setItem('user', JSON.stringify(response))
            window.location.reload()
        })
    }

    // Setting the initial values with let instead const prevents a "changing unctronolled input" error
    let initialValues = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName
    }

    if (!user) return null;

    return <Container className={classes.container} ref={ref}>
        <IconButton className={classes.closeButton} onClick={() => { setAccountModalOpen(false) }}>
            <CloseIcon />
        </IconButton>
        <Formik validationSchema={UpdateAccountSchema} initialValues={initialValues}>
            {formik => (
                <Form className={classes.form} >
                    <Grid container spacing={1} mt={1} justifyContent={'center'}>
                        <Grid item xs={12} textAlign="center" >
                            <PublisherSiteLogo maxWidth="75%" />
                        </Grid>
                        <Grid item md={12} textAlign="center" className={classes.field}>
                            <Typography color="textPrimary" variant="h5" >Account Management</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} className={classes.field}>
                            <FormLabel>
                                <Typography color="textPrimary" variant="subtitle2" className={classes.formLabel}>First Name</Typography>
                            </FormLabel>
                            <TextField
                                fullWidth
                                name="firstName"
                                variant="outlined"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="given-name"
                                helperText={(formik.touched.firstName && formik.errors.firstName) || ' '}
                                error={formik.touched.firstName && formik.errors.firstName}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} className={classes.field}>
                            <FormLabel>
                                <Typography color="textPrimary" variant="subtitle2" className={classes.formLabel}>Last Name</Typography>
                            </FormLabel>
                            <TextField
                                fullWidth
                                name="lastName"
                                variant="outlined"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="family-name"
                                helperText={(formik.touched.lastName && formik.errors.lastName) || ' '}
                                error={formik.touched.lastName && formik.errors.lastName}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.field}>
                            <FormLabel>
                                <Typography color="textPrimary" variant="subtitle2" className={classes.formLabel}>
                                    Email
                                </Typography>
                            </FormLabel>
                            <TextField
                                fullWidth
                                name="email"
                                variant="outlined"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="email"
                                helperText={(formik.touched.email && formik.errors.email) || ' '}
                                error={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.field}>
                            <FormLabel>
                                <Typography color="textPrimary" variant="subtitle2" className={classes.formLabel}>
                                    New Password
                                </Typography>
                            </FormLabel>
                            <TextField
                                fullWidth
                                name="password"
                                variant="outlined"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="new-password"
                                helperText={(formik.touched.password && formik.errors.password) || ' '}
                                error={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.field}>
                            <FormLabel>
                                <Typography color="textPrimary" variant="subtitle2" className={classes.formLabel}>
                                    Confirm New Password
                                </Typography>
                            </FormLabel>
                            <TextField
                                fullWidth
                                name="passwordConfirm"
                                variant="outlined"
                                type="password"
                                value={formik.values.passwordConfirm}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="new-password"
                                helperText={(formik.touched.passwordConfirm && formik.errors.passwordConfirm) || ' '}
                                error={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                            />
                        </Grid>
                        <Grid item xs={12} className={[classes.field, formik.values.password?.length > 0 ? null : classes.hiddenField].join(' ')}>
                            <FormLabel>
                                <Typography color="textPrimary" variant="subtitle2" className={classes.formLabel}>
                                    Current Password
                                </Typography>
                            </FormLabel>
                            <TextField
                                fullWidth
                                name="current_password"
                                variant="outlined"
                                type="password"
                                value={formik.values.current_password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="current-password"
                                helperText={(formik.touched.current_password && formik.errors.current_password) || ' '}
                                error={formik.touched.current_password && formik.errors.current_password}
                            />
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.button}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="success"
                                onClick={() => { handleUpdateAccountClick(formik) }}
                                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                            >
                                Update Account
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.button} sx={{ display: user?.subtype === 'publisher' ? 'none' : 'unsert' }}>
                            {
                                subscription && subscription.status !== 'canceled'
                                    ? <Button variant="contained" fullWidth color="info" onClick={manageSubClick}>Manage Subscription</Button>
                                    : <Button variant="contained" fullWidth color="info" href='/yogarenew/subscriptions'>Subscribe</Button>
                            }
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    </Container>
});

export default AccountManagementForm