import { useState, useEffect } from 'react'
import { fetchWorkshops } from "../../../actions/workshopActions";
import { Container, Typography, Box, Button, Dialog, DialogActions } from '@material-ui/core';
import WorkshopForm from './WorkshopForm';
import { WorkshopTable } from './WorkshopTable';

const WorkshopView = () => {
    const [workshops, setWorkshops] = useState([])
    const [pastWorkshops, setPastWorkshops] = useState([])
    const [showAddWorkshopDialog, setShowAddWorkshopDialog] = useState(false)

    const getWorkshops = async () => {
        const response = await fetchWorkshops()
        if (response && response.length > 0) {
            const upcomingWorkshops = response.filter(workshop => new Date(workshop.date) > new Date())
            setWorkshops(upcomingWorkshops)
            const pastWorkshops = response.filter(workshop => new Date(workshop.date) < new Date())
            setPastWorkshops(pastWorkshops)
        }
    }

    useEffect(() => {
        getWorkshops()
    }, [])

    return (
        <Container>
            <Box sx={{ my: 5 }}>
                <Button onClick={() => setShowAddWorkshopDialog(true)} variant="contained" color="primary">
                    Add Live Event
                </Button>
                <Dialog open={showAddWorkshopDialog} onClose={() => setShowAddWorkshopDialog(false)} maxWidth="lg">
                    <Box sx={{ px: 5, pb: 2 }}>
                        <DialogActions>
                            <Button onClick={() => setShowAddWorkshopDialog(false)} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                        <WorkshopForm workshops={workshops} setWorkshops={setWorkshops} setShowAddWorkshopDialog={setShowAddWorkshopDialog} />
                    </Box>

                </Dialog>
            </Box>
            {workshops && workshops.length > 0 && (
                <Box sx={{ py: 3 }}>
                    <Typography variant="h4" sx={{ mb: 3 }}>
                        Upcoming Live Events
                    </Typography>
                    <WorkshopTable workshops={workshops} setWorkshops={setWorkshops} />
                </Box>
            )}
            {pastWorkshops && pastWorkshops.length > 0 && (
                <Box>
                    <Typography variant="h4" sx={{ mb: 3 }}>
                        Past Live Events
                    </Typography>
                    <WorkshopTable workshops={pastWorkshops} setWorkshops={setPastWorkshops} past={true} />
                </Box>
            )}
        </Container>
    )
}

export default WorkshopView