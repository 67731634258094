import { paymentsApi } from "./paymentsApi"

const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => {
	const accessToken = localStorage.getItem('accessToken')
	const headers = {
		'Content-Type': 'application/json',
		'x-analytics-source': 'web'
	}
	if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

	return headers
}
// *********** FETCH A USER (BY ID) *********** //
export const fetchUser = async (id) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/users/${id}`, opts).then((data) => data.json())

	return response
}

// *********** FETCH A USER (BY ID) *********** //
export const fetchUserFilters = async (id) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/users/filters/${id}`, opts).then((data) => data.json())

	return response
}

// *********** UPDATE A USER (BY ID) *********** //
export const updateUser = async (id, details) => {
	const opts = {
		method: 'PUT',
		headers: headers(),
		body: JSON.stringify(details),
	}

	const response = await fetch(`${nodeURL}/users/${id}`, opts).then((data) => data.json())

	// Update stripe
	if (details.email && response.stripecustomerid) {
		const striperesp = await paymentsApi.updateCustomer({ id: response.stripecustomerid, email: details.email })
		if (striperesp.error) console.log("Error updating account email in stripe")
	}

	return response
}

// *********** DELETE A USER (BY ID) *********** //
export const deleteUser = async (id) => {
	const opts = {
		method: 'DELETE',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/users/${id}`, opts).then((data) => data.json())

	return response
}

export const getCurrentUser = async () => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/users/me`, opts).then((data) => data.json())

	return response
}

export const sendPasswordResetEmail = async (email) => {
	const opts = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ email: email }),
	}

	const response = await fetch(`${nodeURL}/auth/forgot-password`, opts).then((data) => data.json())

	return response
}

// details = {code, password, passwordConfirmation}
/**
 * @typedef {Object} PasswordResetDetails
 * @property {string} code
 * @property {string} password
 * @property {string} passwordConfirmation
 * 
 * @param {PasswordResetDetails} details 
 * @returns 
 */
export const submitPasswordReset = async (details) => {
	const opts = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(details),
	}

	const response = await fetch(`${nodeURL}/auth/reset-password`, opts).then((data) => data.json())

	return response
}

export const checkEmailExists = async (email) => {
	const opts = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	}

	const response = await fetch(`${nodeURL}/users/exists/${email}`, opts).then((data) => data.json())

	return response
}

export const sendInvoice = async (userId, invoiceUrl) => {
	const opts = {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify({ userId, invoiceUrl }),
	}

	const response = await fetch(`${nodeURL}/users/send-invoice`, opts).then((data) => data.json())

	return response
}

export const activateTV = async (deviceId, deviceCode) => {
	const opts = {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify({ deviceId, deviceCode }),
	}

	const response = await fetch(`${nodeURL}/tv-devices/activate`, opts).then((data) => data.json())

	return response
}