import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { Box, Button, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@material-ui/core'
import DuplicateIcon from '../icons/Duplicate'
import XIcon from '../icons/X'
import bytesToSize from '../utils/bytesToSize'
import AddPhotoAlternateOutlined from '@material-ui/icons/AddPhotoAlternateOutlined'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

const PicUpload = (props) => {
	const {
		accept,
		buttonText,
		buttonVariant,
		buttonSize,
		disabled,
		files,
		fileLabel,
		fileType,
		getFilesFromEvent,
		hasIcon,
		maxFiles,
		maxSize,
		minSize,
		noClick,
		noDrag,
		noDragEventsBubbling,
		noKeyboard,
		onDrop,
		onDropAccepted,
		onDropRejected,
		onFileDialogCancel,
		onRemove,
		onRemoveAll,
		onUpload,
		preventDropOnDocument,
		...other
	} = props

	// We did not add the remaining props to avoid component complexity
	// but you can simply add it if you need to.
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept,
		maxFiles,
		maxSize,
		minSize,
		onDrop,
		buttonText,
		fileType,
	})

	return (
		<div {...other}>
			<Box
				sx={{
					alignItems: 'center',
					borderRadius: 1,
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'center',
					outline: 'none',
					...(isDragActive && {
						backgroundColor: 'action.active',
						opacity: 0.5,
					}),
					'&:hover': {
						backgroundColor: 'action.hover',
						cursor: 'pointer',
						opacity: 0.5,
					},
				}}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				<Box>
					<Grid container justifyContent="center">
						<Grid item>
							{`${maxFiles && maxFiles === 1 ? '' : ''}`}{' '}
							{files && files?.length > 0 ? null : (

							<Button color="button" size={buttonSize} type="button" variant={buttonVariant} sx={{}}>
								{/* {fileType === 'image' ? (
									<AddPhotoAlternateOutlined size="small" color="primary" sx={{ mr: 1 }} />
								) : (
									<CloudUploadOutlinedIcon sx={{ fontSize: '100px' }} />
								)} */}
								{(() => {
									switch (fileType) {
										case 'image':
											return <AddPhotoAlternateOutlined size="small" color="primary" sx={{ mr: 1 }} />;
										case 'file':
											return <AddCircleOutlineOutlinedIcon fontSize='medium' color="primary" sx={{ ml: -2 }} />;
										default:
											return <CloudUploadOutlinedIcon sx={{ fontSize: '100px' }} />
									}
								})()
								}
								<Typography variant="subtitle2" color={buttonVariant === 'contained' ? 'inherit' : 'primary'}>
									{buttonText}
								</Typography>
								</Button>
							)}
						</Grid>
					</Grid>
				</Box>
			</Box>
			{/* </Box> */}
			{files && files?.length > 0 && (
				files.map((file) => (
				<Box sx={{ mt: 1 }}>
						<List>
							<ListItem
								key={file.path}
								sx={{
									border: 1,
									borderColor: 'divider',
									borderRadius: 1,
									'& + &': {
										mt: 1,
									},
								}}
							>
								<ListItemIcon>
									<DuplicateIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText
									primary={file.name}
									primaryTypographyProps={{
										color: 'textPrimary',
										variant: 'subtitle2',
									}}
									secondary={bytesToSize(file.size)}
								/>
								<Tooltip title="Remove">
									<IconButton edge="end" onClick={() => onRemove && onRemove(file)}>
										<XIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							</ListItem>
					</List>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							mt: 2,
						}}
					>
							<Button color="primary" onClick={() => onRemove && onRemove(file)} size="small" type="button" variant="text">
							{maxFiles > 1 ? 'Remove All' : 'Remove'}
						</Button>
						<Button color="primary" onClick={onUpload} size="small" sx={{ ml: 2 }} type="button" variant="contained">
							Upload
						</Button>
					</Box>
				</Box>
				))

			)}
		</div>
	)
}

PicUpload.propTypes = {
	accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	buttonText: PropTypes.string,
	buttonVariant: PropTypes.func,
	disabled: PropTypes.bool,
	files: PropTypes.array,
	getFilesFromEvent: PropTypes.func,
	hasIcon: PropTypes.bool,
	maxFiles: PropTypes.number,
	maxSize: PropTypes.number,
	minSize: PropTypes.number,
	noClick: PropTypes.bool,
	noDrag: PropTypes.bool,
	noDragEventsBubbling: PropTypes.bool,
	noKeyboard: PropTypes.bool,
	onDrop: PropTypes.func,
	onDropAccepted: PropTypes.func,
	onDropRejected: PropTypes.func,
	onFileDialogCancel: PropTypes.func,
	onRemove: PropTypes.func,
	onRemoveAll: PropTypes.func,
	onUpload: PropTypes.func,
	preventDropOnDocument: PropTypes.bool,
}

PicUpload.defaultProps = {
	files: [],
}

export default PicUpload
