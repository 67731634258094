import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Divider, Link, Typography, TextField, FormHelperText, Grid, IconButton, InputAdornment } from '@material-ui/core';
import PublisherSiteLogo from '../../components/publisher-site/PublisherSiteLogo';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import gtm from '../../lib/gtm';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import appstore from '../../assets/appstore.png';
import { useLocation } from 'react-router';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const YogaRenewLogin = ({ setOpen, email, setEmailError, setLoggedIn }) => {
  const { login } = useAuth();
  const passedEmail = new URLSearchParams(window.location.search).get('email');
  const navigate = useNavigate()
  const [submitError, setSubmitError] = useState(null)
  const location = useLocation()
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const formik = useFormik({
    initialValues: {
      email: passedEmail || email || '',
      password: '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      password: Yup.string().max(255).required('Password is required')
    }),
    onSubmit: async (values) => {
      try {
        const result = await login(values.email, values.password)
        if (result.error) {
          console.error(result);
          setSubmitError(result.error)
          return
        }

        if (location.pathname !== '/yogarenew/signup' && location.pathname !== '/authentication/activate') {
          navigate('/YogaRenew/videos')
        } else {
          setOpen(false)

          if (setEmailError) {
            setEmailError(null)
          }

          if (setLoggedIn) {
            setLoggedIn(true)
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>Login | YogaRenew</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: location.pathname !== '/yogarenew/signup' ? 'background.default' : '#fff',
          display: 'flex',
          flexDirection: 'column',
          minHeight: location.pathname !== '/yogarenew/signup' ? '100vh' : 'auto',
        }}
      >
        <Container maxWidth='sm' sx={{ py: location.pathname !== '/yogarenew/signup' ? '80px' : '20px' }}>
          {location.pathname !== '/yogarenew/signup' &&
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 8 }}>
              <RouterLink to='/'>
                <PublisherSiteLogo maxWidth="300px" maxHeight="70px" />
              </RouterLink>
            </Box>
          }
          <Card>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', p: 4 }}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 3,
                }}
              >
                <Typography color='textPrimary' gutterBottom variant='h4'>
                  Log in
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    autoFocus
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched?.email && formik.errors?.email ? formik.errors.email : ' '}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    value={formik.values.email}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(formik.touched.password && formik.errors.password)}
                    fullWidth
                    helperText={formik.touched?.password && formik.errors?.password ? formik.errors.password : ' '}
                    label="Password"
                    margin="normal"
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <LoadingButton
                      color="primary"
                      disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                      fullWidth
                      size="large"
                      variant="contained"
                      loading={formik.isSubmitting}
                      onClick={formik.handleSubmit}
                      type="submit"
                    >
                      Log In
                    </LoadingButton>
                    <FormHelperText sx={{ textAlign: 'center', color: 'red', fontSize: '0.9rem' }}>
                      {submitError ? submitError !== 'Please check your email and password' ? 'Please contact support at contact@yogarenewteachertraining.com for help with your account.' : submitError : ' '}
                    </FormHelperText>
                  </Box>
                </form>
              </Box>
              <Divider sx={{ my: 3 }} />
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Typography variant="body2">
                    <Link color="textSecondary" component={RouterLink} to="/YogaRenew/subscriptions" variant="body2">
                      Create new account
                    </Link>
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    <Link color="textSecondary" component={RouterLink} to="/authentication/forgot-password" variant="body2">
                      Forgot password
                    </Link>
                  </Typography>
                </Grid>
                {location.pathname !== '/yogarenew/signup' &&
                  <Grid item xs={12} md={6} textAlign="right">
                    <Link href="https://apps.apple.com/us/app/yogarenew-online/id6452239794" target="_blank">
                      <img src={appstore} alt="appstore" style={{ width: '150px', height: 'auto' }} />
                    </Link>
                  </Grid>
                }
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default YogaRenewLogin;
