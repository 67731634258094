import { LinearProgress, Typography, Box } from '@material-ui/core';

export default function VideoUploadingForm({ uploadProgress }) {
	return (
		<Box sx={{ margin: 10 }}>
			<Typography 
				variant='h4'
				textAlign='center' 
				sx={{ pb: 5 }}
			>
				Video is uploading
			</Typography>
			<Box display='flex' alignItems='center'>
				<Box width='100%' mr={1}>
					<LinearProgress variant='determinate' value={uploadProgress} />
				</Box>
				<Box minWidth={35}>
					<Typography variant='body2' color='textSecondary'>{`${Math.round(uploadProgress)}%`}</Typography>
				</Box>
			</Box>
		</Box>
	);
}
