import { createSlice } from '@reduxjs/toolkit'
import objFromArray from '../utils/objFromArray'
import { fetchChannels, createChannel, deleteChannel, fetchChannelSortOrder } from '../actions/channelActions'
import { updateUser } from '../actions/userActions'

const initialState = {
	isLoaded: false,
	channels: {
		byId: {},
		allIds: [],
	}
}

const slice = createSlice({
	name: 'channels',
	initialState,
	reducers: {
		getChannels(state, action) {
			const { channels, channelorder } = action.payload
			state.channels.byId = objFromArray(channels)
			state.channels.allIds = channelorder // Object.keys(state.channels.byId)
			state.isLoaded = true
		},
		addChannel(state, action) {
			const channel = action.payload;

			state.channels.byId[channel.id] = channel;
			state.channels.allIds.push(channel.id);
		},

		moveChannel(state, action) {
			const { newPosition, channelId } = action.payload

			let newChannelOrder = state.channels.allIds.filter(_listId => _listId !== channelId)
			newChannelOrder.splice(newPosition, 0, channelId)
			state.channels.allIds = newChannelOrder
		},
		removeChannel(state, action) {
			const channelId = action.payload;

			delete state.channels.byId[channelId];
			state.channels.allIds = state.channels.allIds.filter((_listId) => _listId !== channelId);
		},
	},
})

export const { reducer } = slice

export const getChannels = (id) => async (dispatch) => {
	const promises = Promise.all([fetchChannels(id), fetchChannelSortOrder(id)])

	const [channels, sortOrder] = await promises;
	if (!sortOrder) {
		dispatch(slice.actions.getChannels({ channels, channelorder: [] }))
		return
	}

	dispatch(slice.actions.getChannels({ channels, channelorder: sortOrder }))
}

export const addChannel = (user, currentChannels, values) => async (dispatch) => {
	const response = await createChannel(values)
	// Well... I guess this works
	let channelorder = JSON.parse(JSON.stringify(currentChannels.allIds))
	channelorder.push(response.id)
	// No need to wait for this
	updateUser(user.id, { channelorder: channelorder })
	dispatch(slice.actions.addChannel(values))
	return response
}

export const removeChannel = (id) => async (dispatch) => {
	const response = await deleteChannel(id)
	dispatch(slice.actions.removeChannel(id))
	return response
}

export const moveChannel = (userId, channelIdsArray, channelId, newPosition) => async (dispatch) => {
	const filteredChannelIdsArray = channelIdsArray.filter(_listId => _listId !== channelId)
	filteredChannelIdsArray.splice(newPosition, 0, channelId)

	dispatch(
		slice.actions.moveChannel({
			channelId,
			newPosition
		})
	)

	await updateUser(userId, {
		channelorder: filteredChannelIdsArray
	})
}

export const removeVideo = (videoId) => async (dispatch) => {
	// await removeVideo(videoId)

	// dispatch(slice.actions.removeVideo(videoId))
}

export default slice
