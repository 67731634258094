import { Grid, Typography } from '@material-ui/core'
import styled from '@emotion/styled'

const Div = styled.div`
	position: relative;
	height: 550px;
	background-position: top right;
	background-repeat: no-repeat;
	overflow: hidden;
`

const PublisherSiteMarketing2 = (props) => {
	const { marketingHeader, marketingText, marketingImage } = props

	return (
		<Div className="ExampleMarketing2">
			<Grid container md={12} spacing={2} sx={{ backgroundColor: 'background.paper' }}>
				<Grid item md={7} sx={{ py: 10 }}>
					<Grid item sx={{ pl: 15, pt: 5 }}>
						<Typography
							sx={{
								color: 'primary.text',
								textAlign: {
									md: 'left',
									xs: 'left',
								},
								fontSize: '22pt',
								fontWeight: '500',
								textTransform: 'uppercase',
							}}
						>
							{marketingHeader}
						</Typography>
					</Grid>
					<Grid item sx={{ pl: 15, pt: 5 }}>
						<Typography sx={{ color: 'primary.text', zIndex: 20 }} variant="h6">
							{marketingText}
						</Typography>
					</Grid>
				</Grid>

				<Grid item md={5} sx={{ py: 10 }}>
					<Grid item sx={{ px: 10, pt: 5 }}>
						<img
							alt="Yoga"
							src={marketingImage}
							style={{
								maxWidth: '100%',
								zIndex: 1,
								boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Div>
	)
}

export default PublisherSiteMarketing2
