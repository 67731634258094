const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => {
	const accessToken = localStorage.getItem('accessToken')
	const headers = {
		'Content-Type': 'application/json',
		'x-analytics-source': 'web'
	}
	if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

	return headers
}
// *********** CREATE COURSE SECTION *********** //
export const createCourseLecture = async (details) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(details),
    }

    const response = await fetch(`${nodeURL}/course-lectures`, opts).then((data) => data.json())

    return response
}


// *********** FETCH ALL COURSE SECTIONS (IN A COURSE) *********** //
export const fetchCourseLectures = async (courseId) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/course-lectures?course=${courseId}`, opts).then((data) => data.json())

    return response
}

// *********** FETCH A SINGLE COURSE SECTION (BY ID) *********** //
/**
 * 
 * @param {string} id 
 * @param {AbortSignal | undefined} signal
 * @returns 
 */
export const fetchCourseLecture = async (id, signal) => {
    const opts = {
        method: 'GET',
        headers: headers(),
        signal: signal
    }

    const response = await fetch(`${nodeURL}/course-lectures/${id}`, opts);
    const data = await response.json();
    return data;
}

// *********** UPDATE A COURSE SECTION (BY ID) *********** //
export const updateCourseLecture = async (id, details) => {
    const opts = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify(details),
    }

    const response = await fetch(`${nodeURL}/course-lectures/${id}`, opts).then((data) => data.json())

    return response
}

// *********** DELETE A COURSE SECTION (BY ID) *********** //
export const deleteCourseLecture = async (id) => {
    const opts = {
        method: 'DELETE',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/course-lectures/${id}`, opts).then((data) => data.json())

    return response
}
