import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import SearchIcon from '../../../icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';

const HelpTopicDetails = (props) => {
	const { helpTopics, ...other } = props;

	return (
		<div {...other}>

			<Helmet><title>Help/FAQ</title></Helmet>
			<Box sx={{ ml: 7 }}>
				<Grid item md={12} lg={12} sm={12} sx={{ mt: 4, mb: 3 }}>

					<Typography variant='h5'>Help/FAQ's</Typography>
				</Grid>

				<Box
					sx={{
						alignItems: 'center',
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
						mb: 2,
					}}
				>
					<Grid item spacing={3} md={11} sm={11} xs={12}>
						<Card sx={{ p: 4 }}>
							<Box
								sx={{
									justifyContent: 'space-between',
									width: '100%',
									display: 'flex',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'flex-start',
									}}
								>
									<IconButton component={RouterLink} to='/dashboard/help'>
										<ArrowBackIcon fontSize='large' />
									</IconButton>
								</Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'flex-end',
										maxWidth: '100%',
										width: 400,
									}}
								>
									<TextField
										fullWidth
										placeholder='Search'
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<SearchIcon fontSize='small' />
												</InputAdornment>
											),
										}}
										variant='outlined'
										size='small'
									/>
								</Box>
							</Box>

							<Box sx={{ mt: 5, mb: 15, mx: 10 }}>
								<Typography variant='h5' sx={{ mb: 3 }}>
									How do I upload a video?
								</Typography>
								<Typography sx={{ mb: 3 }}>Log in to your account and click dashboard. Click My Channels and Upload Video.</Typography>
							</Box>

							<Box sx={{ mt: 5, mb: 5, mx: 10 }}>
								<Typography variant='h5' sx={{ mb: 3 }}>
									Related Questions:{' '}
								</Typography>
								<Accordion>
									<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='topic1a-content' id='topic1a-header'>
										<Typography variant='h6'>Question #1</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.</Typography>
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='topic1a-content' id='topic1a-header'>
										<Typography variant='h6'>Question #2</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.</Typography>
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='topic1a-content' id='topic1a-header'>
										<Typography variant='h6'>Question #3</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.</Typography>
									</AccordionDetails>
								</Accordion>
							</Box>
						</Card>
					</Grid>
				</Box>
				{/* ))} */}
			</Box>
		</div >
	);
};

export default HelpTopicDetails;
