import axios from 'axios'

const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => {
	const accessToken = localStorage.getItem('accessToken')
	const headers = {
		'x-analytics-source': 'web'
	}
	if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

	return headers
}
// ********** CREATE ATTACHMENT  ********** //
export const createAttachment = async (body) => {
	const opts = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
		},
		body: body,
	}

	const response = await fetch(`${nodeURL}/upload`, opts).then((data) => data.json())
	return response
}

// ********** DELETE ATTACHMENT (BY ID)  ********** //
export const deleteAttachment = async (id) => {
	const opts = {
		method: 'DELETE',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/upload/files/${id}`, opts)
	const data = response.json()

	return data
}

// ********** UPLOAD FILE ********** //
export const uploadFile = (body, responseCallback = () => { }, progressCallback = () => { }) => {
	const config = {
		onUploadProgress: function (progressEvent) {
			var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
			console.log(percentCompleted)
			progressCallback(percentCompleted)
		},
		url: `${nodeURL}/folders`,
		method: 'post',
		data: body,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
		},
	}

	axios(config)
		.then((res) => responseCallback(res.data))
		.catch((err) => console.log(err))
}