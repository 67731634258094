import { useState, useEffect, forwardRef } from 'react'
import { Box, Dialog, Divider, Grid, MenuItem, Select, TextField, Typography, makeStyles, Slider, Button } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { createChannel } from '../../../../../actions/channelActions'
import { createAttachment } from '../../../../../actions/videoActions'
import { getLargestImage } from '../../../../../utils/getLargestImage'
import AddChannelForm from '../../CreateView/AddChannelForm'
import FileUpload from '../../../../../components/FileUpload'
import ImagePlaceholder from '../../../../../assets/image_placeholder.png'
import PlusIcon from '../../../../../icons/Plus'
import MinusIcon from '../../../../../icons/Minus'
import Feed from 'feed-media-audio-player'
import { feedFMApi } from '../../../../../actions/feedFMApi'
import { fetchUserFilters } from '../../../../../actions/userActions'
import NewInstructorView from '../NewInstructorView'
import CloseIcon from '@material-ui/icons/Close';
import { fetchInstructors } from '../../../../../actions/instructorsApi'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { useFormik } from 'formik'

const useStyles = makeStyles(theme => ({
	addChannelButton: {
		fontSize: 25,
	},
	channelButtonText: {
		fontSize: '14px',
		fontWeight: '400',
	},
	checkboxText: {
		fontSize: '14px',
		fontWeight: '400',
	},
	title: {
		textAlign: 'center',
		fontWeight: '500',
		fontSize: '12pt',
	},
	fileNameContainer: {
		marginTop: theme.spacing(2),
	}
}));

const FieldLabel = ({ label }) => (<Typography variant="subtitle2" color="textPrimary" sx={{ textTransform: 'uppercase', fontWeight: 700 }}>{label}</Typography>)

const VideoDetailsForm = forwardRef((props, ref) => {
	const { videoData, channels, defaultChannelId } = props;
	const publisherId = '616da24ac0eef7f071edd9f4'
	const classes = useStyles()
	const [isOpen, setIsOpen] = useState(false)
	const [feedStations, setFeedStations] = useState([])
	const [pointValues, setPointsValues] = useState([defaultPointValue()]);
	const [minute, setMinute] = useState(0);
	const [second, setSecond] = useState(0);
	const [selectedStationPoint, setSelectedStationPoint] = useState(0);
	const [selectedStation, setSelectedStation] = useState("Natural Beats");
	const [publisherFilters, setPublisherFilters] = useState();
	const [openAddNewModal, setOpenAddNewModal] = useState(false);
	const [toastLoadId, setToastLoadId] = useState();
	const [videoPlaybackUrl, setVideoPlaybackUrl] = useState();
	const [videoThumbnail, setVideoThumbnail] = useState();

	const pointMultiplier = videoData.duration / 100;

	const formik = useFormik({
		initialValues: {
			title: videoData.title ?? '',
			channel: defaultChannelId,
			description: videoData.description ?? '',
			thumbnail: videoData.thumbnail,
			musicdata: null,
			course_instructor: null,
			filterdata: {},
			published_at: new Date(),
		},
		validationSchema: Yup.object().shape({
			title: Yup.string('Enter video title').required('Title is required').min(3, 'Title should be a minimum of 3 characters length'),
			channel: Yup.string('Select or create new section').required('Section is required'),
			description: Yup.string('Enter video description').required('Description is required'),
		}),
	});

	ref.current = formik;

	const { setFieldValue } = formik;

	const getFeedChannels = async () => {
		await feedFMApi.refreshToken()

		const feedToken = JSON.parse(localStorage.getItem('feedfm_token'))
		if (feedToken?.token === undefined || feedToken.secret === undefined) {
			console.error("Failed to load Feed.FM - No valid feed token found in local storage")
			return
		}
		// Create feed player
		const player = new Feed.Player(feedToken.token, feedToken.secret)

		// Get stations
		player.on('stations', (stations) => {
			stations = [{ name: 'No Music', id: 'none' }, ...stations]
			setFeedStations(stations)
		});
		player.tune();
	}

	function defaultPointValue(start = 0) {
		return { startTime: start, stationId: feedStations.length > 0 ? feedStations[0].name : "Natural Beats" }
	}

	const getPublisherFilters = async () => {
		try {
			const teacherPromise = fetchInstructors()
			const response = await fetchUserFilters(publisherId)
			const teacherResult = await teacherPromise
			if (Array.isArray(teacherResult)) {
				response.Teacher.options = teacherResult
			}

			delete response['Duration']

			setPublisherFilters(response)
		} catch (error) {
			console.error(error)
		}
	}

	function updateMinuteInput(v) {
		// Empty input needs to be for intuitive input.
		if (v === '') {
			setMinute('');
			return;
		}

		// Prevent non-numeric input
		if (!/^\d+/.test(v)) return;

		let parsed = Number.parseInt(v);

		// Prevent other invalid inputs
		if (parsed === undefined || isNaN(parsed) || parsed === Infinity) return;

		// Bounds checking
		const totalSeconds = parsed * 60 + second
		if (selectedStationPoint > 0 && pointValues[selectedStationPoint - 1]) {
			const pDiff = totalSeconds - pointMultiplier * pointValues[selectedStationPoint - 1].startTime;
			if (pDiff <= 0) {
				parsed = Math.floor(pointMultiplier * pointValues[selectedStationPoint - 1].startTime / 60);
			}
		} else if (selectedStationPoint < pointValues.length - 1 && pointValues[selectedStationPoint + 1]) {
			const nDiff = pointMultiplier * pointValues[selectedStationPoint + 1].startTime - totalSeconds;
			if (nDiff <= 0) {
				parsed = Math.floor(pointMultiplier * pointValues[selectedStationPoint + 1].startTime / 60);
			}
		}

		// Um, I guess this works and doesn't through an error? Ok...
		pointValues[selectedStationPoint].startTime = (parsed * 60 + second) / pointMultiplier;

		// TODO: Check if new value will move dot past bounds of +/-1 of the current index (bounds checking)
		setMinute(parsed);
	}

	function handleMinuteInputBlur(v) {
		if (v === '') setMinute(0);
	}

	function updateSecondInput(v) {
		// Empty input needs to be for intuitive input.
		if (v === '') {
			setSecond('');
			return;
		}

		// Prevent non-numeric input
		if (!/^\d+/.test(v)) return;

		let parsed = Number.parseInt(v)

		// Prevent other invalid inputs
		if (parsed === undefined || isNaN(parsed) || parsed === Infinity) return;

		// Only 60 seconds in a minute!
		if (parsed > 59) parsed = 59;

		// Bounds checking
		const totalSeconds = minute * 60 + parsed;
		if (selectedStationPoint > 0 && pointValues[selectedStationPoint - 1]) {
			const pDiff = totalSeconds - pointMultiplier * pointValues[selectedStationPoint - 1].startTime;
			if (pDiff <= 0) {
				parsed = Math.floor(pointMultiplier * pointValues[selectedStationPoint - 1].startTime % 60) + 1;
			}
		} else if (selectedStationPoint < pointValues.length - 1 && pointValues[selectedStationPoint + 1]) {
			const nDiff = pointMultiplier * pointValues[selectedStationPoint + 1].startTime - totalSeconds;
			if (nDiff <= 0) {
				parsed = Math.floor(pointMultiplier * pointValues[selectedStationPoint + 1].startTime % 60) - 1;
			}
		}

		// Um, I guess this works and doesn't through an error? Ok...
		pointValues[selectedStationPoint].startTime = (minute * 60 + parsed) / pointMultiplier;

		setSecond(parsed);
	}

	// Empty input needs to be auto filled to 0 on blur
	function handleSecondInputBlur(v) {
		if (v === '') setSecond(0)
	}

	function getMinutes() {
		const seconds = pointMultiplier * pointValues[selectedStationPoint].startTime
		return Math.floor(seconds / 60)
	}

	function getSeconds() {
		const seconds = pointMultiplier * pointValues[selectedStationPoint].startTime
		return Math.floor(seconds % 60)
	}

	// TODO: FIX THIS
	const handleCreateChannel = async (values) => {
		const response = await createChannel(values)
		if (response.id) {
			const newChannels = [values, ...channels];
			setIsOpen(true);
			return 'Channel created'
		} else {
			return 'Something went wrong'
		}
	}

	const handleClickOpen = () => {
		setIsOpen(true)
	}

	const handleClose = () => {
		setIsOpen(false)
	}

	const handleUploadPhoto = async ([attachment]) => {
		try {
			const data = new FormData()
			data.append('files', attachment)
			const loading = toast.loading('Uploading thumbnail...')
			const response = await createAttachment(data);
			if (response) {
				toast.dismiss(loading);
				toast.success('Thumbnail uploaded!');
				setFieldValue('thumbnail', response[0]);
				setVideoThumbnail(response[0]);
			}
		} catch (error) {
			console.error('error uploading thumbnail', error);
		}
	}

	function renderFilter(filterSpec, filter) {
		const { values, setFieldValue } = formik;

		if (!filterSpec || !filter) {
			console.warn('Invalid filterSpec or filter', filterSpec, filter);
			return null;
		}

		return <Grid item sx={{ mt: 3 }} key={filter}>
			<FieldLabel label={filter} />
			<Select
				fullWidth
				value={values.filterdata[filter]}
				onChange={(e) => {
					const selectedValue = e.target.value;
					if (filter === 'Teacher') {
						const selectedOption = filterSpec.options.find(option => option.name === selectedValue);
						setFieldValue('course_instructor', selectedOption.id);
						setFieldValue(`filterdata.${filter}`, selectedValue);
					} else {
						setFieldValue(`filterdata.${filter}`, selectedValue);
					}
				}}
			>
				{filter === 'Teacher' &&
					<MenuItem value="addNewOption" onClick={() => setOpenAddNewModal(true)}>
						<AddCircleOutlineIcon />
						<Typography variant="subtitle1">Add new</Typography>
					</MenuItem>
				}
				{filter === 'Teacher' && <Divider sx={{ my: 0.5 }} />}
				{filter === 'Teacher'
					? filterSpec.options?.map(o =>
						<MenuItem key={o.id} value={o.name}>
							<Typography variant="subtitle1">{`${o.name}`}</Typography>
						</MenuItem>
					)
					: filterSpec.options?.map(o =>
						<MenuItem key={o} value={o}>
							<Typography variant="subtitle1">{`${o}`}</Typography>
						</MenuItem>
					)
				}
			</Select>
		</Grid>
	}

	useEffect(() => {
		// There always needs to be at least 1 point. Should show an alert for this...
		if (pointValues.length === 0) {
			setPointsValues([defaultPointValue()])
			return
		}

		// Make sure an invalid point is not selected
		if (pointValues[selectedStationPoint] === undefined) {
			setSelectedStationPoint(selectedStationPoint === 0 ? 0 : selectedStationPoint - 1)
			return
		}

		const m = getMinutes()
		const s = getSeconds()
		setMinute(m)
		setSecond(s)

		const station = feedStations.find(s => s.name === pointValues[selectedStationPoint].stationId)
		if (!station) return
		setSelectedStation(station.name)
	}, [selectedStationPoint, pointValues, feedStations])

	useEffect(() => getFeedChannels(), []);

	useEffect(() => {
		getPublisherFilters()
	}, [openAddNewModal])

	useEffect(() => {
		if (videoData?.thumbnail) {
			setFieldValue('thumbnail', videoData.thumbnail);
			setVideoThumbnail(videoData.thumbnail);
		}

		// Wait a few seconds for the video to be available...
		if (!videoPlaybackUrl) {
			setTimeout(() => setVideoPlaybackUrl(videoData.video?.url), 5000) 
		}

		if ((videoData.status === 'processing' || videoData.status === 'initial') && !toastLoadId) {
			const id = toast.loading('Video is processing', { duration: Infinity });
			setToastLoadId(id);
		} else if (videoData.status === 'complete') {
			setVideoPlaybackUrl(videoData.video?.url);
			toast.dismiss(toastLoadId);
			toast.success('Video processing complete');
			setToastLoadId(null);
		} else if (videoData.status === 'error') {
			toast.dismiss(toastLoadId);
			toast.error('Video processing failed!');
			setToastLoadId(null);
		}
	}, [videoData])

	const processing = videoData.status === 'processing' || videoData.status === 'pending';

	return <form>
		<Grid container spacing={4}>
			<Grid item md={5} xs={12} sx={{ justifyContent: 'center', mt: 2 }}>
				<Box className={classes.videoContainer}>
					<video width="100%" controls src={videoPlaybackUrl} type="video/mp4" />
				</Box>
				<Box className={classes.fileNameContainer}>
					<FieldLabel label="Filename" />
					<Typography variant="body2" color="textSecondary" sx={{ fontWeight: 500 }}>{videoData.name}</Typography>
				</Box>
			</Grid>
			<Grid item md={7} xs={12} sx={{ pl: 3 }}>
				<Box className={classes.formContainer}>
					<Box sx={{ mb: 3 }}>
						<FieldLabel label="Video Title" />
						<TextField
							fullWidth
							required
							name="title"
							variant="outlined"
							value={formik.values.title}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.title && Boolean(formik.errors.title)}
							helperText={formik.touched.title && formik.errors.title}
						/>
					</Box>
					<Box sx={{ mb: 3 }}>
						<FieldLabel label="Description" />
						<TextField
							fullWidth
							required
							multiline
							rows={4}
							name="description"
							variant="outlined"
							placeholder="Type a description..."
							value={formik.values.description}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.description && Boolean(formik.errors.description)}
							helperText={formik.touched.description && formik.errors.description}
						/>
					</Box>
				</Box>
				<Grid item sx={{ mb: 3 }}>
					<Box sx={{ mb: 0.5 }}>
						<FieldLabel label="Section" />
					</Box>
					<Select
						fullWidth
						required
						name="channel"
						value={formik.values.channel}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.channel && Boolean(formik.errors.channel)}
					>
						{channels && channels.filter(c => c.name).map(c =>
							<MenuItem key={c.id} value={c.id}>
								<Typography variant="subtitle1"> {`${c.name}`}</Typography>
							</MenuItem>
						)}
						<Divider sx={{ my: 0.5 }} />
						<MenuItem onClick={handleClickOpen}>
							<AddCircleOutlineIcon />
							<Typography variant="subtitle1" sx={{ pl: 1 }}>
								Create New Section
							</Typography>
						</MenuItem>
					</Select>
				</Grid>
				{publisherFilters && Object.keys(publisherFilters).map(filter => {
					const filterSpec = publisherFilters[filter];
					if (!filterSpec) return null;
					return renderFilter(filterSpec, filter);
				})}
				<Grid item sx={{ mt: 3, mb: 3 }}>
					<FieldLabel label="Feed.FM Station Mappings" />
					<Box sx={{ position: 'relative' }}>
						{(processing && videoData.duration === 0) &&
							<Box
								sx={{
									position: 'absolute',
									height: '106%',
									width: '106%',
									top: '-3%',
									left: '-3%',
									zIndex: 1,
									backgroundColor: 'rgba(255, 255, 255, 0.5)',
									backdropFilter: 'blur(10px)',
								}}
							>
								<Typography
									variant="body2"
									color="textSecondary"
									sx={{
										fontWeight: 500,
										textAlign: 'center',
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
									}}
								>
									Video is still processing. Feed.FM Station mapping will be available momentarily.
								</Typography>
							</Box>}
						<Slider
							sx={{ marginTop: 5, marginBottom: 2 }}
							value={pointValues.map(v => v.startTime)}
							onChange={(e, val, point) => {
								if (typeof (val) === 'number') val = [val]
								const pvCopy = pointValues.slice()
								val.forEach((v, i) => pvCopy[i].startTime = v)
								setPointsValues(pvCopy)
								setSelectedStationPoint(point)
								setFieldValue('musicdata', pointValues)
							}}
							onChangeCommitted={(_, val) => {
								if (typeof (val) === 'number') val = [val]
								const pvCopy = pointValues.slice()
								val.forEach((v, i) => pvCopy[i].startTime = v)
								setPointsValues(pvCopy)
								setFieldValue('musicdata', pointValues)
							}}
							track={false}
							valueLabelFormat={(x, i) => i + 1}
							disableSwap
							valueLabelDisplay='on'
							step={100 / videoData.duration}
						/>
						<Grid container spacing={2}>
							<Grid item xs={12} lg={4}>
								<Button
									startIcon={<PlusIcon />}
									fullWidth
									size="small"
									onClick={() => {
										setPointsValues([...pointValues, defaultPointValue(100)]);
										setFieldValue('musicdata', pointValues)
									}}
									sx={{
										justifyContent: 'flex-start',
										textAlign: 'left',
										marginLeft: '-10px'
									}}
								>
									Add New Point
								</Button>
								<Button
									startIcon={<MinusIcon />}
									size="small"
									fullWidth
									onClick={() => {
										const sliced = [...pointValues.slice(0, selectedStationPoint), ...pointValues.slice(selectedStationPoint + 1)]
										setPointsValues(sliced)
										setFieldValue('musicdata', pointValues)
									}}
									sx={{
										justifyContent: 'flex-start',
										textAlign: 'left',
										marginLeft: '-10px'
									}}
								>
									Remove Selected Point
								</Button>
							</Grid>
							<Grid item xs={12} lg={2}>
								<Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
									<Typography variant="caption" sx={{ fontWeight: 'bold', pb: '2px', textAlign: 'center' }}>
										Selected Point
									</Typography>
									<Select
										size='small'
										fullWidth
										value={selectedStationPoint}
										onChange={(e) => setSelectedStationPoint(e.target.value)}
									>
										{pointValues.map((v, i) =>
											<MenuItem value={i} key={"selectedStationPoint-" + i}>
												{`${i + 1}`}
											</MenuItem>
										)}
									</Select>
								</Box>
							</Grid>
							<Grid item xs={12} lg={3}>
								<Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
									<Typography variant="caption" sx={{ fontWeight: 'bold', pb: '2px', textAlign: 'center' }}>
										Start Time
									</Typography>
									<Box sx={{ display: 'flex', flexDirection: "row" }}>
										<TextField
											size="small"
											value={minute}
											onChange={(e) => updateMinuteInput(e.target.value, e)}
											onBlur={(e) => handleMinuteInputBlur(e.target.value)}
											inputProps={{ sx: { textAlign: 'right' } }}
										/>
										<Typography sx={{ fontWeight: 'bold', px: '6px', py: '6px' }}>:</Typography>
										<TextField
											size="small"
											value={second}
											onChange={(e) => updateSecondInput(e.target.value, e)}
											onBlur={(e) => handleSecondInputBlur(e.target.value)}
											inputProps={{ sx: { textAlign: 'left' } }}
										/>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} lg={3}>
								<Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
									<Typography variant="caption" sx={{ fontWeight: 'bold', pb: '2px', textAlign: 'center' }}>
										Station
									</Typography>
									<Select
										size='small'
										fullWidth
										defaultValue={selectedStationPoint}
										value={selectedStation}
										onChange={e => {
											const pvCopy = pointValues.slice()
											pvCopy[selectedStationPoint].stationId = e.target.value
											setPointsValues(pvCopy)
											setSelectedStation(e.target.value)
											setFieldValue('musicdata', pointValues)
										}}
									>
										{feedStations.map(v =>
											<MenuItem value={v.name} key={v.id}>{`${v.name}`}</MenuItem>)
										}
									</Select>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
			<Grid item className={classes.videoImgButtonsContainer} md={4} lg={4}>
				<Box sx={{ position: 'relative' }}>
					{processing &&
						<Box
							sx={{
								position: 'absolute',
								height: '106%',
								width: '106%',
								top: '-3%',
								left: '-3%',
								zIndex: 1,
								backgroundColor: 'rgba(255, 255, 255, 0.5)',
								backdropFilter: 'blur(10px)',
							}}
						>
							<Typography
								variant="body2"
								color="textSecondary"
								sx={{
									fontWeight: 500,
									textAlign: 'center',
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
								}}
							>
								Video is still processing. Thumbnail selection will be available once processing is complete.
							</Typography>
						</Box>}
					<Box sx={{ display: 'flex', justifyContent: 'left', py: 1.5, pr: 2 }}>
						<FileUpload onDrop={handleUploadPhoto} fileType="image" fileLabel="thumbnail" maxFiles={1} />
					</Box>
				</Box>
			</Grid>
			<Grid item className={classes.videoImgButtonsContainer} md={8} lg={8}>
				<Box sx={{ display: 'flex', justifyContent: 'left', py: 1.5 }}>
					<img
						src={videoThumbnail ? `${getLargestImage(videoThumbnail, 'small')}` : ImagePlaceholder}
						alt={formik.values.title}
						className={classes.videoThumbnail}
						width={400}
					/>
				</Box>
			</Grid>
		</Grid>

		<Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth={'lg'}>
			<AddChannelForm handleClose={handleClose} handleCreateChannel={handleCreateChannel} />
		</Dialog>
		<Dialog
			open={openAddNewModal}
			onClose={() => setOpenAddNewModal(false)}
			maxWidth="lg"
			fullWidth
		>
			<CloseIcon style={{ position: 'absolute', top: '8px', right: '8px', cursor: 'pointer' }} onClick={() => setOpenAddNewModal(false)} />
			<NewInstructorView setOpenAddNewModal={setOpenAddNewModal} />
		</Dialog>
	</form>
});

export default VideoDetailsForm
