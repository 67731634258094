import { Typography, Container, makeStyles, FormLabel, TextField, Grid, Button, Link } from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab';
import useAuth from "../hooks/useAuth"
import { Formik, Form } from 'formik'
import PublisherSiteLogo from './publisher-site/PublisherSiteLogo'
import * as Yup from 'yup'
import { useState } from 'react'
import { sendPasswordResetEmail } from '../actions/userActions';

const useStyles = makeStyles((theme) => ({
    container: {
        background: "white",
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        zIndex: 1,
        borderRadius: '6px',
        padding: '30px',
        [theme.breakpoints.up('md')]: {
            minWidth: '20vw',
            maxWidth: '25vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '40vw',
        },
        [theme.breakpoints.up('xs')]: {
            width: '95vw',
        },
    },
    form: {
        width: '100%',
		alignSelf: 'center',
	},
    field: {
        marginTop: '0.5rem'
    },
    button: {
        marginTop: '1rem'
    },
    link: {
        cursor: 'pointer'
    },
    floatRight: {
        float: 'right'
    }
}))

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required("Password is required")
})

const ResetSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required')
})

const LoginForm = ({ loginClick, createNewAccountClick }) => {
    const { user, login } = useAuth()
    const [reset, setReset] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    if (user) {
        // TODO: If user already logged in, redirect elsewhere
    }
    const classes = useStyles()
    const handleLoginClick = (formik) => {
        if (!formik.isValid) return
        // Don't double submit
        if (formik.isSubmitting) return
        // Don't submit unchanged form
        if (!formik.dirty) return
        
        formik.setSubmitting(true)
        login(formik.values.email, formik.values.password).then(async (response) => {
            loginClick()
        }).catch((e) => { 
            formik.setSubmitting(false)
            formik.setErrors({email: e.message, password: e.message})
        } )
    }

    const handleResetClick = (formik) => {
        if (!formik.isValid) return
        // Don't double submit
        if (formik.isSubmitting) return
        // Don't submit unchanged form
        if (!formik.dirty) return

        formik.setSubmitting(true)
        sendPasswordResetEmail(formik.values.email).then(async (response) => {
            setEmailSent(true)
            formik.setSubmitting(false)
        }).catch((e) => {
            formik.setSubmitting(false)
            console.log(e)
        })
    }
    // Setting the initial values with let instead const prevents a "changing uncontrolled input" error
    let initialValues = {
        email: '',
        password: ''
    }

    return(
    <Container className={classes.container}>
        <Grid container spacing={2} mt={1}>
                <Grid item md={12} sm={12} xs={12} textAlign="center">
                    <PublisherSiteLogo maxWidth="100%" />
                </Grid>
            <Grid item xs={12} md={12}>
                { !reset ?
                <Grid container>
                    <Formik validationSchema={LoginSchema} initialValues={initialValues}>
                        { formik => (
                            <Form className={classes.form} >
                                <Grid item lg={12} md={12} textAlign="center" className={classes.field}>
                                    <Typography color="textPrimary" variant="h5" >Login</Typography>
                                </Grid>
                                <Grid item lg={12} md={12} className={classes.field}>
                                    <FormLabel>
                                        <Typography color="textPrimary" variant="subtitle2" className={classes.formLabel}>Email</Typography>
                                    </FormLabel>
                                    <TextField fullWidth name="email" variant="outlined" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                        helperText={formik.touched.email && formik.errors.email} error={formik.touched.email && Boolean(formik.errors.email)}/>
                                </Grid>
                                <Grid item lg={12} md={12} className={classes.field}>
                                    <FormLabel>
                                        <Typography color="textPrimary" variant="subtitle2" className={classes.formLabel}>Password</Typography>
                                    </FormLabel>
                                    <TextField fullWidth name="password" variant="outlined" type="password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                        helperText={formik.touched.password && formik.errors.password} error={formik.touched.password && Boolean(formik.errors.password)}/>
                                </Grid>
                                <Grid item lg={12} md={12} className={classes.button}>
                                    <LoadingButton variant="contained" fullWidth onClick={() => {handleLoginClick(formik)}} disabled = {formik.isSubmitting || !formik.isValid || !formik.dirty}
                                    type="submit" loading={formik.isSubmitting}>
                                        Login
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12} marginTop={1}>
                                        <Link className={classes.link} onClick={() => {createNewAccountClick()}}>Create account</Link>
                                        <Link className={[classes.link, classes.floatRight]} onClick={()=>{setReset(true)}} sx={{float: 'right'}}>Forgot password?</Link>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                :
                <Grid container>
                    <Grid item lg={12} md={12} textAlign="center" className={classes.field}>
                        <Typography color="textPrimary" variant="h5" >Reset Password</Typography>
                    </Grid>
                    <Formik validationSchema={ResetSchema} initialValues={{email:''}}>
                        { formik => (
                            <Form className={classes.form} >
                                <Grid item lg={12} md={12} className={classes.field}>
                                    <FormLabel>
                                        <Typography color="textPrimary" variant="subtitle2" className={classes.formLabel}>Email</Typography>
                                    </FormLabel>
                                    <TextField fullWidth name="email" variant="outlined" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                            helperText={formik.touched.email && formik.errors.email} error={formik.touched.email && Boolean(formik.errors.email)}/>
                                </Grid>
                                <Grid item lg={12} md={12} className={classes.button}>
                                    <LoadingButton variant="contained" fullWidth onClick={() => {handleResetClick(formik)}} disabled = {formik.isSubmitting || !formik.isValid || !formik.dirty || emailSent}
                                        type="submit" loading={formik.isSubmitting}>
                                            {emailSent ? "Email Sent!" : "Send Reset Email"}
                                    </LoadingButton>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                    <Grid item xs={12} marginTop={1}>
                        <Button onClick={() => {setReset(false); setEmailSent(false)}}>Back</Button>
                    </Grid>
                </Grid>
                }
            </Grid>
        </Grid>
    </Container>
    )
}

export default LoginForm