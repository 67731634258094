import { Box, Grid, Typography, makeStyles, CircularProgress } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
const useStyles = makeStyles(() => ({
    header: {
        textTransform: 'uppercase',
        marginTop: '5rem',
        textAlign: 'center',
        '@media (max-width: 600px)': {
            marginTop: '2rem',
        }
    },
    subHeader: {
        marginTop: '2rem',
        textAlign: 'center',
        padding: '0 10rem',
    },
    button: {
        textTransform: 'uppercase',
        padding: '0.5rem 1.5rem',
        marginTop: '2rem',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        margin: '1rem 0'
    },
    imageContainer: {
        width: '300px',
        height: '300px',
        overflow: 'hidden',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        '&::before': {
            content: '""',
            display: 'block',
            paddingTop: '100%',
        },
        '@media (max-width: 1500px)': {
            width: '250px',
            height: '250px',
        },
        '@media (max-width: 1200px) and (min-width: 901px)': {
            width: '200px',
            height: '200px',
        },
    },
    image: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        display: 'block'
    }
}));

export const InstructorListView = ({ loading, instructors }) => {
    const classes = useStyles()
    const navigate = useNavigate()

    return (
        <Box
            sx={{
                backgroundColor: 'background.paper',
                width: '100%',
            }}
        >
            <Grid container spacing={3}
                sx={{
                    pt: 8,
                    px: 5,
                    '@media (max-width: 600px)': {
                        px: 2,
                        pt: 5
                    }
                }}>
                {loading
                    ?
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        <CircularProgress />
                    </Box>
                    :
                    instructors
                    &&
                    instructors.map((instructor, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}
                            onClick={() => navigate(`/yogarenew/instructors/${instructor.id}`)}
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }}>
                            <Box
                                sx={{
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}>
                                <Box className={classes.imageContainer}>
                                    <img
                                        src={instructor?.picture && instructor.picture.url}
                                        alt={instructor.name}
                                        className={classes.image}
                                    />
                                </Box>
                                <Box className={classes.textContainer}>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            textTransform: 'uppercase',
                                            mr: 2,
                                            textAlign: 'center',
                                            '@media (max-width: 600px)': {
                                                mr: 0,
                                            }
                                        }}
                                    >
                                        {instructor.name}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
            </Grid>
        </Box>
    )
}