import { Suspense, lazy } from 'react'
import AuthGuard from './components/AuthGuard'
import GuestGuard from './components/GuestGuard'
import SubscriberGuard from './components/SubscriberGuard'
import LoadingScreen from './components/LoadingScreen'
import DashboardLayout from './components/dashboard/DashboardLayout'
import { PublisherSiteLayout } from './components/publisher-site'
import PublisherSiteLayoutRoot from './components/publisher-site/PublisherSiteLayoutRoot'
import UploadVideo from './pages/publisher/channels/UploadVideo/UploadVideo'
import HelpTopicDetails from './pages/publisher/help/HelpTopicDetails'
import SuccessPage from './pages/yogarenew/subscriptions/success'
import NotFound from './NotFound'
import HeadManager from './components/head-manager/HeadManager'
import InstructorPage from './pages/yogarenew/instructors'
import InstructorDetailPage from './pages/yogarenew/instructors/DetailView'
import WorkshopView from './pages/publisher/workshops'
import { ZoomRedirect } from './pages/publisher/workshops/ZoomRedirect'
import Workshops from './pages/yogarenew/workshops'
import WorkshopDetailView from './pages/yogarenew/workshops/DetailView'
import TVLogin from './pages/authentication/TVLogin'

const Loadable = (Component) => (props) =>
(
	<Suspense fallback={<LoadingScreen />}>
		<Component {...props} />
	</Suspense>
)

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')))
const YogaRenewLogin = Loadable(lazy(() => import('./pages/authentication/YogaRenewLogin')))
const Register = Loadable(lazy(() => import('./pages/authentication/Register')))
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')))
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/passwordreset/index')))
const ForgotPassword = Loadable(lazy(() => import('./pages/authentication/ForgotPassword')))
const SignupFlow = Loadable(lazy(() => import('./pages/yogarenew/signup/index')))

// Privacy Plolicy page
const PrivacyPolicy = Loadable(lazy(() => import('./pages/privacy-policy')))

// DASHBOARD PAGES
const AccountView = Loadable(lazy(() => import('./pages/publisher/account')))
const AllFilesView = Loadable(lazy(() => import('./pages/publisher/content-manager/all-files')))
const ChannelsView = Loadable(lazy(() => import('./pages/publisher/channels')))
const ChannelDetailsDashboard = Loadable(lazy(() => import('./pages/publisher/channels/DetailsView/channel')))
const FolderView = Loadable(lazy(() => import('./pages/publisher/content-manager/all-files/folder')))
const VideoDetailsDashboard = Loadable(lazy(() => import('./pages/publisher/channels/DetailsView/video')))
const CustomerDetails = Loadable(lazy(() => import('./pages/publisher/customers/DetailsView/CustomerDetails')))
const CustomerEdit = Loadable(lazy(() => import('./pages/publisher/customers/EditView/CustomerEdit')))
const Settings = Loadable(lazy(() => import('./pages/publisher/settings/Settings')))

const HelpView = Loadable(lazy(() => import('./pages/publisher/help')))
const ReportsView = Loadable(lazy(() => import('./pages/publisher/reports')))
const InvoiceDetails = Loadable(lazy(() => import('./pages/publisher/dashboard/InvoiceDetails')))
const InvoiceList = Loadable(lazy(() => import('./pages/publisher/dashboard/InvoiceList')))
const OnboardingView = Loadable(lazy(() => import('./pages/publisher/onboarding')))
const Overview = Loadable(lazy(() => import('./pages/publisher/dashboard/Overview')))
const ProductCreate = Loadable(lazy(() => import('./pages/publisher/dashboard/ProductCreate')))
const ProductList = Loadable(lazy(() => import('./pages/publisher/dashboard/ProductList')))


// YogaRenew pages (PUBLISHER SITE PAGES)
const PublisherSite = Loadable(lazy(() => import('./pages/yogarenew/index')))
const PublisherClassesViews = Loadable(lazy(() => import('./pages/yogarenew/classes')))
const PublisherVideosViews = Loadable(lazy(() => import('./pages/yogarenew/videos')))
const PublisherVideoDetails = Loadable(lazy(() => import('./pages/yogarenew/videos/DetailView/index')))
const PublisherLiveStreamView = Loadable(lazy(() => import('./pages/yogarenew/videos/DetailView/LiveStreamView')))
const PublisherChannelPlaylist = Loadable(lazy(() => import('./pages/yogarenew/videos/ChannelPlaylist/index')))
const PublisherSubscriptionViews = Loadable(lazy(() => import('./pages/yogarenew/subscriptions/index')))
const PublisherCoursesViews = Loadable(lazy(() => import('./pages/yogarenew/courses')))
const PublisherCoursesDetailView = Loadable(lazy(() => import('./pages/yogarenew/courses/DetailView/index')))
const PublisherCourseInformation = Loadable(lazy(() => import('./pages/yogarenew/courses/DetailView/PublisherCourseInformation')))
const PublisherCourseCurriculum = Loadable(lazy(() => import('./pages/yogarenew/courses/DetailView/PublisherCourseCurriculum')))
const PublisherCourseLectureDetail = Loadable(lazy(() => import('./pages/yogarenew/courses/DetailView/PublisherCourseLectureDetail')))
const PublisherCourseInstructor = Loadable(lazy(() => import('./pages/yogarenew/courses/DetailView/PublisherCourseInstructor')))
const PublisherCourseCertificate = Loadable(lazy(() => import('./pages/yogarenew/courses/DetailView/PublisherCourseCertificate')))
const PublisherSeriesView = Loadable(lazy(() => import('./pages/yogarenew/series')))
const PublisherDetailsView = Loadable(lazy(() => import('./pages/yogarenew/series/DetailView')))
const MainHome = Loadable(lazy(() => import('./pages/yogarenew/home/Main')))
const WatchHistory = Loadable(lazy(() => import('./pages/yogarenew/videos/History/index')))
const UserFavorites = Loadable(lazy(() => import('./pages/yogarenew/videos/Favorites/index')))
const PublisherAboutViews = Loadable(lazy(() => import('./pages/yogarenew/about/index')))
const TermsPage = Loadable(lazy(() => import('./pages/yogarenew/TermsPage')))

// Series pages
const SeriesView = Loadable(lazy(() => import('./pages/publisher/series/index')))
const SeriesAddEditView = Loadable(lazy(() => import('./pages/publisher/series/CreateEditSeriesView/index')))
const UploadSeriesVideo = Loadable(lazy(() => import('./pages/publisher/series/UploadVideo/UploadVideo')))

// Courses pages
const CoursesView = Loadable(lazy(() => import('./pages/publisher/lms/index')))
// const CoursesDashboardView = Loadable(lazy(() => import('./pages/publisher/lms/dashboard/index')))
const CoursesCourseView = Loadable(lazy(() => import('./pages/publisher/lms/courses/index')))
const NewCourseView = Loadable(lazy(() => import('./pages/publisher/lms/courses/NewCourseView/index')))
const CourseDetailView = Loadable(lazy(() => import('./pages/publisher/lms/courses/DetailView/index')))
const CourseInformation = Loadable(lazy(() => import('./pages/publisher/lms/courses/DetailView/CourseInformation')))
const CourseCurriculum = Loadable(lazy(() => import('./pages/publisher/lms/courses/DetailView/CourseCurriculum')))
const CourseCurriculumNewSection = Loadable(lazy(() => import('./pages/publisher/lms/courses/DetailView/CourseCurriculumNewSection')))
const CourseCertificates = Loadable(lazy(() => import('./pages/publisher/lms/courses/DetailView/certificates/CourseCertificates')))
const CourseCertificate = Loadable(lazy(() => import('./pages/publisher/lms/courses/DetailView/certificates/CourseCertificate')))
const CourseInstructor = Loadable(lazy(() => import('./pages/publisher/lms/courses/DetailView/CourseInstructor')))
// const CourseLectures = Loadable(lazy(() => import('./pages/publisher/lms/courses/DetailView/CourseLectures')))
const CourseLectureDetails = Loadable(lazy(() => import('./pages/publisher/lms/courses/DetailView/CourseLectureDetails')))

// Instructor Pages 
const InstructorsView = Loadable(lazy(() => import('./pages/publisher/instructors/index')))
const NewInstructorView = Loadable(lazy(() => import('./pages/publisher/instructors/NewInstructorView/index')))
const InstructorDetailView = Loadable(lazy(() => import('./pages/publisher/instructors/DetailView/index')))

const CoursesUsersView = Loadable(lazy(() => import('./pages/publisher/lms/users/index')))
const CoursesSalesView = Loadable(lazy(() => import('./pages/publisher/lms/sales/index')))
const CoursesSiteView = Loadable(lazy(() => import('./pages/publisher/lms/site/index')))
const CoursesEmailsView = Loadable(lazy(() => import('./pages/publisher/lms/emails/index')))
const CoursesSettingsView = Loadable(lazy(() => import('./pages/publisher/lms/settings/index')))

const routes = [
	{
		path: 'authentication',
		children: [
			{
				path: 'login',
				element: (
					<GuestGuard>
						<Login />
					</GuestGuard>
				),
			},
			{
				path: 'yogarenewlogin',
				element: (
					<SubscriberGuard>
						<YogaRenewLogin />
					</SubscriberGuard>
				),
			},
			{
				path: 'forgot-password',
				element: (
					<SubscriberGuard>
						<ForgotPassword />
					</SubscriberGuard>
				),
			},
			{
				path: 'login-unguarded',
				element: <>
					<Login />
				</>,
			},
			{
				path: 'password-reset',
				element: <>
					<PasswordReset />
				</>,
			},
			{
				path: 'register',
				element: (
					<GuestGuard>
						<Register />
					</GuestGuard>
				),
			},
			{
				path: 'register-unguarded',
				element: <Register />,
			},
			{
				path: 'verify-code',
				element: <VerifyCode />,
			},
			{
				path: 'activate',
				element: <TVLogin />,
			}
		],
	},
	{
		path: 'dashboard',
		element: (
			<AuthGuard>
				<DashboardLayout />
				<HeadManager />
			</AuthGuard>
		),
		children: [
			{
				index: true,
				element: <Overview />,
			},
			{
				path: 'account',
				children: [
					{
						index: true,
						element:
							<>
								<HeadManager />
								<AccountView />
							</>,
					},
				],
			},
			{
				path: 'channels',
				children: [
					{
						index: true,
						element: <>
							<HeadManager />
							<ChannelsView />
						</>,
					},
					{
						path: 'new',
						element:
							<>
								<HeadManager />
								<UploadVideo />
							</>,
					},
					{
						path: ':channelId',
						element:
							<>
								<HeadManager />
								<ChannelDetailsDashboard />
							</>,
					},
				],
			},
			{
				path: 'videos',
				children: [
					{
						index: true,
						// element: <ChannelsView />,
					},
					{
						path: 'new',
						element:
							<>
								<HeadManager />
								<UploadVideo />
							</>,
					},
					{
						path: ':videoId',
						element:
							<>
								<HeadManager />
								<VideoDetailsDashboard />
							</>,
					},

				],
			},
			{
				path: 'instructors',
				children: [
					{
						index: true,
						element:
							<>
								<HeadManager />
								<InstructorsView />
							</>,
					},
					{
						path: 'new',
						element:
							<>
								<HeadManager />
								<NewInstructorView />
							</>,
					},
					{
						path: ':instructorId',
						element:
							<>
								<HeadManager />
								<InstructorDetailView />
							</>,
					},
				],
			},
			{
				path: 'contentmanager',
				children: [
					{
						index: true,
						element:
							<>
								<HeadManager />
								<AllFilesView />
							</>,
					},
					{
						path: 'all',
						element:
							<>
								<HeadManager />
								<AllFilesView />
							</>,
					},
					{
						path: ':fileId',
						element:
							<>
								<HeadManager />
								<FolderView />
							</>,
					},
				]
			},
			{
				path: 'help',
				children: [
					{
						index: true,
						element:
							<>
								<HeadManager />
								<HelpView />
							</>,
					},
					{
						path: ':helpId',
						element:
							<>
								<HeadManager />
								<HelpTopicDetails />
							</>,
					},
				],
			},
			{
				path: 'onboarding',
				element: <OnboardingView />,
			},
			{
				path: 'reports',
				element: <ReportsView />,
			},
			{
				path: 'workshops',
				element: <WorkshopView />,
			},
			{
				path: 'zoomcallback',
				element: <ZoomRedirect />,
			},
			{
				path: 'series',
				children: [
					{
						index: true,
						element: <SeriesView />,
					},
					{
						path: 'new',
						element: <SeriesAddEditView />
					},
					{
						path: 'edit/:seriesId',
						element: <SeriesAddEditView />
					},
					{
						path: 'new-video',
						element: <UploadSeriesVideo />
					}
				],
			},
			{
				path: 'lms',
				children: [
					{
						index: true,
						element: <CoursesView />,
					},
					{
						path: 'courses',
						children: [
							{
								index: true,
								element: <CoursesCourseView />
							},
							{
								path: 'new',
								element: <NewCourseView />
							},
							{
								path: ':courseId',
								element: <CourseDetailView />,
								children: [
									{
										path: 'information',
										element: <CourseInformation />,
									},
									{
										path: 'instructor',
										element: <CourseInstructor />,
									},
									{
										path: 'curriculum',
										children: [
											{
												index: true,
												element: <CourseCurriculum />
											},
											{
												path: 'newsection',
												element: <CourseCurriculumNewSection />
											},
										]
									},
									{
										path: 'certificates',
										children: [
											{
												index: true,
												element: <CourseCertificates />,
											},
											{
												path: 'certificate',
												children: [
													{
														index: true,
														element: <CourseCertificate />,
													},
													{
														path: ':certificateId',
														element: <CourseCertificate />,
													},
												],
											},
										],
									},
									{
										path: 'lectures',
										children: [
											{
												index: true,
												element: <CourseDetailView />
											},
											{
												path: ':lectureId',
												element: <CourseLectureDetails />
											},
										]
									}
								]
							}
						]
					},
					{
						path: 'users',
						element: <CoursesUsersView />
					},
					{
						path: 'sales',
						element: <CoursesSalesView />
					},
					{
						path: 'site',
						element: <CoursesSiteView />
					},
					{
						path: 'emails',
						element: <CoursesEmailsView />
					},
					{
						path: 'settings',
						element: <CoursesSettingsView />
					},
				]
			},
			{
				path: 'customers',
				children: [
					{
						path: ':customerId',
						element: <CustomerDetails />,
					},
					{
						path: ':customerId/edit',
						element: <CustomerEdit />,
					},
				],
			},
			{
				path: 'invoices',
				children: [
					{
						index: true,
						element: <InvoiceList />,
					},
					{
						path: ':invoiceId',
						element: <InvoiceDetails />,
					},
				],
			},
			{
				path: 'products',
				children: [
					{
						index: true,
						element: <ProductList />,
					},
					{
						path: 'new',
						element: <ProductCreate />,
					},
				],
			},
			{
				path: 'settings',
				children: [
					{
						index: true,
						element: <Settings />,
					},
				],
			}
		],
	},
	{
		path: '/',
		element: <PublisherSiteLayoutRoot />,
		children: [
			{
				path: '/yogarenew/signup/*',
				element: <SubscriberGuard><SignupFlow /></SubscriberGuard>,
			},
			{
				path: '/yogarenew',
				element: <PublisherSiteLayout />,
				children: [
					{
						index: true,
						element:
							<>
								<HeadManager />
								<PublisherSite />
							</>,
					},
					{
						path: 'main',
						element:
							<>
								<HeadManager />
								<MainHome />
							</>,
					},
					{
						path: 'privacy-policy',
						element:
							<>
								<HeadManager />
								<PrivacyPolicy />
							</>,
					},
					{
						path: 'videos',
						children: [
							{
								index: true,
								element: <><HeadManager /><MainHome /></>,
							},
							{
								path: 'allclasses',
								element:
									<>
										<HeadManager />
										<PublisherVideosViews />
									</>,
							},
							{
								path: ':videoId',
								element:
									<>
										<HeadManager />
										<PublisherVideoDetails />
									</>,
							},
						],
					},
					{
						path: 'series',
						children: [
							{
								index: true,
								element: <PublisherSeriesView />,
							},
							{
								path: ':seriesId',
								children: [
									{
										index: true,
										element: <PublisherDetailsView />,
									},
									{
										path: 'videos/:videoId',
										element:
											<>
												<HeadManager />
												<PublisherVideoDetails />
											</>,

									},
								],
							},
						],
					},
					{
						path: 'workshops',
						children: [
							{
								index: true,
								element:
									<>
										<SubscriberGuard>
											<Workshops />
										</SubscriberGuard>
									</>,
							},
							{
								path: ':workshopId',
								element:
									<>
										<SubscriberGuard>
											<WorkshopDetailView />
										</SubscriberGuard>
									</>,
							},
						],
					},
					{
						path: 'livestream/:streamId',
						element: <PublisherLiveStreamView />,
					},
					{
						path: 'courses',
						children: [
							{
								index: true,
								element:
									<>
										<HeadManager />
										<PublisherCoursesViews />
									</>,
							},
							{
								path: ':courseId',
								element:
									<>
										<HeadManager />
										<AuthGuard>
											<PublisherCoursesDetailView />
										</AuthGuard>
									</>,
								children: [
									{
										path: 'information',
										element:
											<>
												<HeadManager />
												<PublisherCourseInformation />
											</>,
									},
									{
										path: 'curriculum',
										children: [
											{
												index: true,
												element:
													<>
														<HeadManager />
														<AuthGuard>
															<PublisherCourseCurriculum />
														</AuthGuard>
													</>,
											},
										]
									},
									{
										path: 'lectures',
										children: [
											{
												index: true,
												element:
													<>
														<HeadManager />
														<AuthGuard>
															<PublisherCoursesDetailView />
														</AuthGuard>
													</>,
											},
											{
												path: ':lectureId',
												element:
													<>
														<HeadManager />
														<AuthGuard>
															<PublisherCourseLectureDetail />
														</AuthGuard>
													</>,
											},
										]
									},
									{
										path: 'certificate',
										element:
											<>
												<HeadManager />
												<AuthGuard>
													<PublisherCourseCertificate />
												</AuthGuard>
											</>,
									},
									{
										path: 'instructor',
										children: [
											{
												index: true,
												element:
													<>
														<HeadManager />
														<AuthGuard>
															<PublisherCourseInstructor />
														</AuthGuard>
													</>,
											},
										]
									},
								],
							},
						],
					},
					{
						path: 'classes',
						element:
							<>
								<HeadManager />
								<PublisherClassesViews />
							</>,
					},
					{
						path: 'history',
						element:
							<>
								<HeadManager />
								<WatchHistory />
							</>,
					},
					{
						path: 'favorites',
						element:
							<>
								<HeadManager />
								<UserFavorites />
							</>,
					},
					{
						path: 'channels',
						children: [
							{
								index: true,
							},
							{
								path: ':channelId',
								element:
									<>
										<HeadManager />
										<PublisherChannelPlaylist />
									</>,
							},
						],
					},
					{
						path: 'instructors',
						children: [
							{
								index: true,
								element:
									<>
										<HeadManager />
										<InstructorPage />
									</>,
							},
							{
								path: ':instructorId',
								element:
									<>
										<HeadManager />
										<InstructorDetailPage />
									</>,
							},
						],
					},
					{
						path: 'about',
						element:
							<>
								<HeadManager />
								<PublisherAboutViews />
							</>,
					},
					{
						path: 'passwordreset',
						element:
							<>
								<HeadManager />
								<PasswordReset />
							</>,
					},
					{
						path: 'termsofuse',
						element:
							<>
								<HeadManager />
								<TermsPage />
							</>
					},
					{
						path: 'subscriptions',
						children: [
							{
								index: true,
								element: <PublisherSubscriptionViews />
							},
							{
								path: 'success',
								element: <SuccessPage />
							}
						]
					},
				],
			},
			// {
			// 	path: '/yogarenew/courses',
			// 	children: [
			// 		{
			// 			index: true,
			// 			element: <PublisherCoursesViews />,
			// 		},
			// 		{
			// 			path: ':courseId',
			// 			element: <PublisherCoursesDetailView />,
			// 			children: [
			// 				{
			// 					path: 'information',
			// 					element: <PublisherCourseInformation />,
			// 				},
			// 				{
			// 					path: 'curriculum',
			// 					children: [
			// 						{
			// 							index: true,
			// 							element: <PublisherCourseCurriculum />,
			// 						},
			// 					]
			// 				},
			// 				{
			// 					path: 'lectures',
			// 					children: [
			// 						{
			// 							index: true,
			// 							element: <PublisherCoursesDetailView />,
			// 						},
			// 						{
			// 							path: ':lectureId',
			// 							element: <PublisherCourseLectureDetail />,
			// 						},
			// 					]
			// 				},
			// 				{
			// 					path: 'certificate',
			// 					element: <PublisherCourseCertificate />,
			// 				},
			// 				{
			// 					path: 'instructor',
			// 					children: [
			// 						{
			// 							index: true,
			// 							element: <PublisherCourseInstructor />,
			// 						},
			// 					]
			// 				},
			// 			],
			// 		},
			// 	],
			// },
			// {
			// 	path: 'yogarenew',
			// 	element: <PublisherSiteLayout />,
			// 	children: [
			// 		{
			// 			index: true,
			// 			element: <PublisherSite />,
			// 		},
			// 		{
			// 			path: 'videos',
			// 			children: [
			// 				{
			// 					index: true,
			// 					element: <PublisherVideosViews />,
			// 				},
			// 				{
			// 					path: ':videoId',
			// 					element: <PublisherVideoDetails />,
			// 				},
			// 			],
			// 		},
			// 		{
			// 			path: 'classes',
			// 			element: <PublisherClassesViews />,
			// 		},
			// 		{
			// 			path: 'history',
			// 			element: <WatchHistory />,
			// 		},
			// 		{
			// 			path: 'favorites',
			// 			element: <UserFavorites />,
			// 		},
			// 		{
			// 			path: 'channels',
			// 			children: [
			// 				{
			// 					index: true,
			// 				},
			// 				{
			// 					path: ':channelId',
			// 					element: <PublisherChannelPlaylist />,
			// 				},
			// 			],
			// 		},
			// 		{
			// 			path: 'about',
			// 			element: <PublisherAboutViews />,
			// 		},
			// 		{
			// 			path: 'passwordreset',
			// 			element: <PasswordReset />
			// 		},
			// 		{
			// 			path: 'termsofuse',
			// 			element: <TermsPage />
			// 		},
			// 		{
			// 			path: 'subscriptions',
			// 			children: [
			// 				{
			// 					index: true,
			// 					element: <PublisherSubscriptionViews />
			// 				},
			// 				{
			// 					path: 'success',
			// 					element: <SuccessPage />
			// 				}
			// 			]
			// 		},
			// 	],
			// },
		]
	},
	{
		path: '*',
		element: <NotFound />
	},
]

export default routes
