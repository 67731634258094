import { createContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { authApi } from '../actions/authApi'
import { getCurrentUser } from '../actions/userActions'
import Bugsnag from '@bugsnag/js'

const nodeURL = process.env.REACT_APP_STRAPI_URL

const initialState = {
	isAuthenticated: false,
	user: undefined,
	isLoading: true,
}

const handlers = {
	INITIALIZE: (state, action) => {
		const { isAuthenticated, user } = action.payload
		// If not authenticted, remove any old tokens
		if (!isAuthenticated && localStorage.getItem('accessToken')) {
			localStorage.removeItem('accessToken')
		}

		return {
			...state,
			isAuthenticated,
			user,
			isLoading: false
		}
	},
	LOGIN: (state, action) => {
		const { user } = action.payload

		return {
			...state,
			isAuthenticated: true,
			user,
		}
	},
	LOGOUT: (state) => ({
		...state,
		isAuthenticated: false,
		user: null,
	}),
	REGISTER: (state, action) => {
		const { user } = action.payload

		return {
			...state,
			isAuthenticated: true,
			user,
		}
	},
	UPDATE_PROFILE: (state, action) => {
		const { user } = action.payload

		return {
			...state,
			isAuthenticated: true,
			user,
		}
	},
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state)

const AuthContext = createContext({
	...initialState,
	platform: 'JWT',
	login: () => Promise.resolve(),
	logout: () => Promise.resolve(),
	register: () => Promise.resolve(),
	updateProfile: () => Promise.resolve(),
	impersonateUser: () => Promise.resolve(),
	stopImpersonating: () => Promise.resolve()
})

export const AuthProvider = (props) => {
	const { children } = props
	const [state, dispatch] = useReducer(reducer, initialState)

	function parseJwt(token) {
		try {
			return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	const redirectToLogin = () => {
		const loginPage = '/authentication/yogarenewlogin'
		if (window.location.pathname !== loginPage) {
			window.location.href = loginPage;
		}
	}

	async function initialize() {
		const accessToken = localStorage.getItem('accessToken');

		if (!accessToken) {
			dispatch({
				type: 'INITIALIZE',
				payload: {
					isAuthenticated: false,
					user: null,
				},
			});
			return;
		}

		const parsed = parseJwt(accessToken);

		if (!parsed?.exp) {
			localStorage.removeItem('accessToken');
			redirectToLogin();
			return;
		}

		const expires = parsed.exp * 1000;
		const now = Date.now();

		if (expires < now) {
			localStorage.removeItem('accessToken');
			redirectToLogin();
		}

		try {

			const response = await getCurrentUser();
			if (response.statusCode === 401) {
				localStorage.removeItem('accessToken');
				redirectToLogin();
				return;
			}

			sessionStorage.setItem('user', JSON.stringify(response));

			dispatch({
				type: 'INITIALIZE',
				payload: {
					isAuthenticated: true,
					user: response,
				},
			});

			Bugsnag.setUser(response.id, response.email);

		} catch (err) {
			console.error('Error initializing user context', err);
			dispatch({
				type: 'INITIALIZE',
				payload: {
					isAuthenticated: false,
					user: null,
				},
			});
		}
	}

	useEffect(() => initialize(), []);

	const login = async (email, password) => {
		try {
			const { user, jwt } = await authApi.login({ email, password })
			localStorage.setItem('accessToken', jwt)
			sessionStorage.setItem('user', JSON.stringify(user))
			dispatch({
				type: 'LOGIN',
				payload: {
					user,
				},
			})
			Bugsnag.setUser(user.id, user.email);
			return { success: true }
		} catch (err) {
			return { success: false, error: err.message }
		}
	}

	const logout = async () => {
		localStorage.removeItem('accessToken')
		localStorage.removeItem('user') // Legacy
		sessionStorage.removeItem('user')
		dispatch({ type: 'LOGOUT' })
	}

	const register = async (values) => {
		const { user, jwt } = await authApi.register({
			firstName: values.firstName,
			lastName: values.lastName,
			username: values.email,
			email: values.email,
			subtype: values.subtype,
			password: values.password,
		})

		localStorage.setItem('accessToken', jwt)
		sessionStorage.setItem('user', JSON.stringify(user))
		//if (feedfm_token && feedfm_token.success) {
		//	localStorage.setItem('feedfm_token', JSON.stringify(feedfm_token.access_token))
		//}

		dispatch({
			type: 'REGISTER',
			payload: {
				user,
			},
		})
		return { error: false, success: true }
	}

	const updateProfile = async (values) => {
		sessionStorage.setItem('user', JSON.stringify(values))

		dispatch({
			type: 'UPDATE_PROFILE',
			payload: {
				user: values,
			},
		})
	}

	const impersonateUser = async (userId) => {
		const response = await fetch(`${nodeURL}/users/impersonate/${userId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
			}
		});
		const data = await response.json();
		if (data && data.jwt) {
			const { jwt, user } = data;
			const impersonatorUser = sessionStorage.getItem('user');

			sessionStorage.removeItem('impersonatorJWT');
			sessionStorage.removeItem('impersonatorUser');

			sessionStorage.setItem('impersonatorJWT', localStorage.getItem('accessToken'));
			sessionStorage.setItem('impersonatorUser', impersonatorUser);
			sessionStorage.setItem('impersonating', true)
			localStorage.setItem('accessToken', jwt);
			sessionStorage.setItem('user', JSON.stringify(user));
			dispatch({
				type: 'LOGIN',
				payload: {
					user,
					impersonating: true
				}
			});
		}

		return data;

	}

	const stopImpersonating = async () => {
		dispatch({
			type: 'LOGIN',
			payload: {
				user: sessionStorage.getItem('impersonatorUser'),
				impersonating: false
			}
		})
		localStorage.setItem('accessToken', sessionStorage.getItem('impersonatorJWT'));
		sessionStorage.setItem('user', sessionStorage.getItem('impersonatorUser'));
		localStorage.removeItem('impersonatorJWT');
		localStorage.removeItem('impersonatorUser');
		sessionStorage.removeItem('impersonating');
		// refresh page to update data after end of impersonating session
		setTimeout(() => {
			window.location.reload();
		}, 100)
	}

	return (
		<AuthContext.Provider
			value={{
				...state,
				platform: 'JWT',
				login,
				logout,
				register,
				updateProfile,
				impersonateUser,
				stopImpersonating
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

export default AuthContext
