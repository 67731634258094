import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {},
	heroSubtitle: {
		fontWeight: '400',
		lineHeight: '34px',
		letterSpacing: '1.5px',
	},
	headerMain: {
		maxWidth: '100%',
		transformOrigin: '0 100%',
	},
}))

const PublisherHero = ({
	siteName,
	header,
	subheader,
	button1text,
	button1color,
	button2text,
	button2color,
	heroImage,
}) => {
	const classes = useStyles()

	return (
		<Box sx={{ backgroundColor: 'background.paper' }}>
			<Grid container>
				<Grid item md={6} sx={{ py: 10 }}>
					<Grid item sx={{ pl: 15, pt: 18 }}>
						<Typography
							align="left"
							color="textPrimary"
							variant="h2"
							sx={{
								zIndex: 20,
							}}
						>
							{header}
						</Typography>
					</Grid>
					<Grid item sx={{ pl: 15, pr: 4, pt: 3 }}>
						<Typography
							align="left"
							color="textPrimary"
							variant="h5"
							sx={{
								zIndex: 20,
							}}
							className={classes.heroSubtitle}
						>
							{subheader}
						</Typography>
					</Grid>

					<Grid item sx={{ pl: 15, pt: 5 }}>
						<Button
							component="a"
							href={`/${siteName}/subscriptions`}
							size="small"
							variant="contained"
							sx={{
								py: 0.75,
								px: 3,
								mr: 3,
								backgroundColor: button1color,
								'&:hover': {
									backgroundColor: button1color,
									filter: 'brightness(0.85)',
								},
							}}
						>
							<Typography variant="subtitle2" sx={{ fontWeight: 600, textTransform: 'uppercase' }}>
								{button1text}
							</Typography>
						</Button>
						<Button
							component="a"
							href={`/${siteName}/subscriptions`}
							size="small"
							target="_blank"
							variant="outlined"
							sx={{
								py: 0.75,
								px: 3,
								color: button2color,
								borderColor: button2color,
								'&:hover': {
									borderColor: button2color,
									filter: 'brightness(0.75)',
								},
							}}
						>
							<Typography variant="subtitle2" sx={{ fontWeight: 600, textTransform: 'uppercase' }}>
								{button2text}
							</Typography>
						</Button>
					</Grid>
				</Grid>

				<Grid item md={6}>
					<Box
						sx={{
							position: 'relative',
							backgroundImage: `url(${heroImage})`,
							backgroundSize: 'cover',
							height: '63vh',
						}}
					></Box>
				</Grid>
			</Grid>
		</Box>
	)
}

export default PublisherHero
