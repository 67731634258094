import Analytics from 'analytics'

const nodeURL = process.env.REACT_APP_STRAPI_URL
let updateInterval
let lastUpdate = new Date().getTime()
let watchTime = 0
let seekTime = 0
let lastUpdateWatchTime = 0
let playing = false;
let videoLoadCalled = false;

const headers = () => {
    const accessToken = localStorage.getItem('accessToken');
    const headers = { 
        'Content-Type': 'application/json',
        'x-analytics-source': 'web'
     }
    if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
    return headers;
}

/**
 * @typedef {{
 *  event: 'video_impression' | 'clickthrough' | 'error' | 'page_view' | 'session_start' | 'video_start' | 'video_end' | 'video_pause' | 'video_play' | 'video_seek' | 'video_search' | 'interaction'
 *  sessionId: string
 *  userId?: string
 *  targetId?: string
 *  tag?: string
 *  referrer?: string
 *  timestamp: Date
 *  pageUrl: string
 * }} AnalyticsEvent
 */

const analytics = Analytics({
    app: 'peli',
    plugins: [{
        name: 'watchtime',
        methods: {
            videoLoad: async (videoId, userId) => {
                if (!videoLoadCalled) {
                    videoLoadCalled = true;
                    return getWatchtimeAnalyticsId(videoId, userId).then((data) => data.id)
                }
                // Return null to indicate that videoLoad has already been called
                return { id: null };
            },
            videoPlay: (analyticsId, currentTime) => {
                if (!playing) {
                    lastUpdate = new Date().getTime()
                    playing = true
                }
                seekTime = currentTime
                if (playing) update(analyticsId)
            },
            videoPause: (analyticsId, currentTime) => {
                if (playing) {
                    seekTime = currentTime
                    update(analyticsId, true)
                    playing = false
                }
            },
            videoEnd: (analyticsId, currentTime) => {
                seekTime = currentTime
                update(analyticsId, true)
                playing = false
            },
            unloaded: (analyticsId) => {
                update(analyticsId, true)
                clearInterval(updateInterval)
                videoLoadCalled = false;
                watchTime = 0
            }
        }
    },
    peliAnalyticsPlugin({})]
});

const update = (analyticsId, commit = false) => {
    if (playing) {
        const now = new Date().getTime()
        watchTime += now - (lastUpdate ? lastUpdate : now)
        lastUpdate = now
    }
    // Rate limit updates
    if (watchTime - lastUpdateWatchTime >= 10000) {
        updateWatchtimeAnalytics(analyticsId, Math.floor(watchTime / 1000), Math.floor(seekTime), commit)
        lastUpdateWatchTime = watchTime
    }
}

const updateWatchtimeAnalytics = async (analyticsId, watchtime, seektime, commit = false) => {
    const opts = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify({
            seek_time: seektime,
            watch_time: watchtime,
            commit: commit
        }),
        credentials: 'include'
    }

    try {
        await fetch(`${nodeURL}/video-views/${analyticsId}`, opts).then((data) => data.json())
    } catch (err) {
        console.error("Error updating watchtime", err)
    }
}

const getWatchtimeAnalyticsId = async (videoId, userId) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify({ video: videoId, user: userId }),
        credentials: 'include'
    }

    try {
        return await fetch(`${nodeURL}/video-views`, opts).then((data) => data.json())
    } catch (err) {
        console.error("Error creating watchtime", err)
    }

    return { id: null }
}

const flushInterval = 10000;
const flushIntervalRef = setInterval(() => {
    analytics.plugins['peli-analytics'].flush();
}, flushInterval);

window.addEventListener('beforeunload', () => {
    clearInterval(flushIntervalRef);
    analytics.plugins['peli-analytics'].flush();
});

function peliAnalyticsPlugin(config) {
    /** @type {AnalyticsEvent[]} */
    const analyticsEventQueue = [];

    const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL

    const getSession = async () => {
        const anonId = localStorage.getItem('__anon_id')?.replace(/"/g, '');
        // TODO: will this be set by the time this is called?
        let userId = null;

        const userData = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
        if(userData) userId = userData._id;

        const opts = {
            method: 'GET',
            headers: headers(),
            credentials: 'include'
        }

        const url = new URL('/session', analyticsUrl);
        if(anonId) url.searchParams.append('anonymousId', anonId);
        if(userId) url.searchParams.append('userId', userId);

        return await fetch(url, opts).then(data => data.json())
    }

    return {
        name: 'peli-analytics',
        config: Object.assign({}, config),
        initialize: ({ config }) => {
            getSession()
            .then(data => analytics.plugins['peli-analytics'].sessionId = data.id)
            .catch(err => console.error("Error getting session", err));
        },
        page: ({ payload }) => {
            console.log('Peli analytics plugin page tracked', payload)
        },
        track: ({ payload }) => {
            analyticsEventQueue.push(payload);
        },
        identify: ({ payload }) => {
            console.log('Peli analytics plugin user identified', payload)
        },
        loaded: () => {
            return true
        },
        methods: {
            flush: async () => {
                const events = analyticsEventQueue.splice(0, analyticsEventQueue.length);
                if (events.length === 0) return;

                const mapped = events.map(event => {
                    const base = {
                        name: event.event,
                        timestamp: new Date(event.meta.ts)
                    }

                    Object.assign(base, event.properties);

                    return base;
                });

                const opts = {
                    method: 'POST',
                    headers: headers(),
                    body: JSON.stringify({ events: mapped }),
                    credentials: 'include'
                }

                try {
                    await fetch(`${analyticsUrl}/events`, opts);
                } catch (err) {
                    console.error("Error sending analytics", err)
                }
            }
        },
        events: [
            'video_impression',
            'clickthrough',
            'error',
            'page_view',
            'session_start',
            'video_start',
            'video_end',
            'video_pause',
            'video_play',
            'video_seek',
            'video_search',
            'interaction'
        ]
    }
}

export default analytics;
