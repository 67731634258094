import { createSlice } from '@reduxjs/toolkit'
import objFromArray from '../utils/objFromArray'
import { fetchInstructors, createInstructor, deleteInstructor } from '../actions/instructorsApi'

const initialState = {
  isLoaded: false,
  instructors: {
    byId: {},
    allIds: [],
  },
}

const slice = createSlice({
  name: 'instructors',
  initialState,
  reducers: {
    getInstructors(state, action) {
      const { instructors } = action.payload
      state.instructors.byId = objFromArray(instructors)
      state.isLoaded = true
    },
    addInstructor(state, action) {
      const instructor = action.payload;

      state.instructors.byId[instructor.id] = instructor;
      state.instructors.allIds.push(instructor.id);
    },
    removeInstructor(state, action) {
      const instructorId = action.payload;

      delete state.instructors.byId[instructorId];
      state.instructors.allIds = state.instructors.allIds.filter((_listId) => _listId !== instructorId);
    },
  },
})

export const { reducer } = slice

export const getInstructors = (id) => async (dispatch) => {
  const instructors = await fetchInstructors(id)

  dispatch(slice.actions.getInstructors({ instructors }))
}

export const addInstructor = (values) => async (dispatch) => {
  const response = await createInstructor(values)
  dispatch(slice.actions.addInstructor(values))
  return response
}

export const removeInstructor = (id) => async (dispatch) => {
  const response = await deleteInstructor(id)

  dispatch(slice.actions.removeInstructor(id))
  return response
}


export default slice
