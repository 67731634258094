import { Typography, Box, Container, makeStyles, TextField, Grid, Button, RadioGroup, Radio, FormControlLabel, Checkbox } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as Yup from 'yup'
import { FormikWizard } from 'formik-wizard-form';
import { cancelSubscription } from '../actions/subscriptionsApi'
import useAuth from '../hooks/useAuth'
import useSupport from '../hooks/useSupport'
import { LoadingButton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    container: {
        background: "white",
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        zIndex: 1,
        borderRadius: '6px',
        padding: '30px',
        [theme.breakpoints.up('md')]: {
            minWidth: '20vw',
            maxWidth: '25vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '30vw',
            maxWidth: '40vw'
        },
        [theme.breakpoints.up('xs')]: {
            width: '80vw',
        },
    },
    form: {
        alignSelf: 'center',
        width: '100%'
    },
    field: {
        marginTop: '0.5rem'
    },
    button: {
        marginTop: '1rem',
        marginLeft: '1rem'
    }
}))

const CancelSurvey = ({ values, handleChange, setFieldValue }) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5">Cancel Subscription</Typography>
            </Grid>
            <Grid item xs={12} marginBottom={4}>
                <Typography variant="body2">
                    Before you go, could you quickly let us know why?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">What Can We Work On?</Typography>
            </Grid>
            {values.cancel_reason !== "other" ?
                <RadioGroup name="cancel_reason" id="cancel_reason" onChange={handleChange} value={values.cancel_reason}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel value="lackingcontent" control={<Radio />} label="Not enough content" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel value="differentcontent" control={<Radio />} label="I'm looking for different content" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel value="expensive" control={<Radio />} label="Too expensive" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel value="notusing" control={<Radio />} label="Not using it" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel value="other" control={<Radio />} label="Other" />
                        </Grid>
                    </Grid>
                </RadioGroup>
                :
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='body2'>
                            Could you let us know why you're looking to leave?
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button startIcon={<ArrowBackIcon />} onClick={() => (setFieldValue('cancel_reason', ''))}>Other Reason</Button>
                    </Grid>
                </Grid>
            }
            <Grid item xs={12}>
                <Typography variant="body2" gutterBottom marginTop={2} marginBottom={1}>
                    Before you go, we are always looking to improve our product, let’s work through your problem together.
                </Typography>
            </Grid>
            <Grid item xs={12} marginBottom={3}>
                <TextField
                    id="outlined-textarea"
                    name="feedback"
                    if="feedback"
                    label="Tell us about your problem"
                    multiline
                    fullWidth
                    minRows={5}
                    maxRows={5}
                    onChange={handleChange}
                    value={values.feedback}
                />
            </Grid>
        </Grid>
    )
}

const Confirmation = ({ errors, values, handleChange }) => (
    <Grid container>
        <Grid item xs={12}>
            <Typography variant="h5">
                Are you sure you want to cancel your subscription?
            </Typography>
        </Grid>
        <Grid item xs={12} marginBottom={2}>
            <FormControlLabel
                control={<Checkbox name='checkbox1' onChange={handleChange} checked={values.checkbox1} error={!!errors.checkbox1} helperText={errors.checkbox1} />}
                label={<Typography variant="body2">{`Yes, please cancel.`}</Typography>}
            />
        </Grid>
    </Grid>
)

const FinalConfirmation = ({ values }) => (
    <Box>
        <Box display="flex" justifyContent="center" sx={{ my: 2 }}>
            <img src="/static/sadsmiley.jpeg" alt="sad to see you go" width="120px" />
        </Box>
        <Box sx={{ p: 2 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
                Sorry to see you go.
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                We're sad to see you go, but happy that you were able to a part of our community for a little while.
                Your {values.siteName} account has been cancelled. We wish you the best and hope you can come back soon.
            </Typography>
            <Typography variant="body2" style={{ fontWeight: 600 }} sx={{ mb: 2 }}>
                The {values.siteName} Team
            </Typography>
        </Box>
        <Box display="flex" justifyContent="center" sx={{ my: 2 }}>
            <Button component="a" onClick={() => values.handleLastClick()}>
                Return to {values.siteName}
            </Button>
        </Box>
    </Box>
)

const SubscriptionCancelForm = ({ siteName, onSubmit, onClose }) => {
    const classes = useStyles()
    const { user } = useAuth()
    const { supportEmail } = useSupport()
    let mailto = supportEmail ? `mailto:${supportEmail}` : "mailto:contact@yogarenewteachertraining.com"

    return (
        <Container className={classes.container}>
            <FormikWizard
                initialValues={{ cancel_reason: '', feedback: '', checkbox1: false, checkbox2: false, siteName: siteName }}
                onSubmit={async values => {
                    await cancelSubscription(user, false, values);
                    onSubmit()
                }}
                validateOnNext
                activeStepIndex={0}
                steps={[
                    { component: CancelSurvey },
                    {
                        component: Confirmation,
                        validationSchema: Yup.object().shape({
                            checkbox1: Yup.boolean().required("required"),
                        })
                    },
                    { component: FinalConfirmation }
                ]}
            >
                {
                    ({ renderComponent, handleSubmit, handleNext, values, currentStepIndex, isSubmitting }) => (
                        <>
                            {renderComponent()}
                            <Grid container spacing={2}>
                                {currentStepIndex === 2
                                    ? null
                                    : (<>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <Button fullWidth variant="contained" disabled={isSubmitting} href={mailto}>
                                                <Typography variant="subtitle2">
                                                    Contact Support
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <LoadingButton
                                                fullWidth
                                                variant="outlined"
                                                color="error"
                                                onClick={() => currentStepIndex === 1 ? handleSubmit() : handleNext()}
                                                disabled={!values.checkbox1 && currentStepIndex === 1}
                                                loading={isSubmitting}
                                            >
                                                <Typography variant="subtitle2">
                                                    Cancel Subscription
                                                </Typography>
                                            </LoadingButton>
                                        </Grid>
                                    </>)}
                            </Grid>
                        </>
                    )
                }
            </FormikWizard>
        </Container>
    )
}

export default SubscriptionCancelForm