import { Card, CardContent, Typography, Box, Button } from "@material-ui/core"
import { PublisherSiteLogo } from "./components/publisher-site"
import ArrowBack from "@material-ui/icons/ArrowBack";
import Home from "@material-ui/icons/Home";
import useSupport from "./hooks/useSupport";

export const ErrorFallback = () => {
    const { supportEmail } = useSupport()
    let mailto = supportEmail ? `mailto:${supportEmail}` : "mailto:contact@yogarenewteachertraining.com"

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Card sx={{ width: { xs: '95%', sm: '75%', md: '40%' }, mt: 5 }}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', mt: 3, mb: 3, alignItems: 'center' }}>
                    <a href="/" style={{ marginBottom: '2rem' }}>
                        <PublisherSiteLogo maxWidth="400px" maxHeight="70px" />
                    </a>
                    <Typography variant="h4" sx={{ mb: 4 }}>
                        An Error Has Occurred :(
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
                        If the error persists, please report it by sending an email to <a href={mailto}>contact@yogarenewteachertraining.com</a>
                    </Typography>
                    <Box>
                        <Button
                            variant="contained"
                            sx={{ mr: 1 }}
                            startIcon={<ArrowBack />}
                            onClick={() => window.location.reload()}
                        >
                            Try Again
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ ml: 1 }}
                            startIcon={<Home />}
                            onClick={() => window.location.href = '/'}
                        >
                            Go Home
                        </Button>
                    </Box>

                </CardContent>
            </Card>
        </Box>
    )
}