import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { Box, Button, Grid, IconButton, List, ListItem, Tooltip, Typography } from '@material-ui/core'
import XIcon from '../icons/X'
import AddPhotoAlternateOutlined from '@material-ui/icons/AddPhotoAlternateOutlined'

const InstructorPhotoUpload = (props) => {
  const {
    accept,
    buttonText,
    buttonVariant,
    buttonSize,
    disabled,
    file,
    fileLabel,
    fileType,
    getFilesFromEvent,
    hasIcon,
    maxFiles,
    maxSize,
    minSize,
    noClick,
    noDrag,
    noDragEventsBubbling,
    noKeyboard,
    onDrop,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    onRemove,
    onRemoveAll,
    onUpload,
    preventDropOnDocument,
    ...other
  } = props

  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    minSize,
    onDrop,
    buttonText,
    fileType,
  })


  return (
    <div {...other}>
      {!file && (
        <Box
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            outline: 'none',
            maxWidth: 450,
            border: '1px solid #c2c2c2',
            p: 4,
            mb: 2,
            ...(isDragActive && {
              backgroundColor: 'action.active',
              opacity: 0.5,
            }),
            '&:hover': {
              backgroundColor: 'action.hover',
              cursor: 'pointer',
              opacity: 0.5,
            },
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Box>
            <Grid container justifyContent="center">
              <Grid item>
                <Button color="button" size={buttonSize} type="button" variant={buttonVariant} sx={{}}>
                  <AddPhotoAlternateOutlined size="small" color="primary" sx={{ mr: 1 }} />
                  <Typography variant="subtitle2" color={buttonVariant === 'contained' ? 'inherit' : 'primary'}>
                    {buttonText}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      {file && (
        <Box sx={{ mb: 1, maxWidth: 450 }}>
          <List>
            <ListItem
              key={file?.path}
              sx={{
                border: 1,
                borderColor: 'divider',
                borderRadius: 1,
                '& + &': {
                  // mt: 1,
                },
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <img src={file?.url} alt="" style={{ width: 260, height: 'auto', objectFit: 'contain' }} />
              <Tooltip title="Remove">
                <IconButton edge="end" onClick={() => onRemove && onRemove(file)}>
                  <XIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </ListItem>
          </List>
          {/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              // mt: 1,
            }}
          >
            <Button color="primary" onClick={() => onRemove && onRemove(file)} size="small" type="button" variant="text">
              Remove
            </Button>
            <Button color="primary" onClick={onUpload} size="small" sx={{ ml: 2 }} type="button" variant="contained">
              Upload
            </Button>
          </Box> */}
        </Box>
      )}
    </div>
  )
}

InstructorPhotoUpload.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  buttonText: PropTypes.string,
  buttonVariant: PropTypes.func,
  disabled: PropTypes.bool,
  files: PropTypes.array,
  getFilesFromEvent: PropTypes.func,
  hasIcon: PropTypes.bool,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  preventDropOnDocument: PropTypes.bool,
}

InstructorPhotoUpload.defaultProps = {
  files: [],
}

export default InstructorPhotoUpload
