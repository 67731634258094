import { combineReducers } from '@reduxjs/toolkit';
import { reducer as channelReducer } from '../slices/channel';
import { reducer as curriculumReducer } from '../slices/curriculum';
import { reducer as instructorReducer } from '../slices/instructor';

const rootReducer = combineReducers({
  channel: channelReducer,
  curriculum: curriculumReducer,
  instructor: instructorReducer,

});

export default rootReducer;
