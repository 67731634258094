import React, { useState } from 'react'
import { Box, Paper, Dialog, Button, Typography } from '@material-ui/core'
import { PublisherSiteLogo } from './publisher-site'

const NotSubscribedModal = ({ setOpen, isOpen }) => {
    const [showDetail, setShowDetail] = useState(false)

    const close = () => setOpen(false)

	return (
		<Dialog open={isOpen} onClose={close}>
			<Paper elevation={12} sx={{ position: 'relative', px: 5, py: 5 }}>
                <Box sx={{display: 'flex', justifyContent: 'center', pb: 6}}>
                    <PublisherSiteLogo maxWidth="75%" />
                </Box>
                <Box sx={{pb: 5}}>
                    <Typography color="textPrimary" variant="body1" align="center">
                        Hi There! You have created an account but it seems you haven’t finished setting up your subscription!
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between', px: 2}}>
                    <Button variant="outlined" fullWidth sx={{mx: 3}} href="mailto:support@yogarenewteachertraining.com">
                        Contact Us
                    </Button>
                    <Button variant="contained" fullWidth sx={{mx: 3}} href="/yogarenew/subscriptions">
                        Finish Subscription
                    </Button>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', mt: 5}}>
                    <Button onClick={() => setShowDetail(!showDetail)}>
                        Why am I getting this message?
                    </Button>
                </Box>
                <Box sx={{display: showDetail ? 'flex' : 'none', pt: 3}}>
                    <Typography color="textPrimary" variant="subtitle1" align="center">
                        While you have created an account, you haven’t finished the subscription process. 
                        To finish subscribing to view our videos, click finish subscription complete the subscription process.
                        <br /><br />
                        If you are still having issues, please contact <a href="mailto:support@yogarenewteachertraining.com">support@yogarenewteachertraining.com</a>
                    </Typography>
                </Box>
			</Paper>
		</Dialog>
	)
}

export default NotSubscribedModal
