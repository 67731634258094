import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
import Logo2 from '../Logo2';
import NotificationsPopover from './NotificationsPopover';

const DashboardNavbarRoot = experimentalStyled(AppBar)(
	({ theme }) => ({
		...(theme.palette.mode === 'light' && {
			backgroundColor: theme.palette.background.default,
			borderBottom: `1px solid ${theme.palette.divider}`,
			boxShadow: '1px',
			color: theme.palette.background.contrastText,
		}),
		...(theme.palette.mode === 'dark' && {
			backgroundColor: theme.palette.background.paper,
			borderBottom: `1px solid ${theme.palette.divider}`,
			boxShadow: 'none',
		}),
		zIndex: theme.zIndex.drawer + 100,
	}),
);

const DashboardNavbar = (props) => {
	const { onSidebarMobileOpen, ...other } = props;

	return (
		<DashboardNavbarRoot {...other}>
			<Toolbar sx={{ minHeight: 64 }}>
				<IconButton
					color='inherit'
					onClick={onSidebarMobileOpen}
					sx={{ display: { lg: 'none' } }} >
					<MenuIcon fontSize='small' />
				</IconButton>
				<RouterLink to='/'>
					<Logo2
						sx={{
							display: {
								lg: 'inline',
								xs: 'none',
							},
							height: 45,
							width: 45,
						}}
					/>
				</RouterLink>
				<Box sx={{ flexGrow: 1, ml: 2 }} />

				<Box sx={{ ml: 1 }}>
					<NotificationsPopover />
				</Box>
				<Box sx={{ ml: 2 }}>
					<AccountPopover />
				</Box>
			</Toolbar>
		</DashboardNavbarRoot>
	);
};

DashboardNavbar.propTypes = {
	onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
