import { Box, Grid, Typography, makeStyles } from '@material-ui/core'
import Image from '../../../../components/Image'

const useStyles = makeStyles(() => ({
    header: {
        textTransform: 'uppercase',
        marginTop: '5rem',
        textAlign: 'left',
    },
    subHeader: {
        marginTop: '1rem',
        textAlign: 'left',
    },
    button: {
        textTransform: 'uppercase',
        padding: '0.5rem 1.5rem',
        marginTop: '2rem',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        marginBottom: '1rem',
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: '100%'
    },
    imageContainer: {
        position: 'relative',
        width: '100%',
        height: '0',
        paddingTop: '56.25%',
        overflow: 'hidden',
        marginBottom: '1rem',
        paddingRight: '1rem',
        marginRight: '1rem',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        left: 0,
    },
}));

export const ClassesTab = ({ videos, navigate }) => {
    const classes = useStyles()

    const getDuration = (duration) => {
        let minutes = Math.floor(duration / 60);
        if (minutes < 1) {
            minutes = 1;
        }
        const durationinMin = `${minutes}`;
        return durationinMin;
    }

    return (
        <Box sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            px: 10,
            '@media (max-width: 960px)': {
                px: 2,
            }
        }}>
            <Grid container spacing={3} sx={{ py: 8, px: 5 }}>
                {videos && videos.length > 0 ?
                    videos.map((video, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer'
                                }
                            }}
                            onClick={() => navigate(`/yogarenew/videos/${video._id}`)}>
                            <Box className={classes.imageContainer}>
                                <Image formats={video.thumbnail?.formats} alt={video.title} className={classes.image} />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        position: 'absolute',
                                        bottom: '0.5rem',
                                        right: '1rem',
                                        color: 'white',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        padding: '0.2rem 0.5rem',
                                        borderRadius: '4px',
                                    }}
                                >
                                    {getDuration(video?.duration) + ' Min'}
                                </Typography>
                            </Box>
                            <Box className={classes.textContainer}>
                                <Typography noWrap variant="h6">
                                    {video.title}
                                </Typography>
                            </Box>
                        </Grid>
                    ))
                    :
                    <Typography variant="h6">No videos found for this instructor.</Typography>
                }
            </Grid>
        </Box>
    )
}
