export const THEMES = {
	LIGHT: 'LIGHT',
	DARK: 'DARK',
	NATURE: 'NATURE',
}

export const NODE_URL = process.env.REACT_APP_STRAPI_URL
export const PAYMENTS_URL = process.env.REACT_APP_PAYMENTS_URL
export const STRIPE_PUBLISHER_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
export const APP_VERSION = process.env.REACT_APP_VERSION || '0.0.1'
export const RELEASE_STAGE = process.env.REACT_APP_RELEASE_STAGE || 'development'