import React from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { Box, Button, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@material-ui/core'
import DuplicateIcon from '../icons/Duplicate'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import XIcon from '../icons/X'
import AddPhotoAlternateOutlined from '@material-ui/icons/AddPhotoAlternateOutlined'
import bytesToSize from '../utils/bytesToSize'

const FileUpload = (props) => {
	const {
		accept,
		disabled,
		files,
		fileLabel,
		fileType,
		getFilesFromEvent,
		maxFiles,
		maxSize,
		minSize,
		noClick,
		noDrag,
		noDragEventsBubbling,
		noKeyboard,
		onDrop,
		onDropAccepted,
		onDropRejected,
		onFileDialogCancel,
		onRemove,
		onRemoveAll,
		onUpload,
		preventDropOnDocument,
		...other
	} = props

	// We did not add the remaining props to avoid component complexity
	// but you can simply add it if you need to.
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept,
		maxFiles,
		maxSize,
		minSize,
		onDrop,
		fileType,
	})

	return (
		<div {...other}>
			<Box
				sx={{
					alignItems: 'center',
					border: 1,
					borderRadius: 1,
					borderColor: 'divider',
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'center',
					outline: 'none',
					p: 2,
					...(isDragActive && {
						backgroundColor: 'action.active',
						opacity: 0.5,
					}),
					'&:hover': {
						backgroundColor: 'action.hover',
						cursor: 'pointer',
						opacity: 0.5,
					},
				}}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				<Grid container justifyContent="center">
					<Grid item>
						{fileType === 'image' ? (
							<AddPhotoAlternateOutlined sx={{ fontSize: '40px' }} />
						) : (
							<CloudUploadOutlinedIcon sx={{ fontSize: '100px' }} />
						)}
					</Grid>
				</Grid>
				<Box sx={{ p: 1 }}>
					{fileType === 'image' ? (
						<Typography color="textPrimary" variant="body2" textAlign="center">
							{`Drag and drop your ${fileLabel} here or click to browse`}
						</Typography>
					) : (
						<Typography color="textPrimary" variant="body1">
							{`Drag and drop your ${fileLabel} here or click to browse`}{' '}
						</Typography>
					)}
					{/* <Link color='primary' underline='always'>
							browse
            </Link> */}
					<Grid container justifyContent="center" sx={{ mt: 2 }}>
						<Grid item>
							<Button color="button" size="small" type="button" variant="contained">
								Choose File
							</Button>{' '}
						</Grid>
					</Grid>
				</Box>
			</Box>
			{/* </Box> */}
			{files.length > 0 && (
				<Box sx={{ mt: 2 }}>
					<List>
						{files.map((file) => (
							<ListItem
								key={file.path}
								sx={{
									border: 1,
									borderColor: 'divider',
									borderRadius: 1,
									'& + &': {
										mt: 1,
									},
								}}
							>
								<ListItemIcon>
									<DuplicateIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText
									primary={file.name}
									primaryTypographyProps={{
										color: 'textPrimary',
										variant: 'subtitle2',
									}}
									secondary={bytesToSize(file.size)}
								/>
								<Tooltip title="Remove">
									<IconButton edge="end" onClick={() => onRemove && onRemove(file)}>
										<XIcon fontSize="small" />
									</IconButton>
								</Tooltip>
							</ListItem>
						))}
					</List>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							mt: 2,
						}}
					>
						<Button color="primary" onClick={onRemoveAll} size="small" type="button" variant="text">
							Remove All
						</Button>
						<Button color="primary" onClick={onUpload} size="small" sx={{ ml: 2 }} type="button" variant="contained">
							Upload
						</Button>
					</Box>
				</Box>
			)}
		</div>
	)
}

FileUpload.propTypes = {
	accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	disabled: PropTypes.bool,
	files: PropTypes.array,
	getFilesFromEvent: PropTypes.func,
	maxFiles: PropTypes.number,
	maxSize: PropTypes.number,
	minSize: PropTypes.number,
	noClick: PropTypes.bool,
	noDrag: PropTypes.bool,
	noDragEventsBubbling: PropTypes.bool,
	noKeyboard: PropTypes.bool,
	onDrop: PropTypes.func,
	onDropAccepted: PropTypes.func,
	onDropRejected: PropTypes.func,
	onFileDialogCancel: PropTypes.func,
	onRemove: PropTypes.func,
	onRemoveAll: PropTypes.func,
	onUpload: PropTypes.func,
	preventDropOnDocument: PropTypes.bool,
}

FileUpload.defaultProps = {
	files: [],
}

export default FileUpload
