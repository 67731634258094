import { useState, useEffect, useRef } from 'react'
import { Box, Button, Card, CardContent, Grid, Stepper, Step, StepLabel, Typography } from '@material-ui/core'
import useAuth from '../../../../../hooks/useAuth'
import VideoSelectionForm from './VideoSelectionForm'
import VideoUploadingForm from './VideoUploadingForm'
import VideoDetailsForm from './VideoDetailsForm'
import { fetchVideo, updateVideo, uploadVideo } from '../../../../../actions/videoActions'
import { fetchChannels } from '../../../../../actions/channelActions'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'

const grayButtonStyle = {
	px: 4,
	ml: 2,
	backgroundColor: 'background.gray',
	color: 'background.gray.contrastText',
	'&:hover': {
		backgroundColor: '#404EE4',
	},
}

/**
 * @param {{ defaultChannelId: string }} props
 * @returns 
 */
const UploadVideoView = ({ defaultChannelId }) => {
	const { user } = useAuth();
	const navigate = useNavigate();
	/**
	 * @type {[File, (file: File) => void]}
	 */
	const [videoFile, setVideoFile] = useState();
	const [videoData, setVideoData] = useState(null);
	const [step, setStep] = useState(0);
	const [channels, setChannels] = useState([]);
	const [uploadProgress, setUploadProgress] = useState(0);
	const formikRef = useRef();

	async function getChannels() {
		try {
			const response = await fetchChannels(user.id);
			setChannels(response);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => getChannels(), [])

	useEffect(() => {
		const handleVideoUpload = async () => {
			if (!videoFile) {
				setStep(0);
				return;
			}

			if (videoFile && step === 0) {
				try {
					setStep(1);
					const video = await uploadVideo(
						videoFile, 
						defaultChannelId, 
						null, 
						(p) => setUploadProgress(p), 
						(e) => toast.error(e)
					);
					setVideoData(video);
				} catch (err) {
					console.error("Error uploading video:", err);
					toast.error("Error occured while uploading video. Please try again. If the error persists, contact support.");
				}
			}
		};

		handleVideoUpload();
	}, [videoFile]);

	useEffect(() => {
		if (uploadProgress === 100 && videoData) {
			setStep(2);
		}
	}, [uploadProgress, videoData])

	useEffect(() => {
		console.debug('videoData', videoData)
		if (videoData && videoData.status !== 'complete') {
			setTimeout(() => getVideoData(videoData._id), 5000);
		}
	}, [videoData])

	const getVideoData = async (videoId) => {
		const video = await fetchVideo(videoId);
		if (!video) {
			toast.error("Error fetching updated video. Page will not update once video is finished processing.", { role: 'alert', duration: 30000 });
			console.error("Error fetching video");
			return;
		}

		setVideoData(video)
	}

	const handlePrevStep = () => {
		setStep(step - 1)
	};

	const handleCancelClick = () => {
		navigate('/dashboard/channels')
	};

	async function publishVideo() {
		if (!formikRef.current) {
			console.error("Formik ref not set");
			toast.error("Error publishing video. Please try again later. (1)", { role: 'alert' });
			return;
		}

		if (!formikRef.current.isValid) {
			toast.error("Please fill out all required fields.", { role: 'alert' });
			return;
		}

		const values = formikRef.current.values;
		try {
			await updateVideo(videoData._id, values);
			navigate('/dashboard/channels');
		} catch (error) {
			console.error('error publishing video', error);
			toast.error('Failed to publish video');
			return;
		}
		
	}

	async function handleSaveVideo() {
		if (!formikRef.current) {
			console.error("Formik ref not set");
			toast.error("Error publishing video. Please try again later. (1)", { role: 'alert' });
			return;
		}

		if (!formikRef.current.isValid) {
			toast.error("Please fill out all required fields.", { role: 'alert' });
			return;
		}

		const values = formikRef.current.values;
		delete values.published_at;
		try {
			await updateVideo(videoData._id, values);
			toast.success('Video saved successfully');
		} catch (error) {
			console.error('error saving video', error);
			toast.error('Failed to save video');
			return;
		}
	}

	const stepLabels = [
		'Select Video',
		'Video Uploading',
		'Add Video Details'
	];

	const stepContent = [
		<VideoSelectionForm onVideoSelect={setVideoFile} />,
		<VideoUploadingForm uploadProgress={uploadProgress} />,
		<VideoDetailsForm
			videoData={videoData}
			channels={channels}
			defaultChannelId={defaultChannelId}
			ref={formikRef}
		/>
	];

	const isLastStep = step === stepLabels.length - 1;

	return <Grid container>
		<Grid item md={3} xs={12} sx={{ mb: 4, mt: 2 }}>
			<Typography color="textPrimary" variant="h5">
				Upload Video
			</Typography>
		</Grid>
		<Grid item md={9} xs={12} sx={{ mb: 4, mt: 2.75 }}>
			<Stepper activeStep={step}>
				{stepLabels.map(label => <Step key={label}>
					<StepLabel>{label}</StepLabel>
				</Step>)}
			</Stepper>
		</Grid>
		<Grid item xs={12} sx={{ mt: 2 }}>
			<Card>
				<CardContent sx={{ mt: 4 }}>
					<>
						{stepContent[step]}
						<Box sx={{ mt: 4 }}>
							{step > 0 &&
								<Button
									color="button"
									sx={{ mr: 2, px: 4 }}
									variant="outlined"
									onClick={handlePrevStep}
								>
									BACK
								</Button>
							}
							<Button
								sx={grayButtonStyle}
								variant="contained"
								type="submit"
								onClick={handleCancelClick}
							>
								CANCEL
							</Button>
							{ isLastStep ? <Button sx={grayButtonStyle} variant="contained" onClick={handleSaveVideo}>SAVE</Button> : null }
							<Button
								sx={grayButtonStyle}
								variant="contained"
								type="submit"
								disabled={videoData && videoData.status !== 'complete'}
								onClick={isLastStep ? publishVideo : () => { }}
							>
								{isLastStep ? 'PUBLISH' : 'CONTINUE'}
							</Button>
						</Box>
					</>
				</CardContent>
			</Card>
		</Grid>
	</Grid>
}

export default UploadVideoView
