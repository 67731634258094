import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled, useTheme } from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import DashboardMiniSidebar from './DashboardMiniSidebar';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const [isMiniBar, setIsMiniBar] = useState(localStorage.getItem('isMiniBar') === 'true');
  const theme = useTheme()

  const handleCloseSidebar = () => {
    setIsMiniBar(true)
    localStorage.setItem('isMiniBar', true)
  }

  const handleOpenSidebar = () => {
    setIsMiniBar(false)
    localStorage.setItem('isMiniBar', false)
  }

  useEffect(() => {
    if (isSidebarMobileOpen) {
      setIsMiniBar(true)
      localStorage.setItem('isMiniBar', true)
    } else {
      setIsMiniBar(false)
      localStorage.setItem('isMiniBar', false)
    }
  }, [isSidebarMobileOpen])

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(!isSidebarMobileOpen)} />
      {isMiniBar
        ? <DashboardMiniSidebar
          onOpenSidebar={handleOpenSidebar}
          onOpen={handleCloseSidebar}
          openMobile={isSidebarMobileOpen}
        />
        : <DashboardSidebar
          onCloseSidebar={handleCloseSidebar}
          openMobile={isSidebarMobileOpen}
        />
      }
      <DashboardLayoutWrapper sx={{
        [theme.breakpoints.up('sm')]: {
          paddingLeft: isMiniBar ? '74px' : '259px'
        }
      }}>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
