import React from 'react'
import { Box } from '@material-ui/core'
import FileUpload from '../../../../../components/FileUpload'

/**
 * @typedef {Object} VideoSelectionFormProps
 * @property {(video: File) => void} onVideoSelect
 */

const VideoSelectionForm = ({ onVideoSelect }) => {
	const handleDropVideo = files => onVideoSelect(files[0]);

	return (
		<Box
			sx={{
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				px: 5,
			}}
		>
			<FileUpload 
				accept="video/mp4,video/quicktime" 
				maxFiles={1} 
				onDrop={handleDropVideo} 
				fileLabel="video" 
			/>
		</Box>
	)
}

export default VideoSelectionForm
