import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Grid, Typography, makeStyles, CircularProgress, Button } from '@material-ui/core'
import { fetchWorkshop } from '../../../../actions/workshopActions'
import useAuth from '../../../../hooks/useAuth'
import toast from 'react-hot-toast'
import { fetchJoinUrl, registerZoomMeeting } from '../../../../actions/workshopActions'
import { fetchInstructors } from '../../../../actions/instructorsApi'

const useStyles = makeStyles(() => ({
    page: {
        backgroundColor: 'background.paper',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        textTransform: 'uppercase',
        fontWeight: '700',
    },
    subHeader: {
        marginBottom: '1rem',
        textAlign: 'center',
    },
    date: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: '600'
    },
    image: {
        width: '100%',
        height: '50vh',
        objectFit: 'cover',
        marginBottom: '2rem',
    },
    description: {
        marginTop: '2rem',
    },
    button: {
        textTransform: 'uppercase',
        padding: '0.5rem 1.5rem',
        marginTop: '2rem',
    },
    teacherImageContainer: {
        width: '400px',
        height: '400px',
        overflow: 'hidden',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        '&::before': {
            content: '""',
            display: 'block',
            paddingTop: '100%'
        },
        '@media (max-width: 600px)': {
            width: '300px',
            height: '300px',
        },
    },
    teacherImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        display: 'block'
    },
    teacherName: {
        textTransform: 'uppercase',
        marginBottom: '1rem',
    },
    teacherBio: {
        marginBottom: '2rem',
    },
    teacherButton: {
        textTransform: 'uppercase',
        padding: '0.5rem 2rem',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.2)',
        zIndex: 1,
    },
}))

const WorkshopDetailView = () => {
    const { user } = useAuth()
    const classes = useStyles()
    const { workshopId } = useParams()
    const [workshop, setWorkshop] = useState(null)
    const [loading, setLoading] = useState(false)
    const date = new Date();
    const dateAsString = date.toString();
    const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
    const [registered, setRegistered] = useState(false)
    const [joinLink, setJoinLink] = useState('')
    const [instructor, setInstructor] = useState(null)

    const getWorkshop = async () => {
        setLoading(true)
        try {
            const response = await fetchWorkshop(workshopId)
            if (response) {
                setWorkshop(response)
            }
            setLoading(false)
        } catch (error) {
            console.error(error)
        }
    }

    const getInstructors = async () => {
        try {
            const response = await fetchInstructors()
            if (response) {
                const instructor = response.find(instructor => instructor.name.toLowerCase().trim() === workshop.host.toLowerCase().trim())
                if (instructor) {
                    setInstructor(instructor)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleRegister = async () => {
        try {
            const toastId = toast.loading('Registering for workshop...')
            const response = await registerZoomMeeting(workshop.meetingId, user.firstName, user.lastName, user.email)

            if (response.join_url) {
                toast.dismiss(toastId)
                console.log(response, 'register zoom meeting response')
                setRegistered(true)
                setJoinLink(response.join_url)
                toast.success(`You are registered! Please check your email for a confirmation, we can't wait to see you!`, { duration: 4000 })
            } else {
                toast.error('Failed to register for workshop')
            }
        } catch (error) {
            console.error(error, 'register zoom meeting error')
            toast.error('Failed to register for workshop')
        }
    }

    const fetchJoinLink = async () => {
        const response = await fetchJoinUrl(workshop.meetingId)
        if (response.join_url) {
            setJoinLink(response.join_url)
        }
    }

    useEffect(() => {
        if (!workshop || !user) return
        if (workshop.attendees) {
            workshop.attendees.forEach(attendee => {
                if (attendee === user.id) {
                    setRegistered(true)
                }
            })
        }
        getInstructors()
    }, [workshop, user])

    useEffect(() => {
        if (registered) {
            fetchJoinLink()
        }
    }, [registered])

    useEffect(() => {
        if (!workshopId) return
        getWorkshop()
    }, [workshopId])

    console.log('workshop', workshop)

    return (
        loading ? <CircularProgress /> :
            workshop &&
            <Box className={classes.page}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },  // Column on xs to md, row on md and above
                    alignItems: 'center',
                    width: '100%',
                }}>
                    <Box sx={{
                        position: 'relative',
                        aspectRatio: '1',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundImage: `url(${workshop.cover.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '100%',
                    }}>
                        <Box className={classes.overlay} />
                    </Box>
                    <Box
                        sx={{
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            pt: instructor ? 5 : 10,
                            '@media (max-width: 960px)': {
                                px: 2,
                            }
                        }}>
                            <Typography variant="h3" className={classes.header}>{workshop.title}</Typography>
                            <Typography variant="h6" className={classes.subHeader}>with {workshop.host}</Typography>
                            <Typography variant="h6" className={classes.date}>
                                {new Date(workshop.date).toLocaleDateString('default', { month: 'long', day: '2-digit', year: 'numeric' })} at {new Date(workshop.date).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })} {timezone.match(/\b(\w)/g).join('')}
                            </Typography>
                            <Typography variant="h6" className={classes.subHeader}>
                                {workshop.duration} minutes
                            </Typography>
                            {registered &&
                                <Typography variant="h6" className={classes.subHeader} style={{ color: 'red', textTransform: 'uppercase' }}>
                                    Registered
                                </Typography>
                            }
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            px: 10,
                            pb: 10,
                            '@media (max-width: 960px)': {
                                px: 5,
                                pb: 5,
                            },
                        }}>
                            <Typography variant="body1" className={classes.description}>{workshop.information}</Typography>
                            {!registered ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mx: 'auto', mt: 3 }}
                                    onClick={handleRegister}
                                    className={classes.button}
                                >
                                    Register</Button>
                                :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mx: 'auto', mt: 3 }}
                                    href={joinLink}
                                    target="_blank"
                                    className={classes.button}
                                >
                                    Join</Button>
                            }
                        </Box>
                    </Box>
                </Box>
                {instructor &&
                    <Box sx={{
                        width: '100%',
                        px: 10,
                        pt: 10,
                        pb: 15,
                        '@media (max-width: 960px)': {
                            px: 5,
                            py: 5,
                        },
                        backgroundColor: '#F0F0F0'
                    }}>
                        <Grid container sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Grid item sm={12} md={6} sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <Box className={classes.teacherImageContainer}>
                                    <img
                                        src={instructor?.picture && instructor.picture.url}
                                        alt={instructor.name}
                                        className={classes.teacherImage}
                                    />
                                </Box>

                            </Grid>
                            <Grid item sm={12} md={6} sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                height: '100%',
                                '@media (max-width: 960px)': {
                                    px: 5,
                                    py: 5,
                                },
                                '@media (max-width: 600px)': {
                                    px: 2,
                                    pb: 20,
                                },
                            }}>
                                <Typography variant="h4" className={classes.header}>About the instructor</Typography>
                                <Typography variant="h5" className={classes.teacherName}>{instructor.name}</Typography>
                                <Typography variant="body1">{instructor.bio}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                }

            </Box>

    )
}

export default WorkshopDetailView