const Image = ({ formats, alt, ...props }) => {

    if (!formats) return null

    let size = 'medium'

    if (window.innerWidth < 600) {
        size = 'thumbnail'
    } else if (window.innerWidth < 960) {
        size = 'small'
    } else if (window.innerWidth >= 1280) {
        size = 'large'
    }

    let url = ''

    switch (size) {
        case 'thumbnail':
            url = formats.thumbnail?.url
            if (url) break;
        case 'small':
            url = formats.small?.url
            if (url) break;
        case 'medium':
            url = formats.medium?.url
            if (url) break;
        case 'large':
            url = formats.large?.url
            if (url) break;
        default:
            url = formats.thumbnail?.url
            break;
    }

    return (
        <img src={url} alt={alt || ""} {...props} />
    )
}

export default Image