export const getLargestImage = (object, maxSize) => {
	if (typeof object !== 'object' || !object.hasOwnProperty('formats')) {
		console.warn('getLargestImage expects an object with a formats property.')
		return;
	}

	const { formats } = object
	const formatNames = ['thumbnail', 'small', 'medium', 'large']
	let formatIndex = formatNames.indexOf(maxSize)
	if (formatIndex === -1) {
		return console.error("getLargestImage expects a valid size: 'thumbnail', 'small', 'medium', 'large'");
	}

	while (formats[formatNames[formatIndex]] === undefined) {
		formatIndex--
	}

	return formats[formatNames[formatIndex]].url
}
