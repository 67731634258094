import { useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Box, Container } from '@material-ui/core'
import UploadVideoView from './Form/index'
import useSettings from '../../../../hooks/useSettings'

const UploadVideo = () => {
	const { settings } = useSettings()
	const [searchParams] = useSearchParams();

	//TODO: Make sure channel belongs to logged in publisher
	const initialChannel = searchParams.get("c")

	return (
		<>
			<Helmet>
				<title>Dashboard: Upload Video</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: 'background.default',
					minHeight: '100%',
					p: 2,
					mt: 2,
					mb: 6,
				}}
			>
				<Container maxWidth={settings.compact ? 'xl' : false}>
					<Box sx={{ mt: 3, ml: 4 }}>
						{initialChannel ? <UploadVideoView defaultChannelId={initialChannel} /> : "No Channel ID Provided"}
					</Box>
				</Container>
			</Box>
		</>
	)
}

export default UploadVideo
