import { Grid, Typography } from '@material-ui/core'
import styled from '@emotion/styled'

const Div = styled.div`
	position: relative;
	height: 500px;
	background-position: top right;
	background-repeat: no-repeat;
	${
		'' /* background-image: linear-gradient(90deg, rgba(151, 235, 228, 1) 0.5%, rgba(191, 234, 234, 1) 11%, rgba(236, 239, 242, 1) 88%); */
	}
	overflow: hidden;
`

const PublisherSiteMarketing1 = (props) => {
	const { marketingHeader, marketingText, marketingImage } = props

	return (
		<Div>
			<Grid container spacing={2} sx={{ mt: 5 }}>
				<Grid item sm={12} md={7} lg={7} sx={{ py: 10, zIndex: 99 }}>
					<Grid item sx={{ pl: 15, pr: 8, pt: 15 }}>
						<Typography
							sx={{
								color: 'primary.text',
								textAlign: {
									md: 'left',
									xs: 'left',
								},
								fontSize: '22pt',
								fontWeight: '500',
								lineHeight: '1',
								textTransform: 'uppercase',
							}}
						>
							{marketingHeader}
						</Typography>
					</Grid>
					<Grid item sx={{ pl: 15, pr: 8, pt: 5 }}>
						<Typography sx={{ color: 'primary.text', zIndex: 20 }} variant="h6">
							{marketingText}
						</Typography>
					</Grid>
				</Grid>
				<Grid item sm={12} md={5} lg={5} sx={{ py: 10, mt: 5 }}>
					<Grid item sx={{ pl: 10, pt: 15 }}>
						<img
							alt="Hero"
							src={marketingImage}
							style={{
								maxWidth: '120%',
								zIndex: 1,
								position: 'absolute',
								bottom: 0,
								right: 0,
								top: 30,
							}}
							height="100%"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Div>
	)
}

export default PublisherSiteMarketing1
