import React from 'react'

const PublisherSiteLogo = (props) => {
	const { width, maxWidth, maxHeight, minHeight } = props

	return <img alt="Logo" src="https://peliuploads.s3.us-east-2.amazonaws.com/yogarenew_logo_120321_lockup1_f8e8c62fde.png" style={{
		width: width, maxWidth: maxWidth, maxHeight: maxHeight, minHeight: minHeight
	}} />
}

export default PublisherSiteLogo
