import * as qs from 'qs'
const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => {
  const accessToken = localStorage.getItem('accessToken')
  const headers = {
    'Content-Type': 'application/json',
    'x-analytics-source': 'web'
  }
  if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

  return headers
}

// *********** CREATE INSTRUCTOR *********** //
export const createInstructor = async (details) => {
  const opts = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(details),
  }

  const response = await fetch(`${nodeURL}/course-instructors`, opts).then((data) => data.json())

  return response
}


// *********** FETCH ALL INSTRUCTORS *********** //
export const fetchInstructors = async (params) => {
  const opts = {
    method: 'GET',
    headers: headers(),
  }

  const query = qs.stringify(params, { addQueryPrefix: true })

  const response = await fetch(`${nodeURL}/course-instructors${query}`, opts).then((data) => data.json())

  return response
}

// *********** FETCH A SINGLE INSTRUCTOR (BY ID) *********** //
export const fetchInstructor = async (id) => {
  const opts = {
    method: 'GET',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/course-instructors/${id}`, opts).then((data) => data.json())

  return response
}

// *********** UPDATE AN INSTRUCTOR (BY ID) *********** //
export const updateInstructor = async (id, details) => {
  const opts = {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(details),
  }

  const response = await fetch(`${nodeURL}/course-instructors/${id}`, opts).then((data) => data.json())

  return response
}

// *********** DELETE AN INSTRUCTOR (BY ID) *********** //
export const deleteInstructor = async (id) => {
  const opts = {
    method: 'DELETE',
    headers: headers(),
  }

  const response = await fetch(`${nodeURL}/course-instructors/${id}`, opts).then((data) => data.json())

  return response
}
