import { useState } from 'react'
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Dialog, Typography, Box } from '@material-ui/core'
import toast from 'react-hot-toast'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { WorkshopUpdateModal } from './WorkshopUpdateModal'
import { deleteWorkshop } from '../../../actions/workshopActions'
import { useNavigate } from 'react-router-dom'
export const WorkshopTable = ({ workshops, setWorkshops, past }) => {
    const [openUpdateModal, setOpenUpdateModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [selectedWorkshop, setSelectedWorkshop] = useState(null)
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate()

    const collapsedStyle = {
        display: '-webkit-box',
        WebkitLineClamp: 5,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '100px',
    };

    const expandedStyle = {
        display: 'block',
    };

    const handleDelete = async () => {
        const response = await deleteWorkshop(selectedWorkshop.id)
        if (response.success) {
            toast.success('Workshop deleted successfully!')
            const updatedWorkshops = workshops.filter(workshop => workshop.id !== selectedWorkshop.id)
            setWorkshops(updatedWorkshops)
            setOpenDeleteModal(false)
        } else {
            toast.error('Failed to delete workshop')
            setOpenDeleteModal(false)
        }
    }

    return (
        <Paper>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            Cover
                        </TableCell>
                        <TableCell align="center">
                            Name
                        </TableCell>
                        <TableCell align="center">
                            Information
                        </TableCell>
                        <TableCell align="center">
                            Date & Time
                        </TableCell>
                        <TableCell align="center">
                            Host
                        </TableCell>
                        <TableCell align="center">
                            Number of Registrants
                        </TableCell>
                        <TableCell align="center">
                            Details
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {workshops && workshops.map((workshop) => (
                        <TableRow key={workshop.id}>
                            <TableCell sx={{ width: '20%', align: 'center' }}>
                                <img src={workshop.cover?.url} alt={workshop.title}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        display: 'block',
                                        objectFit: 'contain',
                                        margin: 'auto'
                                    }} />
                            </TableCell>
                            <TableCell sx={{ width: '20%' }} align="center">
                                {workshop.title}
                            </TableCell>
                            <TableCell sx={{ width: '20%' }} align="center">
                                <Box style={isExpanded ? expandedStyle : collapsedStyle}>
                                    {workshop.information}
                                </Box>
                                {workshop.information?.length > 100 &&
                                    <Button size="small" onClick={() => setIsExpanded(!isExpanded)}>
                                        {isExpanded ? 'Read Less' : 'Read More'}
                                    </Button>
                                }
                            </TableCell>
                            <TableCell align="center">
                                {new Date(workshop.date).toLocaleDateString()}
                                <br />
                                {new Date(workshop.date).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
                            </TableCell>
                            <TableCell align="center">
                                {workshop.host}
                            </TableCell>
                            <TableCell align="center">
                                {workshop.attendees?.length}
                            </TableCell>
                            <TableCell align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate(`/yogarenew/workshops/${workshop.id}`)}
                                    target="_blank"
                                >
                                    View Workshop
                                </Button>
                            </TableCell>
                            <TableCell align="center">
                                {!past &&
                                    <Button
                                        startIcon={<EditIcon />}
                                        onClick={() => {
                                            setSelectedWorkshop(workshop)
                                            setOpenUpdateModal(true)
                                        }}
                                    >
                                        Edit
                                    </Button>
                                }
                                <Button
                                    startIcon={<DeleteIcon />}
                                    onClick={() => {
                                        setSelectedWorkshop(workshop)
                                        setOpenDeleteModal(true)
                                    }}
                                >
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <WorkshopUpdateModal
                open={openUpdateModal}
                handleClose={() => setOpenUpdateModal(false)}
                workshop={selectedWorkshop}
                setWorkshops={setWorkshops}
            />
            <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <Box sx={{ padding: 3 }}>
                    <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>Delete Workshop</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>Are you sure you want to delete this workshop?</Typography>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        sx={{ mr: 2 }}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => setOpenDeleteModal(false)}
                    >
                        Cancel
                    </Button>
                </Box>
            </Dialog>
        </Paper>
    )
}
