import { useState, useEffect } from 'react';
import { fetchWorkshops } from '../../../actions/workshopActions';
import { Box, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import { WorkshopListView } from './ListView';
import banner from '../../../assets/workshop-banner.jpeg'
import useAuth from '../../../hooks/useAuth';
import { checkSubscriptionValid, checkIAPHubSubscription } from '../../../actions/subscriptionsApi';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    page: {
        backgroundColor: 'background.paper',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '10rem',
        '@media (max-width: 960px)': {
            paddingBottom: '5rem',
        }
    },
    header: {
        textTransform: 'uppercase',
        marginTop: '2rem',
        textAlign: 'center',
        zIndex: 2,
        color: 'white'
    },
    subHeader: {
        marginTop: '2rem',
        textAlign: 'center',
        padding: '0 10rem',
        '@media (max-width: 960px)': {
            padding: '0 2rem',
        }
    },
    banner: {
        position: 'relative',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'flex-start',
        width: '100%',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.2)',
        zIndex: 1,
    },
    button: {
        textTransform: 'uppercase',
        padding: '0.5rem 1.5rem',
        marginTop: '2rem',
    },
    teacherImageContainer: {
        width: 300,
        height: 300,
        overflow: 'hidden',
        borderRadius: '50%',
        marginTop: -150,
        border: '5px solid white',
        zIndex: 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    image: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        display: 'block'
    },
}));



export default function Workshops() {
    const classes = useStyles()
    const [workshops, setWorkshops] = useState(null)
    const [loading, setLoading] = useState(false)
    const [subscriptionValid, setSubscriptionValid] = useState(false)
    const { user } = useAuth()
    const navigate = useNavigate()

    const checkSubscriptionValidity = async (user) => {
        if (user.subtype === 'publisher') {
            setSubscriptionValid(true)
            return
        }

        const iaphubuserid = user.iaphubuserid
        const iaphubpurchaseid = user.iaphubpurchaseid

        if (iaphubuserid && iaphubpurchaseid) {
            const response = await checkIAPHubSubscription(iaphubpurchaseid)
            if (response.valid) {
                setSubscriptionValid(true)
                return
            }
        }

        const id = user.stripesubscriptionid
        if (!id) setSubscriptionValid(false)
        const response = await checkSubscriptionValid(id)
        if (response) {
            setSubscriptionValid(response.valid)
        }
    }

    useEffect(() => {
        if (user) {
            checkSubscriptionValidity(user)
        }
    }, [user])


    const getWorkshops = async () => {
        setLoading(true)
        try {
            const response = await fetchWorkshops()
            if (response && response.length > 0) {
                const currentTime = new Date();
                const upcomingWorkshops = response
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .filter(workshop => {
                        const startTime = new Date(workshop.date);
                        const endTime = new Date(startTime.getTime() + workshop.duration * 60000);
                        return endTime > currentTime;
                    });
                setWorkshops(upcomingWorkshops)
            }
            setLoading(false)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getWorkshops()
    }, [])

    if (!subscriptionValid) {
        navigate('/yogarenew')
    }

    return (
        <Box className={classes.page}>
            <Box className={classes.banner}>
                <Box className={classes.overlay} />
                <Typography variant="h2" className={classes.header}>
                    YOGA, MEDITATION &
                    LIFESTYLE WORKSHOPS
                </Typography>
            </Box>
            {loading ? <CircularProgress /> :
                workshops &&
                <>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '100%',
                        px: 10,
                        mt: 5,
                        '@media (max-width: 960px)': {
                            px: 2,
                        },
                    }}>
                        <WorkshopListView workshops={workshops} subscriptionValid={subscriptionValid} />
                    </Box>
                </>
            }
        </Box >
    )
}