import { useState } from 'react'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
	Button,
	Grid,
	FormLabel,
	IconButton,
	ImageListItem,
	Paper,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import useAuth from '../../../../hooks/useAuth'
import ImagePlaceholder from '../../../../assets/image_placeholder.png'
import PicUpload from '../../../../components/PicUpload'
import { createAttachment } from '../../../../actions/channelActions'
import { getLargestImage } from '../../../../utils/getLargestImage'

const useStyles = makeStyles((theme) => ({
	root: {},
	modalBox: {
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: `translate(-50%, -50%)`,
		minWidth: '40vw',
		backgroundColor: theme.palette.background.dark,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
		zIndex: 1,
	},
}))

const AddChannelForm = ({ handleClose, handleChange, props, handleCreateChannel }) => {
	const classes = useStyles()
	const { user } = useAuth()
	const [cover, setCover] = useState(null)
	const [isUploading, setIsUploading] = useState(false)

	const handleAddCover = async ([attachment]) => {
		setIsUploading(true)
		const data = new FormData()
		data.append('files', attachment)
		console.log(data)
		const response = await createAttachment(data)
		setCover(response[0])
		setIsUploading(false)
		console.log(response)
	}

	console.log(cover)

	return (
		<Grid container spacing={2} mt={1}>
			<Grid item lg={4} md={4} xl={4} xs={12}>
				<Paper elevation={12} sx={{ position: 'relative' }} className={classes.modalBox}>
					<Typography color="textPrimary" variant="h5" sx={{ position: 'absolute', top: 25, left: 35 }}>
						Add New Section
					</Typography>
					<IconButton
						sx={{
							position: 'absolute',
							top: 10,
							right: 10,
						}}
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
					<Formik
						initialValues={{
							name: '',
							description: '',
							cover: '',
							user: user.id,
						}}
						validationSchema={Yup.object().shape({
							name: Yup.string().max(255),
						})}
						onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
							console.log(values)
							const response = await handleCreateChannel(values)
							if (response === 'Channel created') {
								resetForm()
								setStatus({ success: true })
								setSubmitting(false)
								toast.success('Channel added')
								handleClose()
							} else {
								setStatus({ success: false })
								setErrors('Something went wrong')
								setSubmitting(false)
							}
						}}
					>
						{({ errors, values, isSubmitting, handleSubmit, setFieldValue }) => (
							<form onSubmit={handleSubmit}>
								<Grid container spacing={1} sx={{ mt: 5, mx: 'auto' }}>
									<Grid item xs={12} md={7}>
										<FormLabel>
											<Typography
												variant="subtitle2"
												color="textPrimary"
												sx={{ textTransform: 'uppercase', fontWeight: 700 }}
											>
												Section Name
												{/* Channel Name */}
											</Typography>
										</FormLabel>
										<TextField
											fullWidth
											name="name"
											type="text"
											errors={errors}
											variant="outlined"
											value={values.name}
											onChange={(e) => setFieldValue('name', e.target.value)}
										/>
										<Grid item xs={12} sx={{ mt: 2 }}>
											<FormLabel>
												<Typography
													variant="subtitle2"
													color="textPrimary"
													sx={{ textTransform: 'uppercase', fontWeight: 700 }}
												>
													Section Description
													{/* Channel Description */}
												</Typography>
											</FormLabel>
										</Grid>
										<Grid item xs={12} textAlign="center">
											<TextField
												fullWidth
												name="description"
												multiline
												rows="3"
												type="text"
												errors={errors}
												variant="outlined"
												value={values.description}
												onChange={(e) => setFieldValue('description', e.target.value)}
											/>
										</Grid>
									</Grid>

									<Grid item xs={12} md={4} textAlign="center" sx={{ mx: 'auto', mt: 2.5, mr: 2 }}>
										<ImageListItem>
											<img
												src={cover ? `${getLargestImage(cover, 'small')}` : ImagePlaceholder}
												// src={values.cover ? `${getLargestImage(values.cover, 'small')}` : ImagePlaceholder}
												value={cover}
												alt={values.name}
											/>
										</ImageListItem>
										<PicUpload
											onDrop={handleAddCover}
											fileType="image"
											fileLabel="cover"
											maxFiles={1}
											buttonText={'Add Cover'}
											hasIcon={true}
											buttonSize="medium"
										/>
									</Grid>
									<Grid item md={12} textAlign="right" sx={{ mt: 3, mr: 2 }}>
										<Button
											variant="contained"
											type="submit"
											onClick={handleSubmit}
											disabled={isSubmitting}
											sx={{ px: 2 }}
										>
											<Typography variant="subtitle2" sx={{ textTransform: 'uppercase' }}>
												Add Section
											</Typography>
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
					</Formik>
				</Paper>
			</Grid>
		</Grid>
	)
}

export default AddChannelForm
