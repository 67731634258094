import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

const HeadManager = () => {
    const { courseId } = useParams();

    return (
        <Helmet>
            <meta name="apple-itunes-app" content={courseId ? `app-id=6452239794, app-argument=pelimobile://courseDetails?courseId=${courseId}` : `app-id=6452239794`} />
        </Helmet>

    )
}

export default HeadManager;