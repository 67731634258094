import { useEffect, useState } from 'react'
import { getZoomAccessToken } from '../../../actions/workshopActions'
import { useLocation } from 'react-router'

export const ZoomRedirect = () => {
    const [loading, setLoading] = useState(true)
    const location = useLocation();

    useEffect(() => {
        const search = new URLSearchParams(location.search);
        const code = search.get('code');

        if (code) {
            getZoomAccessToken(code)
                .then(() => {
                    setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }, [location])

    return loading ? null : null
}