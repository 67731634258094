import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Button, ButtonBase, Divider, ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core'
import useAuth from '../hooks/useAuth'
import CogIcon from '../icons/Cog'
import UserIcon from '../icons/User'
import RestoreIcon from '@material-ui/icons/Restore'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import TransactionIcon from '../icons/Cash'
import OndemandVideoOutlined from '@material-ui/icons/OndemandVideoOutlined'
import ViewListIcon from '@material-ui/icons/ViewList';
import { SelfImprovementOutlined } from '@material-ui/icons'

const AccountPopover = ({ profileClick, logoutClick, transactionClick }) => {
	const navigate = useNavigate()
	const anchorRef = useRef(null)
	const { user, stopImpersonating } = useAuth()
	const [menuOpen, setMenuOpen] = useState(false)

	const handleMenuOpen = () => {
		setMenuOpen(true)
	}

	const handleMenuClose = () => {
		setMenuOpen(false)
	}

	const impersonating = sessionStorage.getItem('impersonating')

	if (!user) return null

	return (
		<>
			<Box component={ButtonBase} onClick={handleMenuOpen} ref={anchorRef}
				sx={{ alignItems: 'center', display: 'flex' }}>
				<Avatar src={`${user.avatar?.url}`} sx={{ height: 32, width: 32, bgColor: "primary" }} />
			</Box>
			<Menu anchorEl={anchorRef.current} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} keepMounted open={menuOpen} onClick={handleMenuClose} onClose={handleMenuClose}
				sx={{ mt: 1 }}>
				<Box sx={{ p: 2, width: "240px" }}>
					<Typography color="textPrimary" variant="subtitle2">Welcome to YogaRenew</Typography>
					<Typography color="textSecondary" variant="subtitle2">{user?.firstName && user?.lastName ? `${user?.firstName} ${user.lastName}` : `${user?.username}`}</Typography>
				</Box>
				<Divider />
				<MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={profileClick}>
					<ListItemIcon><UserIcon fontSize="small" color="primary" /></ListItemIcon>
					<Typography color="textPrimary" variant="subtitle2">Account Settings</Typography>
				</MenuItem>
				<MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={() => { navigate(`/yogarenew/videos/allclasses`) }}>
					<ListItemIcon><SelfImprovementOutlined fontSize="small" /></ListItemIcon>
					<Typography color="textPrimary" variant="subtitle2">Classes</Typography>
				</MenuItem>
				<MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={() => { navigate(`/yogarenew/courses`) }}>
					<ListItemIcon><OndemandVideoOutlined fontSize="small" /></ListItemIcon>
					<Typography color="textPrimary" variant="subtitle2">Courses</Typography>
				</MenuItem>
				<MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={() => { navigate(`/yogarenew/series`) }}>
					<ListItemIcon><ViewListIcon fontSize="small" /></ListItemIcon>
					<Typography color="textPrimary" variant="subtitle2">Series</Typography>
				</MenuItem>
				<MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={() => { navigate(`/yogarenew/history`) }}>
					<ListItemIcon><RestoreIcon fontSize="small" /></ListItemIcon>
					<Typography color="textPrimary" variant="subtitle2">History</Typography>
				</MenuItem>
				<MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={() => { navigate(`/yogarenew/favorites`) }}>
					<ListItemIcon><FavoriteBorderIcon fontSize="small" /></ListItemIcon>
					<Typography color="textPrimary" variant="subtitle2">Favorites</Typography>
				</MenuItem>
				{user.subtype?.toLowerCase() !== 'publisher' &&
					<MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={transactionClick}>
						<ListItemIcon><TransactionIcon fontSize="small" /></ListItemIcon>
						<Typography color="textPrimary" variant="subtitle2">Transactions</Typography>
					</MenuItem>
				}
				{user.subtype === "publisher" && (
					<MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={() => { navigate("/dashboard") }}>
						<ListItemIcon><CogIcon fontSize="small" /></ListItemIcon>
						<Typography color="textPrimary" variant="subtitle2">Dashboard</Typography>
					</MenuItem>)}
				<Divider />
				<Box sx={{ mt: 2 }}></Box>
				<Box sx={{ p: 2 }}>
					{!impersonating ?
						<Button color="primary" fullWidth onClick={logoutClick} variant="outlined">Logout</Button>
						: <Button color="primary" fullWidth onClick={stopImpersonating} variant="outlined">Stop Impersonating</Button>
					}
				</Box>
			</Menu>
		</>
	)
}

export default AccountPopover
