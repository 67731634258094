import { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import CourseCard from '../../../../components/publisher-frontend/courses/CourseCard'
import { checkIAPHubSubscription, checkSubscriptionValid } from '../../../../actions/subscriptionsApi'
import useAuth from '../../../../hooks/useAuth'

export const CoursesTab = ({ courses, navigate, coursesProgress }) => {

    const { user } = useAuth()
    const [validSubscription, setValidSubscription] = useState(false)
    const [checkingSubscription, setCheckingSubscription] = useState(true)
    const checkSubscription = async () => {
        if (user.iaphubuserid && user.iaphubpurchaseid) {
            const response = await checkIAPHubSubscription(user.iaphubpurchaseid)
            if (response.valid) {
                setValidSubscription(true)
                setCheckingSubscription(false)
                return
            }
        }

        const id = user.stripesubscriptionid
        if (!id) return false
        const response = await checkSubscriptionValid(id)
        setValidSubscription(response.valid)
        setCheckingSubscription(false)
    }

    useEffect(() => {
        if (!user) {
            setValidSubscription(false)
            setCheckingSubscription(false)
            return
        }
        checkSubscription()
    }, [user])

    const hasAccess = user && (validSubscription || user.subtype === 'publisher')

    if (checkingSubscription) return null

    return (
        <Box sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            px: 10,
            '@media (max-width: 960px)': {
                px: 2,
            },
        }}>
            <Grid container spacing={3} sx={{ py: 8, px: 5 }}>
                {courses && courses.length > 0 ?
                    courses
                        .filter(course => course.status === 'published')
                        .map((course, index) => {
                            let progress = coursesProgress?.find((item) => item.id === course._id)
                            if (!progress && coursesProgress) {
                                progress = { progress: 0 }
                            }
                            return (
                                <Grid item xs={12} sm={6} md={3} key={index}
                                    sx={{
                                        '&:hover': {
                                            cursor: 'pointer'
                                        }
                                    }}>
                                    <CourseCard hasAccess={hasAccess} course={course} progress={progress?.progress} />
                                </Grid>
                            )
                        })
                    :
                    <Typography variant="h6">No courses found for this instructor.</Typography>
                }
            </Grid>
        </Box>
    )
}
