const feedFMApiURL = 'https://feed.fm/api/v2'
const feedFMMode = process.env.REACT_APP_FEED_FM_MODE // DEMO OR LIVE

const nodeURL = process.env.REACT_APP_STRAPI_URL

const headers = () => {
	const accessToken = localStorage.getItem('accessToken')
	const headers = {
        'Content-Type': 'application/json',
		'x-analytics-source': 'web'
	}
	if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

	return headers
}

class FeedFMApi {

    get_token() {
        if (feedFMMode === "DEMO") return window.btoa('demo:demo')

        const { token, secret } = JSON.parse(localStorage.getItem('feedfm_token'))
        const encodedToken = window.btoa(token + ":" + secret)
        return encodedToken
    }

    async get_session(previous_cid = "") {
        return new Promise(async (resolve, reject) => {
            try {
                const opts = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Basic ${this.get_token()}`
                    },
                    body: JSON.stringify({
                        client_id: previous_cid
                    }),
                }

                const response = await fetch(`${feedFMApiURL}/session`, opts)

                if (!response || response.status !== 200) {
                    reject(new Error('Failed to get valid Feed.FM Session - no response or wrong response code'))
                    return
                }

                const responseJson = await response.json()
                if (!responseJson.success || !responseJson.session || !responseJson.session.client_id) {
                    reject(new Error('Failed to get valid Feed.FM Session - bad json'))
                    return
                }
                resolve(responseJson)
            } catch (err) {
                console.error('[Feed.FM Api]: ', err)
                reject(new Error('Internal server error'))
            }
        })
    }

    async refreshToken() {
        const opts = {
            method: 'GET',
            headers: headers(),
        }

        const response = await fetch(`${nodeURL}/feedfm`, opts).then((data) => data.json())

        if(response.access_token) {
            localStorage.setItem("feedfm_token", JSON.stringify(response.access_token))
        }

        return response
    }
}

export const feedFMApi = new FeedFMApi()