import { ListItemIcon, Menu, MenuItem, Typography} from '@material-ui/core'
import UserIcon from '../icons/User'
import ShoppingCartIcon from '../icons/ShoppingCart'

const MobileMenuPopover = ({loginClick, subscribeClick, open, closeMenu, anchorRef}) => {

	return (
		<Menu 
			anchorEl={anchorRef} 
			anchorOrigin={{horizontal: 'center', vertical: 'bottom'}} 
			keepMounted
			open={open}
			onClick={closeMenu}
			onClose={closeMenu}
			sx={{mt: 1}}
		>
			<MenuItem sx={{pt: 1.5, pb: 1.5}} onClick={loginClick}>
				<ListItemIcon><UserIcon fontSize="small" /></ListItemIcon>
				<Typography color="textPrimary" variant="subtitle2">Login</Typography>
			</MenuItem>
			<MenuItem sx={{pt: 1.5, pb: 1.5}} onClick={subscribeClick}>
				<ListItemIcon><ShoppingCartIcon fontSize="small" /></ListItemIcon>
				<Typography color="textPrimary" variant="subtitle2">Subscribe</Typography>
			</MenuItem>
		</Menu>
	)
}

export default MobileMenuPopover
