import { Link } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";

export default function NotFound() {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', textAlign: 'center', mt: '10vh', flexDirection: "column"}}>
            <Typography variant="h1">Uh oh! This page can't be found.</Typography>
            <Typography variant="h6">
                <Link to='/'>Let's get back</Link>
            </Typography>            
        </Box>
    )
}