const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => {
	const accessToken = localStorage.getItem('accessToken')
	const headers = {
		'Content-Type': 'application/json',
		'x-analytics-source': 'web'
	}
	if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

	return headers
}

// *********** CREATE COURSE SECTION *********** //
export const createCourseSection = async (details) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(details),
    }

    const response = await fetch(`${nodeURL}/course-sections`, opts).then((data) => data.json())

    return response
}


// *********** FETCH ALL COURSE SECTIONS (IN A COURSE) *********** //
export const fetchCourseSections = async (courseId) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/course-sections?course=${courseId}`, opts).then((data) => data.json())

    return response
}

// *********** FETCH A SINGLE COURSE SECTION (BY ID) *********** //
export const fetchCourseSection = async (id) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/course-sections/${id}`, opts).then((data) => data.json())

    return response
}

// *********** UPDATE A COURSE SECTION (BY ID) *********** //
export const updateCourseSection = async (id, details) => {
    const opts = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify(details),
    }

    const response = await fetch(`${nodeURL}/course-sections/${id}`, opts).then((data) => data.json())

    return response
}

// *********** DELETE A COURSE SECTION (BY ID) *********** //
export const deleteCourseSection = async (id) => {
    const opts = {
        method: 'DELETE',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/course-sections/${id}`, opts).then((data) => data.json())

    return response
}
