import { useState, useEffect } from 'react';
import { InstructorListView } from "./ListView";
import { fetchInstructors } from '../../../actions/instructorsApi';
import { Box, Typography } from '@material-ui/core';

const InstructorPage = () => {
    const [loading, setLoading] = useState(false)
    const [instructors, setInstructors] = useState(null)


    const getInstructors = async () => {
        setLoading(true)
        const response = await fetchInstructors()
        if (response) {
            setLoading(false)
            setInstructors(response)
        }
    }

    useEffect(() => {
        getInstructors()
    }, [])

    return (
        <Box
            sx={{
                backgroundColor: 'background.paper',
                px: 10,
                pb: 15,
                '@media (max-width: 960px)': {
                    px: 2,
                    pb: 2,
                },
            }}
        >
            <Box sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography
                    variant="h4"
                    sx={{
                        textTransform: 'uppercase',
                        mt: '5rem',
                        textAlign: 'center',
                        '@media (max-width: 960px)': {
                            marginTop: '2rem',
                        }
                    }}
                >
                    meet your instructors
                </Typography>
            </Box>
            <InstructorListView loading={loading} instructors={instructors} />
        </Box>
    )
}

export default InstructorPage