import { useEffect, useState } from 'react';
import { activateTV } from '../../actions/userActions';
import { useSearchParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import YogaRenewLogin from './YogaRenewLogin';
import {
    Dialog,
    DialogContent,
    Button,
    Box,
    Typography,
    CircularProgress,
    TextField,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import PublisherSiteLogo from '../../components/publisher-site/PublisherSiteLogo';
import { Link as RouterLink } from 'react-router-dom';

const TVLogin = () => {
    const { user } = useAuth();
    const [searchParams] = useSearchParams();
    const [deviceCode, setDeviceCode] = useState(searchParams.get('deviceCode'));
    const [deviceId, setDeviceId] = useState(searchParams.get('deviceId'));
    const [error, setError] = useState('');
    const [authorized, setAuthorized] = useState(false);
    const [showCodeField, setShowCodeField] = useState(!deviceCode ? true : false);
    const [activating, setActivating] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [open, setOpen] = useState(true);

    const handleActivateTV = async () => {
        setActivating(true);
        try {
            const result = await activateTV(deviceId, deviceCode);
            if (result.authorized) {
                setAuthorized(true);
                setShowCodeField(false);
            } else {
                setError('Unauthorized');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setActivating(false);
        }
    };

    useEffect(() => {
        if (user) {
            setOpen(false);
        }
    }, [user]);

    console.log('deviceCode', deviceCode);
    console.log('deviceId', deviceId);
    console.log('user', user);

    return (
        <Box p={3} textAlign="center">
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
                <RouterLink to='/'>
                    <PublisherSiteLogo maxWidth="300px" maxHeight="70px" />
                </RouterLink>
            </Box>
            <Typography variant={isMobile ? 'h4' : 'h2'}>
                Connect a device to your YogaRenew Account
            </Typography>
            <Typography variant={isMobile ? 'h6' : 'h4'} my={2} mx={5}>
                To safely sign in to your device, first verify that the code on this screen matches the code that's displayed on your device.
            </Typography>
            <Box my={2}>
                <Typography variant={isMobile ? 'h5' : 'h3'}>Code:</Typography>
                <Typography variant={isMobile ? 'h5' : 'h3'}>{deviceCode}</Typography>
            </Box>
            {authorized
                ? (
                    <Typography variant={isMobile ? 'h6' : 'h4'} color="primary" my={2}>
                        Device activated successfully. It may take a few seconds to update.
                    </Typography>)
                : (
                    <Box my={2}>
                        {activating ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Button variant="outlined" onClick={() => setShowCodeField(true)}>
                                    Enter code manually
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleActivateTV} style={{ marginLeft: '1rem' }}>
                                    Activate
                                </Button>
                            </>
                        )}
                    </Box>
                )
            }
            {error && (
                <Typography variant="body1" color="error" my={2}>
                    {error}
                </Typography>
            )}
            <Dialog open={showCodeField} onClose={() => setShowCodeField(false)}>
                <DialogContent>
                    <Typography variant='h6'>Enter the code displayed on your device</Typography>
                    <Box my={2}>
                        <TextField fullWidth variant="outlined" placeholder="Enter code" onChange={(e) => setDeviceCode(e.target.value)} />
                        <Button variant="contained" color="primary" onClick={() => setShowCodeField(false)} style={{ marginTop: '1rem' }}>
                            Submit
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            {!user &&
                <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
                    <DialogContent>
                        <YogaRenewLogin setOpen={setOpen} />
                    </DialogContent>
                </Dialog>
            }
        </Box>
    );
};

export default TVLogin;
