import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
	logoColor: {
		filter: 'invert(1)',
	},
}))

const PublisherSiteFooterLogo = (props) => {
	const classes = useStyles()

	return (
		<img
			alt="Logo"
			className={classes.logoColor}
			src="https://peliuploads.s3.us-east-2.amazonaws.com/yogarenew_6fd63c661f.png"
			style={{ width: 'auto', height: 30 }}
			{...props}
		/>
	)
}

export default PublisherSiteFooterLogo
