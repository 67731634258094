import { CircularProgress, Box } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { getSetupIntentSecret } from '../actions/subscriptionsApi'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import UpdatePaymentFormControl from './UpdatePaymentFormControl'

const UpdatePaymentForm = ({ onSuccess, onCancel }) => {

    /** @type {[Stripe, React.Dispatch<Stripe>]} */
    const [stripe, setStripe] = useState(null)

    /** @type {[string, React.Dispatch<string>]} */
    const [clientSecret, setClientSecret] = useState(null)

    const stripePaymentTheme = {
		theme: 'none',
		rules: {
			'.Tab': {
				border: '1px solid rgb(23,43,77)',
				lineHeight: '23px',
			},
			'.Input': {
				border: '1px solid rgba(0,0,0, 0.23)',
				borderRadius: '16px',
				lineHeight:'30px'
			},
			'.Label': {
				color: 'rgb(0,0,0)',
				fontSize: '14px',
				fontWeight: '600'
			}
		}
	}

    useEffect(() => {
		init()
	}, [])

    async function init() {
        const stripePromise = await loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`)
		const setupIntent = await getSetupIntentSecret()
		setStripe(stripePromise)
		setClientSecret(setupIntent.client_secret)
    }

    if(stripe && clientSecret)
        return <Elements stripe={stripe} options={{clientSecret: clientSecret, appearance: stripePaymentTheme}}>
                    <UpdatePaymentFormControl onSuccess={onSuccess} onCancel={onCancel}/>
                </Elements>
    else return <Box sx={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></Box>
}

export default UpdatePaymentForm