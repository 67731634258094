import { decode } from '../utils/jwt'
import { NODE_URL } from '../constants'

const users = [
	{
		id: '5e86809283e28b96d2d38537',
		avatar: '/static/mock-images/avatars/avatar-jane_rotanson.png',
		email: 'demo@devias.io',
		name: 'Jane Rotanson',
		password: 'Password123!',
		plan: 'Premium',
	},
	{
		id: '5e86809283e28b96d2d38538',
		avatar: '/static/mock-images/avatars/avatar-neha_punita.png',
		email: 'neha@devias.io',
		name: 'Neha Punita',
		password: 'Password123!',
		plan: 'Premium',
	},
]

class AuthApi {
	async login({ email, password }) {
		return new Promise(async (resolve, reject) => {
			try {
				// Find the user
				const body = {
					identifier: email,
					password: password,
				}

				const opts = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'x-analytics-source': 'web'
					},
					body: JSON.stringify(body),
				}

				const response = await fetch(`${NODE_URL}/auth/local`, opts)

				if (!response || response.status === 400) {
					reject(new Error('Please check your email and password'))
					return
				}

				if (response.status !== 200) {
					reject(new Error('Internal server error'))
					return
				}

				const user = await response.json()
				resolve(user)
			} catch (err) {
				console.error('[Auth Api]: ', err)
				reject(new Error('Internal server error'))
			}
		})
	}

	async register(body) {
		return new Promise(async (resolve, reject) => {
			try {
				const opts = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'x-analytics-source': 'web'
					},
					body: JSON.stringify(body),
				}

				const response = await fetch(`${NODE_URL}/auth/local/register`, opts)

				if (!response || response.status === 400) {
					reject(new Error('Please check your email and password'))
					return
				}

				const responseJson = await response.json()
				resolve(responseJson)
			} catch (err) {
				console.error('[Auth Api]: ', err)
				reject(new Error('Internal server error'))
			}
		})
	}

	me(accessToken) {
		return new Promise((resolve, reject) => {
			try {
				// Decode access token
				const { userId } = decode(accessToken)

				// Find the user
				const user = users.find((_user) => _user.id === userId)

				if (!user) {
					reject(new Error('Invalid authorization token'))
					return
				}

				resolve({
					id: user.id,
					avatar: user.avatar,
					email: user.email,
					name: user.name,
					plan: user.plan,
				})
			} catch (err) {
				console.error('[Auth Api]: ', err)
				reject(new Error('Internal server error'))
			}
		})
	}
}

export const authApi = new AuthApi()
