const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = (additionalHeaders) => {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
        'Content-Type': 'application/json',
        'x-analytics-source': 'web',
        ...additionalHeaders
    }
    if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

    return headers
}
// CREATE A WORKSHOP 
export const createWorkshop = async (workshop) => {
    const opts = {
        method: 'POST',
        headers: headers({
            'zoom_access_token': localStorage.getItem('zoomAccessToken'),
            'zoom_refresh_token': localStorage.getItem('zoomRefreshToken')
        }),
        body: JSON.stringify(workshop),
        credentials: 'include'
    }

    const response = await fetch(`${nodeURL}/workshops`, opts).then((data) => data.json())

    return response
}

// FETCH A WORKSHOP
export const fetchWorkshop = async (id) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/workshops/${id}`, opts).then((data) => data.json())

    return response
}

// FETCH ALL WORKSHOPS
export const fetchWorkshops = async () => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/workshops`, opts).then((data) => data.json())

    return response
}

// UPDATE A WORKSHOP
export const updateWorkshop = async (id, workshop) => {
    const opts = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify(workshop),
        credentials: 'include'
    }

    const response = await fetch(`${nodeURL}/workshops/${id}`, opts).then((data) => data.json())

    return response
}

// DELETE A WORKSHOP
export const deleteWorkshop = async (id) => {
    const opts = {
        method: 'DELETE',
        headers: headers(),
        credentials: 'include'
    }

    const response = await fetch(`${nodeURL}/workshops/${id}`, opts).then((data) => data.json())

    return response
}

// AUTHORIZE ZOOM
export const authorizeZoom = async (redirect_url) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify({ redirect_url }),
        credentials: 'include'
    }

    const response = await fetch(`${nodeURL}/workshops/zoomAuth`, opts).then((data) => data.json())

    return response

}

/**
 * @typedef {Object} ZoomAuthResponse
 * @property {boolean} authorized - Indicates if the authorization was successful.
 * @property {string} [error] - error message if authorization failed.
 * @property {string} [access_token] - access token provided on successful authorization.
 * @property {string} [refresh_token] - refresh token provided on successful authorization.
 */

/**
 * @param {*} code 
 * @param {*} redirect_url 
 * @returns { ZoomAuthResponse }
 */
export const getZoomAccessToken = async (code, redirect_url) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify({ code, redirect_url }),
        credentials: 'include'
    }

    const response = await fetch(`${nodeURL}/workshops/zoomCallback`, opts).then((data) => data.json())

    return response
}

// GET ZOOM MEETINGS
export const fetchZoomMeetings = async () => {
    const opts = {
        method: 'GET',
        headers: headers(),
        credentials: 'include'
    }

    const response = await fetch(`${nodeURL}/workshops/getzoommeetings`, opts).then((data) => data.json())

    return response
}

// REGISTER ZOOM MEETING
export const registerZoomMeeting = async (meetingId, firstname, lastname, email) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify({ meetingId, firstname, lastname, email }),
    }

    const response = await fetch(`${nodeURL}/workshops/registerzoommeeting`, opts).then((data) => data.json())

    return response
}

// FIND JOIN URL FOR USER
export const fetchJoinUrl = async (meetingId) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/workshops/${meetingId}/joinzoommeeting`, opts).then((data) => data.json())

    return response
}

/**
 * @typedef {Object} ZoomRefreshResponse
 * @property {boolean} authorized - Indicates if the authorization was successful.
 * @property {string} [error] - error message if authorization failed.
 * @property {string} [access_token] - access token provided on successful authorization.
 */

/**
 * @param {*} code 
 * @param {*} redirect_url 
 * @returns { ZoomRefreshResponse }
 */
export const checkZoomAuth = async () => {
    const opts = {
        method: 'POST',
        headers: headers(),
        credentials: 'include',
        body: JSON.stringify({
            access_token: localStorage.getItem('zoomAccessToken'),
            refresh_token: localStorage.getItem('zoomRefreshToken')
        }),
    }

    const response = await fetch(`${nodeURL}/workshops/zoom/auth/check`, opts);
    const data = await response.json();

    return data
}

// REVOKE ZOOM AUTH
export const revokeZoomAuth = async () => {
    const opts = {
        method: 'GET',
        headers: headers(),
        credentials: 'include'
    }

    const response = await fetch(`${nodeURL}/workshops/revokezoomauth`, opts).then((data) => data.json())

    return response
}

// CHECK FEATURE FLAG
export const checkFeatureFlag = async () => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/feature-flags`, opts).then((data) => data.json())

    return response
}