import React, { useState } from 'react'
import { Box, Button, TextField, Typography, makeStyles, InputLabel, Grid } from '@material-ui/core'
import { Helmet } from 'react-helmet-async'
import InstructorPhotoUpload from '../../../../../components/InstructorPhotoUpload'
import { createAttachment } from '../../../../../actions/uploadActions'
import { createInstructor } from '../../../../../actions/instructorsApi'
import { Formik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'

const useStyles = makeStyles(() => ({
  photoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid #c2c2c2',
    borderRadius: 5,
  }
}))

const NewInstructorView = ({ setOpenAddNewModal }) => {
  const classes = useStyles()
  const [newInstructor, setNewInstructor] = useState({})
  const [instructorPhoto, setInstructorPhoto] = useState(null)

  const handleUploadPhoto = async ([attachment], setFieldValue) => {
    const data = new FormData()
    data.append('files', attachment)
    const response = await createAttachment(data)
    const uploadedImgObject = response[0]
    setInstructorPhoto(uploadedImgObject)
    setFieldValue('picture', uploadedImgObject)
  }

  const handleCreateInstructor = async (values) => {
    if (instructorPhoto) {
      const valuesWithPhoto = { ...values, picture: instructorPhoto }
      try {
        const response = await createInstructor(valuesWithPhoto)
        if (response.id) {
          setNewInstructor(response)
          return response
        }
      } catch (err) {
        console.error(err)
      }
    } else {
      toast.error('Please upload a photo')
    }
  }

  return (
    <>
      <Helmet><title>Add New Instructor</title></Helmet>
      <Box
        sx={{
          px: 4,
          pt: 2,
          pb: 8,
          mb: 6,
          width: '100%',
          height: 'auto',
        }}
      >
        <Formik
          initialValues={{
            name: newInstructor?.name ? newInstructor?.name : '',
            bio: newInstructor?.bio ? newInstructor?.bio : '',
            picture: instructorPhoto ? instructorPhoto : '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required('Name is required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm, setErrors, setStatus }) => {
            const response = await handleCreateInstructor(values)
            if (response && response.id) {
              resetForm()
              setStatus({ success: true })
              setSubmitting(false)
              toast.success('Instructor created')
              setOpenAddNewModal(false);
            } else {
              setStatus({ success: false })
              setErrors('Something went wrong creating the instructor')
              setSubmitting(false)
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, values }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ mb: 2 }}>
                  <Typography className={classes.pageSubheading} variant='subtitle2' sx={{ mb: 1 }}>Course Instructors</Typography>
                  <Typography variant='h5'>Add New Instructor</Typography>
                </Box>
                <Box>
                  <Button
                    size="medium"
                    variant="outlined"
                    sx={{ borderRadius: 40, textTransform: 'uppercase', px: 5, mr: 2 }}
                  >
                    Preview
                  </Button>
                  <Button
                    size="medium"
                    variant="contained"
                    sx={{ borderRadius: 40, textTransform: 'uppercase', px: 6, mr: 2 }}
                    disabled={isSubmitting}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Create
                  </Button>
                </Box>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between' }}>
                    <Box
                      sx={{ width: '100%', mr: 2, }}
                    >
                      <Box>
                        <InputLabel>Instructor Name</InputLabel>
                        <TextField
                          variant="outlined"
                          fullWidth
                          sx={{ mb: 2 }}
                          name="name"
                          value={values.name}
                          onChange={(e) => setFieldValue('name', e.target.value)}
                        />
                        <InputLabel>Instructor Bio</InputLabel>
                        <TextField
                          variant="outlined"
                          fullWidth
                          sx={{ mb: 2 }}
                          multiline={true}
                          rows={4}
                          name="bio"
                          value={values.bio}
                          onChange={(e) => setFieldValue('bio', e.target.value)}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: '100%',
                          mr: 2,
                        }}
                      >
                        <InputLabel>Instructor Photo</InputLabel>
                        <InstructorPhotoUpload
                          onDrop={(file) => handleUploadPhoto(file, setFieldValue)}
                          buttonText="Upload Photo"
                          fileType="image"
                          fileLabel="Instructor Photo"
                          accept="image/*"
                          maxFiles={1}
                          name="picture"
                          file={instructorPhoto}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </>
  )
}

export default NewInstructorView