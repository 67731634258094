const nodeURL = process.env.REACT_APP_STRAPI_URL
const headers = () => {
    const accessToken = localStorage.getItem('accessToken')
    const headers = {
        'Content-Type': 'application/json',
        'x-analytics-source': 'web'
    }
    if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

    return headers
}

// *********** CREATE COURSE *********** //
export const createCourse = async (details) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(details),
    }

    const response = await fetch(`${nodeURL}/courses`, opts).then((data) => data.json())

    return response
}


// *********** FETCH ALL COURSES *********** //
export const fetchCourses = async (userId, course_instructor, publisher = false) => {

    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const params = new URLSearchParams()

    if (course_instructor) {
        params.append('course_instructor', course_instructor)
    }

    if (userId) {
        params.append('user', userId)
    }

    if (publisher) {
        params.append('publisher', publisher)
    }
    const response = await fetch(`${nodeURL}/courses?${params.toString()}`, opts).then((data) => data.json())

    return response
}

// *********** FETCH A COURSE (BY ID) *********** //
export const fetchCourse = async (id) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/courses/${id}`, opts).then((data) => data.json())

    return response
}

// *********** UPDATE A COURSE (BY ID) *********** //
export const updateCourse = async (id, details) => {
    const opts = {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify(details),
    }

    const response = await fetch(`${nodeURL}/courses/${id}`, opts).then((data) => data.json())

    return response
}

// *********** DELETE A COURSE (BY ID) *********** //
export const deleteCourse = async (id) => {
    const opts = {
        method: 'DELETE',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/courses/${id}`, opts).then((data) => data.json())

    return response
}

export const fetchCourseSortOrder = async (userId) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }

    const response = await fetch(`${nodeURL}/courses/sortorder/${userId}`, opts).then((data) => data.json())

    return response
}

export const fetchFilteredSortedCourses = async (publisherId, filterVals) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }
    const params = new URLSearchParams()
    if (filterVals) {
        Object.keys(filterVals).forEach(key => params.append(key, filterVals[key]))
    }
    const response = await fetch(`${nodeURL}/courses/bypublisher/${publisherId}?${params.toString()}`, opts).then((data) => data.json())
    return response
}

export const fetchCourseWithDetails = async (courseId) => {
    const opts = {
        method: 'GET',
        headers: headers(),
    }
    const response = await fetch(`${nodeURL}/courses/detailed/${courseId}`, opts).then((data) => data.json())
    return response
}

// ********** GET ALL THE SECTION OBJECTS IN A COURSE (BY COURSE ID) ********** //
export const fetchCourseSections = async (courseId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/courses/${courseId}/sections`, opts);
    const data = response.json();

    return data;
}

export const fetchCourseCompletionData = async (courseId, userId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    const response = await fetch(`${nodeURL}/completed-lectures?course=${courseId}&user=${userId}`, opts);
    const data = response.json();

    return data;
}

export const fetchUserCourseCompletionData = async (userId) => {
    const opts = {
        method: 'GET',
        headers: headers()
    }

    let completedLectures = []
    let hasMore = true
    let page = 1
    while (hasMore) {
        const response = await fetch(`${nodeURL}/completed-lectures?user=${userId}&_limit=100&_start=${(page - 1) * 100}`, opts);
        const data = await response.json();
        if (data.length < 100) {
            hasMore = false
        }
        completedLectures = completedLectures.concat(data)
        page++
    }

    return completedLectures;
}

export const markLectureComplete = async (courseId, lectureId, userId) => {
    const opts = {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify({ course: courseId, lecture: lectureId, user: userId, timestamp: new Date() })
    }

    const response = await fetch(`${nodeURL}/completed-lectures`, opts);
    const data = response.json();

    return data;
}
