import { Outlet } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { PublisherSiteNavbar, PublisherSiteFooter } from './index'
import NotSubscribedModal from '../NotSubscribedModal'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	contentWrap: {
		paddingBottom: '12rem',
		paddingTop: '64px',
	}
}))

const PublisherSiteLayout = () => {
	const classes = useStyles()
	const [notSubscibedOpen, setNotSubscibedOpen] = useState(false)
	const context = useOutletContext();

	if(!context) return null;

	const [logo, name] = context

	return (
		<div>
			<PublisherSiteNavbar
				subscribeButtonColor="#2d91e3"
				loginButtonColor="#2d91e3"
				siteName={name}
				logo={logo}
			/>
			<div className={classes.contentWrap}>
				{<Outlet context={[logo, name]}/>}
			</div>
			<NotSubscribedModal isOpen={notSubscibedOpen} setOpen={setNotSubscibedOpen}/>
			<PublisherSiteFooter footerbackgroundcolor="#232323" socialIconColor="#2d91e3" />
		</div>
	)
}

export default PublisherSiteLayout
