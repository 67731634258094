import { Outlet } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const siteName = 'YogaRenew'
const siteLogo = "https://peliuploads.s3.us-east-2.amazonaws.com/yogarenew_logo_120321_lockup1_f8e8c62fde.png"

const PublisherSiteLayoutRoot = () => {
	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		if(location.pathname === "/") {
			navigate("/yogarenew")
		}
	}, [location.pathname, navigate])

	return (
		<Box sx={{position: 'relative', minHeight: '100vh', backgroundColor: 'background.default'}}>
			<Outlet context={[siteLogo, siteName]}/>
		</Box>
	)
}

export default PublisherSiteLayoutRoot
